import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import { AuthTokens, Chain } from 'types/dexbarn'

interface PatchReferralCodeArgs {
  referral_code: string
}

interface PatchReferralCodeResponse {
  code: string
  message: string
  user: string
}

interface PatchReferralCodeVariables {
  args: PatchReferralCodeArgs
  authTokens: AuthTokens
}

const usePatchReferralCode = ({
  chain
}: {
  chain: Chain
}): UseMutationResult<
  PatchReferralCodeResponse,
  Error,
  PatchReferralCodeVariables
> => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()
  const account = useAccountOnChain(chain)
  const addErrorPopup = useAddErrorPopup()

  return useMutation<
    PatchReferralCodeResponse,
    Error,
    PatchReferralCodeVariables
  >({
    mutationFn: async ({ args, authTokens }) => {
      if (!account.address) {
        throw new Error('Wallet not connected')
      }

      switch (chain) {
        case 'avalanche': {
          const response = await dexbarnClient.patch<PatchReferralCodeResponse>(
            '/v1/users/referral-code',
            args,
            { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
          )
          return response.data
        }
        case 'solana': {
          const response = await solbarnClient.patch<PatchReferralCodeResponse>(
            '/users/referral-code',
            args,
            { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
          )
          return response.data
        }
      }
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup({
          subtitle: error.response?.data.detail,
          summary: 'Unable to update referral code'
        })
      } else {
        addErrorPopup({
          subtitle: error.message,
          summary: 'Unable to update referral code'
        })
      }
      console.error(error)
    }
  })
}

export default usePatchReferralCode
