import { Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'

const TermsAndConditions = () => {
  return (
    <Flex
      flexDir="column"
      gap={2}
      pt={{ base: 4, md: 10 }}
      maxW="960px"
      margin="0 auto"
      minH="calc(100vh - 80px)"
      px={4}
      pb={{ base: 8, md: 16 }}
    >
      <Heading
        pt={4}
        textAlign="center"
        size="xl"
        color="textPrimary"
        mb={{ base: 4, md: 8 }}
      >
        TERMS OF SERVICES
      </Heading>

      <Heading pt={4} textAlign="center" size="md">
        Last Updated: 5 November 2024
      </Heading>

      <Text fontSize="md">
        These User Terms and Conditions, which includes terms under which Token
        Mill provides its services to users (these &quot;
        <Text as="b">Terms</Text>&quot;), effective upon the effective date of
        its publication on the website at [website details] (the &quot;
        <Text as="b">Website</Text>&quot;), sets out the terms and conditions of
        your use of the &quot;Token Mill&quot; platform (the &quot;
        <Text as="b">Platform</Text>&quot;), an protocol owned and operated by
        Joemart Ltd., a company incorporated under the laws of Panama (&quot;
        <Text as="b">Token Mill</Text>&quot; or &quot;<Text as="b">we</Text>
        &quot;) as well as access and use of any other services provided through
        the Platform. The provisions of these Terms will govern your use of the
        Platform (as defined below) and of the Services (as described below),
        and you should therefore take time to read these Terms carefully.
      </Text>

      <Text fontSize="md">
        Your use of the Services is governed by the version of these Terms in
        effect on the date of use. We may make changes to these Terms from time
        to time without prior notice. If we do this, we will post the changed
        provisions on the Website, update the &quot;Last Updated&quot; date
        above, and the revised Terms shall be effective at such time. It is your
        sole responsibility to review these Terms from time to time to view such
        changes and to ensure that you understand these Terms that apply when
        you use the Services. If you do not agree with any such revision, your
        sole and exclusive remedy is to terminate your use of the Services. You
        understand and agree that your continued use of the Services or the
        Website after we have made any such changes constitutes your acceptance
        of the new Terms.
      </Text>

      <Text fontSize="md">
        The Platform is intended for users who are at least 18 years old, or
        otherwise of sufficient legal age and capacity in their jurisdiction to
        contract. By clicking on the &quot;I Accept&quot; button or using the
        Services, the Platform or the Website, you agree to be bound by these
        terms and all of the terms incorporated herein by reference.{' '}
        <Text as="b">
          IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE
          PLATFORM OR THE SERVICES
        </Text>
        .
      </Text>

      <Text fontSize="md">
        The purpose of the Token Mill Platform and Services is to create an
        open, permissionless token launch platform which acts as an intermediary
        protocol connecting developers/token issuers with interested users (the{' '}
        <Text as="b">Services</Text>). It allows any type of third party
        blockchain project (not affiliated to Token Mill) which intends to issue
        its native digital tokens (each, a <Text as="b">Project</Text>) to mint
        tokens, build custom smart contracts, and create a peer-to-peer
        Automated Market Maker (AMM) marketplaces for trading of their native
        Project tokens via liquidity pools.
      </Text>

      <Text fontSize="md">
        Via tools and custom contract modules provided on the Platform and
        licensed to Projects, Projects will be able to directly conduct their
        token generation, sale and trading activities. They would also be able
        to interact with community members and conduct their own token sales
        without intervention or involvement of any third party (including Token
        Mill). The Platform allows a variety of novel features for Projects for
        the purpose of that Project&apos;s native token launch and sale process,
        for example customised pricing curve (a.k.a &quot;bonding curve&quot;)
        for token sales, customised bid and ask spreads for the AMM to offer
        differing price spreads between bids and offers, and customised fee
        sharing with holders/stakers of that Project&apos;s native tokens.
      </Text>

      <Text fontSize="md">
        By listing a Project on the Platform, third party issuers promoting the
        Project are inviting users to contract directly with them by way of a
        binding legal agreement (which Token Mill is not a party to). As an
        indication of commitment to potential purchasers and traders of tokens,
        Projects would be required to deposit tokens allocated for the potential
        sale/distribution into a specific token sale smart contract created by
        the Project utilising the tools and custom contract modules (and in this
        regard, Token Mill shall in no way be construed as a custodian, bailee
        or trustee of any sale token allocations or proceeds in connection with
        the same).
      </Text>

      <Text fontSize="md">
        Users are able to directly access Projects and take responsibility from
        the very beginning by selecting the Projects which they wish to support
        and accessing AMM liquidity pools deployed by Projects. Users are
        contracting with the relevant Project directly in respect of all token
        launch, sales and/or trading organised or performed by such Project, and
        Token Mill shall not be party to such contract for sale of tokens
        between the user and the Project, nor does Token Mill have any control
        over the Project&apos;s activities in connection with the token sale.
        Accordingly, a user&apos;s only recourse is against the relevant Project
        and not Token Mill.
      </Text>

      <Text fontSize="md">
        Token Mill functions solely as an intermediary technology protocol, and
        the Services solely comprise provision of tools and custom contract
        modules for third party issuers to launch their own Projects, perform
        sales and/or trading of native Project tokens via AMM liquidity pools.
        Token Mill is not a party to a user&apos;s participation in any Project.
        Users are wholly responsible for their decision to engage with third
        party issuers or any particular Project on the Platform. The
        relationship and dealings between a user and the relevant Project shall
        be governed by the relevant legal agreement or contract entered into
        directly between the parties, and Token Mill is not a party to the
        direct contractual relationship between a user and a Project;
        accordingly Token Mill does not assume any responsibilities or
        liabilities whatsoever in this regard. Token Mill shall in no
        circumstances be construed as a seller, introducer or promoter of any
        digital tokens or any Project.
      </Text>

      <Heading pt={4} size="md">
        1. GENERAL
      </Heading>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            In order to access the Services, you may first install a web browser
            (such as the Google Chrome web browser) and an electronic wallet
            compatible with the relevant supported blockchain network (for
            example the Phantom electronic wallet). These electronic wallet
            services provide a visual representation allowing you to interact
            with the underlying blockchain networks to purchase, store, and
            engage in transactions with various digital assets. You will not be
            able to engage in any transactions on the Platform other than
            through your selected electronic wallet service, or other browsers
            compatible with the relevant blockchain network.
          </li>
          <li>
            Token Mill will not create any hosted wallet for you or otherwise
            hold custody digital assets on your behalf, and it is your sole
            responsibility to maintain the security of your selected electronic
            wallet or digital address. In the event that you lose access to your
            electronic wallet, private key(s), password(s), or other method(s)
            of securing your wallet or digital address, all digital assets held
            in such wallet or digital address may be irretrievable, and Token
            Mill will be unable to assist you in any way. You hereby irrevocably
            waive, release and discharge all claims, whether known or unknown to
            you, against Token Mill, its affiliates and their respective
            shareholders, members, directors, officers, employees, agents and
            representatives related to your use of any wallet software,
            associated loss of digital assets, transaction failures, or any
            other defects that arise in the course of your use of your
            electronic wallet, including any losses that may obtain as a result
            of any failure of any underlying smart contracts, the Services, the
            Website or the Platform.
          </li>
          <li>
            Transactions that take place via the visual user interface on the
            Platform are confirmed via the relevant blockchain network. You
            understand that your public digital address on the relevant
            blockchain network will be made publicly visible whenever you engage
            in a transaction on the Platform.
          </li>
          <li>
            We may amend or modify these Terms by posting such amended or
            modified Terms on the Platform or by notifying you about the changes
            via email. By continuing to access or use the Services once the
            amended Terms are effective, you agree to be bound by its terms. If
            you do not agree to any of the terms set forth in these Terms, or
            any subsequent modification to these Terms, you may not access or
            use any of the Services and must stop accessing to the Platform
            immediately.
          </li>
          <li>
            We reserve the right to (a) modify or discontinue any portion of the
            Services, and (b) suspend or terminate your access to the Services,
            at any time, and from time to time, without notice to you in
            certain, limited circumstances described herein. You agree that we
            shall not be liable to you or any third party for any modification
            or termination of the Services, or suspension or termination of your
            access to the Services, except to the extent otherwise expressly set
            forth herein.
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        2. KYC/AML COMPLIANCE
      </Heading>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            All users and third party issuers are required to comply with all
            &quot;Know Your Customer&quot; (&quot;KYC&quot;) and &quot;Anti
            Money Laundering&quot; (&quot;AML&quot;) laws and regulations in all
            applicable jurisdictions, and provide all documentation/information
            as requested by the relevant Project or Token Mill (or their
            authorised third party service provider(s)). This may include,
            without limitation verification of user identity with multi-factor
            authentication, layered security, obtaining proof of address
            (utility bill or bank statement), screening identifying information
            against trusted third-party source such as international databases,
            analysing whether there is logical inconsistency between identifying
            information provided.
          </li>
          <li>
            Only users which have satisfactorily completed all KYC and AML
            checks prescribed by Projects and/or authorised third party service
            providers will qualify to have their designated digital address
            placed on the whitelist to grant access to the Services, the
            Platform and the Website. Users agree that all information provided
            will be made available to Projects to identify traits about users in
            a permissionless manner.
          </li>
          <li>
            Token Mill reserves the right, at its sole discretion, to reject any
            users or third party issuers, reject any Project or withdraw the
            listing of any Project on the Platform in the event that it deems
            any such Project&apos;s AML or KYC policies to be deficient or
            detrimental to the interests of any parties involved in any manner
            whatsoever.
          </li>
          <li>
            By using the Services and accessing the Platform, you represent and
            warrant that your use of the Services complies with all KYC and AML
            laws of all applicable jurisdictions.
          </li>
        </ul>
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            We hereby grant you a limited, non-exclusive, revocable,
            non-sublicensable, non-transferable and non-assignable license,
            subject to the terms herein, to access and use the Platform solely
            for the purpose of connecting with Projects and/or users on the
            Platform (as the case may be). Any other use of the Platform is
            expressly prohibited. You agree to use the license subject to the
            charges and fees as detailed further in this document.
          </li>
          <li>
            All other rights in the Platform are reserved by us. We reserve all
            rights in the Platform and you agree that these Terms does not grant
            you any other rights in or licenses to the Platform. You will not
            otherwise copy, transmit, distribute, sell, license, de-compile,
            reverse engineer, disassemble, modify, publish, create derivative
            works from, perform, display, incorporate into another website, or
            in any other way exploit any of the content available on the
            Platform or any derivative works thereof, in whole or in part for
            commercial or non-commercial purposes.
          </li>
          <li>
            If you violate any portion of these Terms, your licence to access
            and use the Services may be terminated pursuant to these Terms
            without requirement of a notice. In addition, we reserve the right
            to enforce all remedies available at law and in equity for any such
            violation.
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        4. USE OF SERVICES; RELATIONSHIP
      </Heading>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            You acknowledge and agree that the Platform merely functions as an
            intermediary user interface connecting third parties and related
            supporting Services, allowing users to obtain information regarding
            Projects and for Projects to launch their own IDO projects on the
            Platform. Token Mill does not actively introduce users to Projects
            or <i>vice versa</i>. Further, Token Mill does not act as a
            fiduciary or custodian in relation to any funds which you may place
            with a Project, and any claim relating to these funds are solely
            against the relevant third party issuer for the Project which bears
            sole responsibility for such funds.
          </li>
          <li>
            The Platform reserves the right to charge fees in the future for any
            transactions you may perform via the Platform, including without
            limitation a fee of 20% of all fees generated from the sale and
            trading of any tokens launched on the Platform (charged to the
            individual Projects which launch tokens).
          </li>
          <li>
            Prior to charging any fees, the fee schedule will be published on
            the Platform and Token Mill will allow users to review such fees
            before accepting them. The Platform works with a variety of payment
            service providers, and Token Mill cannot be responsible for their
            actions, omissions or performance. Projects and users are each
            separately responsible for paying any additional value added taxes,
            income taxes or other taxes or fees associated with their activities
            on the Platform.
          </li>
          <li>
            Further, transactions on the Platform may require interaction with
            the relevant blockchain network, and such transaction will require
            virtual &quot;gas&quot; fees to be paid to third-party
            miners/validators on such relevant blockchain network as
            consideration for their services in confirming such blockchain
            transactions. All such fees and costs shall be borne by you.
          </li>
          <li>
            You acknowledge that Token Mill may share a portion of its fees
            charged under its referral program for the invitation of new users
            by existing users. Token Mill reserves the right to (a) modify the
            referral fee calculations, (b) impose eligibility criteria on users
            for the earning of referral fees, (c) reject the payment of referral
            fees where the relevant user engages in any conduct which is
            unlawful, harmful, threatening, abusive, harassing, tortious,
            defamatory, vulgar, obscene, libellous, invasive of another&apos;s
            privacy, hateful (racially, ethnically, or otherwise), prejudicial
            in any form, misleading, fraudulent or false, or constitutes overly
            aggressive, questionable referral/sales or marketing methods, or (d)
            terminate the referral program at any time.
          </li>
        </ul>
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            The content provided on the Platform by third party issuers may not
            always be entirely accurate, complete or current and may also
            include technical inaccuracies or typographical errors. In an effort
            to continue to provide users with as complete and accurate
            information as possible, information may be changed or updated from
            time to time without notice. Accordingly, you should verify all
            information before relying on it, and all decisions based on
            information contained on the Platform are your sole responsibility
            and we shall have no liability for such decisions.
          </li>
          <li>
            From time to time, the Platform may contain references or links to
            third-party materials (including, without limitation, websites) and
            third-party applications which are not controlled by us. Such
            information, links, and third-party applications are provided as a
            convenience to you. Such links should not be considered endorsements
            and such reference does not imply our recommendation, approval,
            affiliation, or sponsorship of that respective property, product,
            service, or process. You acknowledge and agree that we are not
            responsible for any aspect of the information, content, or services
            contained in any third-party materials or on any third-party sites
            accessible or linked to the Platform, including without limitation
            content, property, goods or services available on the linked sites
            or services.
          </li>
          <li>
            In connection with your use of the Services, and your interactions
            with other users, and third parties you agree and represent you will
            not engage in any illegal, unauthorised, or improper activity. We
            reserve the right at all times to monitor, review, retain and/or
            disclose any information as necessary to satisfy any applicable law,
            regulation, legal process or governmental request.
          </li>
          <li>
            Token Mill may, at its sole discretion and without any prior
            notification suspend, restrict, or terminate your access to any or
            all of the Services and/or the Platform, and/or deactivate or cancel
            such access if:
            <ul>
              <li>
                we are so required by an enforceable subpoena, court order, or
                binding order of the court or government authority; or
              </li>
              <li>
                we reasonably suspect you of utilising the Services or the
                Platform in connection with illegal, unauthorised, or improper
                activity; or
              </li>
              <li>
                we are required to do so in order to comply with KYC/AML laws
                and regulations; or
              </li>
              <li>you violate any of these Terms.</li>
            </ul>
          </li>
          <li>
            Token Mill shall not be responsible for any content or other
            services provided by any third party issuer, or for any payments to
            be made by any Project to users. Such obligations shall be the
            exclusive liability of the relevant user and third party issuer
            under their direct contractual relationship.
          </li>
          <li>
            All information provided by or on behalf of Token Mill is for
            informational purposes only and should not be construed as legal,
            tax or financial advice. In particular, any summaries or reports on
            the Platform are provided to users for informational purposes only,
            and users should refrain from taking any action in reliance on any
            information contained in these Terms or provided by or on behalf of
            Token Mill. You acknowledge, agree, and understand that you are
            solely responsible for assessing whether to participate in any
            Project. Before you decide to make any financial, legal, or other
            decisions relating to any Project, you shall conduct all appropriate
            due diligence and seek all appropriate legal, tax or financial
            advice from independent professionals in connection with the same.
            You acknowledge and agree that Token Mill does not, in any way,
            supervise, direct, control, or evaluate any Project and accordingly
            cannot be responsible for any Project.
          </li>
          <li>
            Token Mill assumes no liability or obligations under or related to
            any third party issuer, Project, or any acts or omissions by any
            user or any third party issuer.
          </li>
          <li>
            You agree to immediately notify Token Mill if you become subject to
            any legal or regulatory investigation or action, or if you become
            aware of any third-party claim regarding the Platform or any
            Project.
          </li>
          <li>
            You acknowledge and agree that Token Mill assumes no obligations to
            help any user or third party issuer enforce any claims it might have
            towards any other user or third party issuer on the Platform, which
            may arise from their direct contractual engagements entered into on
            the Platform or based on any information provided by any party on
            the Platform.
          </li>
          <li>
            The third party issuer promoting a Project is solely responsible for
            fulfilling promises of goods or services in relation to Projects
            launched. Where third party issuers are unable to satisfy the terms
            of their direct arrangement with users, they may be subject to legal
            action by users.
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        5. USER REPRESENTATIONS AND WARRANTIES
      </Heading>

      <Text fontSize="md">
        By utilising the Platform, you shall represent and warrant to Token Mill
        as well as the Projects as follows:
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            you have read carefully and agree in full to the contents of these
            Terms and the whitepaper published on the Website;
          </li>
          <li>
            these Terms comprise legal, valid and binding obligations on you,
            enforceable against you by Token Mill in accordance with the terms
            hereof;
          </li>
          <li>
            these Terms and the consummation of the transactions hereinunder
            will not result in the violation of, or constitute a default under,
            or conflict with or cause the acceleration of any obligation of it
            under: (a) any contract or agreement to which you are a party or by
            which you are bound; (b) any judgment, decree, order or award of any
            court, governmental body or arbitrator having jurisdiction over you;
            or (c) any applicable law, statute, ordinance, regulation or rule;
            or (d) (if you are a legal entity) any provision of your constating
            documents, by-laws or resolutions;
          </li>
          <li>
            you are not a citizen, resident (tax or otherwise), permit holder or
            green card holder of the United States, China, Cuba, Iran, North
            Korea, Sudan, or Syria or any other country subject to United States
            embargo, UN sanctions, HM Treasury&apos;s financial sanctions
            regime, or if you are on the U.S. Treasury Department&apos;s
            Specially Designated Nationals List or the U.S. Commerce
            Department&apos;s Denied Persons List, Unverified List, Entity List
            HM Treasury&apos;s financial sanctions regime, nor are you under the
            control of any national or resident of these countries;
          </li>
          <li>
            where you are a Project launching tokens or liquidity provider on
            the AMM, the tokens generated and/or traded shall not constitute a
            security or similarly regulated product in all relevant
            jurisdictions;
          </li>
          <li>
            you understand and agree that we do not represent or warrant that
            the Services or the Platform is free of security vulnerabilities or
            secure from a hacker or other malicious attack, which may result in
            the loss of the user&apos;s assets, funds, confidential information
            or any other data;
          </li>
          <li>
            you are fully aware and understand that there are risks associated
            with the Platform, the Services and Projects. In particular, Token
            Mill does not guarantee or represent that contributions to any
            Project will be used as promised, that issuers of Projects will
            deliver on their promises, or that the Project will achieve any
            commercial goals/targets; Token Mill undertakes no duty to
            investigate claims made by issuers of Projects and accordingly does
            not endorse, guarantee, make representations, or provide warranties
            regarding the quality, safety, morality or legality of any Project;
          </li>
          <li>
            you agree and acknowledge that Token Mill is not liable for any
            indirect, special, incidental, consequential or other losses of any
            kind, in tort, contract or otherwise (including but not limited to
            loss of revenue, income or profits, and loss of use or data),
            arising out of or in connection with the usage of the Platform
            and/or the Services;
          </li>
          <li>
            you shall not (and shall not permit or authorise any other person
            to):
            <ul>
              <li>
                use the Platform in any manner that is not expressly authorised
                by these Terms;
              </li>
              <li>
                use the Platform (i) for any illegal, unauthorised or otherwise
                improper purposes or (ii) in any manner which would violate
                these Terms or breach any laws, regulations, rules or orders
                (including those relating to sale of securities, virtual assets,
                data privacy, data transfer, international communications or the
                export of technical or personal data) or violate the rights of
                third parties (including intellectual property rights and rights
                of privacy or publicity);
              </li>
              <li>
                remove any legal, copyright, trademark or other proprietary
                rights notices contained in the Platform or on any materials
                received or is given access to in connection with these Terms or
                the Services;
              </li>
              <li>
                advertise or provide products or services competitive with the
                Platform or access the Platform for competitive analysis or
                disseminate performance information relating to the Platform;
              </li>
              <li>
                sell, lease, share, transfer or sublicense the Platform or the
                Services, directly or indirectly, to any third party;
              </li>
              <li>
                use the Platform in a manner that, as determined by Token Mill
                in its sole discretion, constitutes excessive or abusive usage;
              </li>
              <li>
                interfere with, disrupt, degrade, impair, overburden or
                compromise the integrity of the Platform or any underlying
                software, any of Token Mill&apos;s systems or any of Token
                Mill&apos;s networks relating to the Platform (including by
                probing, scanning or testing their vulnerability);
              </li>
              <li>
                disobey any requirements, procedures, policies or regulations of
                networks in respect of the Platform as notified by Token Mill to
                you;
              </li>
              <li>
                attempt to gain unauthorised access to the Platform or Token
                Mill&apos;s other products and/or services, any of Token
                Mill&apos;s systems or any information not permitted by these
                Terms;
              </li>
            </ul>
          </li>
        </ul>
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            use the Platform to transmit any (i) content that is illegal,
            tortious, defamatory, vulgar, obscene, racist, ethnically
            insensitive, or invasive of another person&apos;s privacy, (ii)
            content that promotes illegal or harmful activity, or gambling or
            adult content, (iii) viruses, worms, defects, Trojan horses, or any
            other malicious programs or code or items of a destructive nature or
            (iv) materials that could harm minors in any way;
          </li>
          <li>
            copy, adapt, reformat, reverse-engineer, disassemble, decompile,
            download, translate or otherwise modify or create derivative works
            of the Platform or any underlying software, any of Token Mill&apos;s
            systems or other products or services;
          </li>
          <li>
            make any representations, warranties or commitments regarding the
            Platform on behalf of Token Mill; or
          </li>
          <li>
            take any action that would subject the Platform to any third-party
            terms, including without limitation any open source software licence
            terms.
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        6. RISKS BORNE BY USERS
      </Heading>

      <Text fontSize="md">
        You acknowledge and agree that the Platform and Services are currently
        in the initial development stages, and there are a variety of
        unforeseeable risks with utilising the Platform, the Services, or the
        underlying smart contracts. In the worst scenario, this could lead to
        the loss of all or part of your digital assets interacting with the
        Platform, the Services, or the underlying smart contracts.{' '}
        <Text as="b">
          IF YOU DECIDE TO UTILISE THE PLATFORM, THE SERVICES, OR THE UNDERLYING
          SMART CONTRACTS YOU EXPRESSLY ACKNOWLEDGE, ACCEPT AND ASSUME THE BELOW
          RISKS AND AGREE NOT TO HOLD TOKEN MILL OR ANY OF THEIR AFFILIATES
          RESPONSIBLE FOR THE FOLLOWING RISKS:
        </Text>
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            Usage of the Platform, the Services, or the underlying smart
            contracts carry financial risk. You acknowledge and agree that you
            are aware of such risks, in particular that transactions relating to
            digital assets are very risky, and such digital assets are, by their
            nature, highly experimental, risky, volatile and generally
            irreversible. You should not make any transactional decision without
            first conducting your own research. You are solely and exclusively
            responsible for determining whether any Project or any transaction
            in connection with the Platform, the Services, or the underlying
            smart contracts is appropriate or suitable for you based on your own
            objectives and personal and financial situation. You acknowledge and
            agree that you will access and use the Platform, the Services, or
            the underlying smart contracts and interact with Projects at your
            own risk.
          </li>
          <li>
            You represent that you have sufficient knowledge, market
            sophistication, professional advice and experience to make your own
            evaluation of the merits and risks of any participation in Projects
            and the underlying digital assets. You accept all consequences of
            participating in such interactions, including the risk that you may
            lose access to your digital assets indefinitely. All decisions to
            interact with Projects are made solely by you. Notwithstanding
            anything in these Terms, Token Mill accepts no responsibility
            whatsoever for and will in no circumstances be liable to you in
            connection with any interaction with Projects or your digital
            assets. Under no circumstances will the operation of all or any
            portion of the Platform, the Services, or the underlying smart
            contracts be deemed to create a relationship that includes any
            management of any assets, or the provision or tendering of
            investment advice.
          </li>
        </ul>
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            The prices of blockchain assets are extremely volatile. Fluctuations
            in the price of other digital assets could materially and adversely
            affect the value of your digital assets received in connection with
            Projects, which may also be subject to significant price volatility.
            We cannot guarantee that any users which choose to interact with
            Projects will not lose money.
          </li>
          <li>
            Neither the Platform, the Services, or the underlying smart
            contracts hold in custody, store, send, or receive any of your
            digital assets. This is because your digital assets exist only by
            virtue of the ownership record maintained on the relevant blockchain
            network. Any transfer of digital assets occurs within the relevant
            blockchain network, and not on the Platform.
          </li>
          <li>
            All smart contracts may contain security vulnerabilities, errors,
            failures, bugs or economic loopholes which may be exploited by third
            parties, causing you to suffer losses in connection with any digital
            assets utilised or received in connection with Projects. Interaction
            with these smart contracts are entirely at your own responsibility
            and liability, and Token Mill is not a counterparty to the smart
            contracts.
          </li>
          <li>
            No creator of Projects will be able to guarantee the future
            performance of digital assets issued in connection with a Project or
            any specific level of performance, or your overall results from
            interacting with Projects. When reviewing the background history,
            information, and opinions of these creators, do not assume that the
            user is unbiased, independent or qualified to provide financial
            information or opinions.
          </li>
          <li>
            Hackers or other malicious groups or organisations may attempt to
            interfere with the Platform, the Services, or the underlying smart
            contracts in a variety of ways, including, but not limited to,
            malware attacks, denial of service attacks, consensus-based attacks,
            Sybil attacks, smurfing and spoofing. Furthermore, because the
            relevant blockchain network comprises open-source software, there is
            the software underlying the smart contracts may contain intentional
            or unintentional bugs or weaknesses that may negatively affect the
            Platform, the Services, or the underlying smart contracts, or result
            in the loss of the user&apos;s original assets or wrapped assets, or
            the loss of the user&apos;s ability to access or control their
            digital assets. In the event of such a software bug or weakness,
            there may be no remedy, and users are not guaranteed any remedy,
            refund or compensation.
          </li>
          <li>
            All actions and functions performed by users via the open source
            smart contracts are irrevocable, and Token Mill cannot access nor
            does it custodies any of users&apos; digital assets transferred or
            deployed in connection with smart contracts; recover private keys,
            passwords, or other information; reset passwords; or reverse
            transactions.
          </li>
          <li>
            The Platform, the Services, or the underlying smart contracts may
            rely on or utilise a variety of external third party services or
            software, including without limitation decentralised cloud storage
            services, analytics tools, oracles, hence therefore the Platform,
            the Services, or the underlying smart contracts may be adversely
            affected by any number of risks related to these third party
            services/software, which may be compromised in the event of security
            vulnerabilities, cyberattacks, malicious activity, or technical
            interruptions.
          </li>
          <li>
            You accept and confirm that a conflict of interest may arise when
            the interest of Token Mill and its affiliates competes or may appear
            to compete with your interests under these Terms. Specifically, you
            hereby acknowledge and confirm that: (a) Token Mill or its
            affiliates may participate in certain transactions relating to
            Projects in an individual capacity; (b) Token Mill or its affiliates
            may establish business, including without limitation, advisory or
            consulting relationships with Projects and various other ecosystem
            players, financial institutions, digital asset owners, or digital
            asset exchanges; and (c) Token Mill or its affiliates may compensate
            or share its revenues (e.g. under the referral program) from
            activities in connection with the Platform, the Services, or the
            underlying smart contracts with various users, creators of Projects,
            users of Projects, Token Mill&apos;s affiliates, partners or other
            similar parties.
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        7. INTELLECTUAL PROPERTY RIGHTS
      </Heading>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            The Platform and any Services, including their design elements or
            concepts and any and all underlying software and intellectual
            property, including, but not limited to any registered trademarks,
            are the property of Token Mill, and are protected by copyright,
            patent, trade secret and other intellectual property laws.
          </li>
          <li>
            Token Mill retains any and all rights, title and interest in and to
            the Platform and Services (including, without limitation, all
            intellectual property rights), including all copies, modifications,
            extensions and derivative works thereof. Your right to use the
            Platform and Services is limited to the rights expressly granted in
            these Terms. No licences to use any of trademarks or any other Token
            Mill brands are to be inferred or assumed pursuant to the use of any
            Services. All rights not expressly granted to you are reserved and
            retained by Token Mill.
          </li>
        </ul>
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            You expressly agree not to duplicate, copy, transmit, distribute,
            license, reverse engineer, modify, publish, reuse or participate in
            the transfer or sale of, create derivative works from, or in any
            other way exploit any of the intellectual property of Token Mill
            without the express prior written consent of Token Mill.
          </li>
          <li>
            You agree and grant Token Mill a worldwide, perpetual, irrevocable,
            royalty-free, sublicensable and transferable right and license to
            use, reproduce, communicate, distribute, copy, modify, delete in its
            entirety, edit, adapt, publish, translate, publicly display,
            publicly perform, use, create derivative works from and/or sell
            and/or distribute any source code, designs, creative ideas,
            suggestions, proposal, plans, data or any other material or content
            submitted to the Platform, or incorporate such content into any
            form, medium or technology without compensation or further reference
            to you. You are solely responsible for all such content and you
            agree that these will not violate any right of any third-party,
            including copyright, trademark, privacy, personality or other
            personal or proprietary right. This license will survive the
            termination of these Terms.
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        8. NO WARRANTY, LIMITATION OF LIABILITY
      </Heading>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            <Text as="b">
              TO THE FULLEST EXTENT PERMISSIBLE BY LAW, TOKEN MILL DOES NOT
              WARRANT THAT THE PLATFORM OR ANY OF THE SERVICES AVAILABLE THROUGH
              IT WILL BE UNINTERRUPTED OR FREE FROM ERRORS. THERE MAY BE DELAYS,
              OMISSIONS, INTERRUPTIONS AND INACCURACIES IN MATERIALS OR SERVICES
              AVAILABLE THROUGH THE WEBSITE OR THAT THE SERVICES WILL MEET ANY
              PARTICULAR CRITERIA OF PERFORMANCE OR QUALITY. WE MAKE NO
              REPRESENTATION OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS,
              TIMELINESS, RELIABILITY OR NON-INFRINGEMENT OF ANY CONTENT ON THE
              WEBSITE OR SERVICES AVAILABLE THROUGH IT.
            </Text>
          </li>
          <li>
            <Text as="b">
              IN NO EVENT SHALL TOKEN MILL, ITS AFFILIATES AND SERVICE
              PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS,
              JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES (COLLECTIVELY THE
              TOKEN MILL INDEMNIFIED PARTIES), BE LIABLE FOR INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL OR OTHER LOSSES OF ANY KIND, IN TORT,
              CONTRACT OR OTHERWISE (INCLUDING BUT NOT LIMITED TO LOSS OF
              REVENUE, INCOME OR PROFITS, AND LOSS OF USE OR DATA), ARISING OUT
              OF OR IN CONNECTION WITH ANY ACCEPTANCE OF OR RELIANCE ON THESE
              TERMS OR THE WEBSITE, OR WITH THE USE OF THE PLATFORM AND/OR THE
              SERVICES, OR ANY ACTION TAKEN IN THE BEST INTEREST OF THE PLATFORM
              (AT THE SOLE DISCRETION OF TOKEN MILL).
            </Text>
          </li>
          <li>
            <Text as="b">
              THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND &quot;AS
              AVAILABLE&quot; BASIS WITHOUT ANY REPRESENTATION OR WARRANTY,
              WHETHER EXPRESS, IMPLIED, OR STATUTORY. TOKEN MILL SPECIFICALLY
              DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TOKEN MILL
              DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO ANY
              PART OF THE SERVICES, OR ANY OF THE MATERIALS CONTAINED THEREIN,
              WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, ERROR-FREE OR SECURE.
              TOKEN MILL ALSO SPECIFICALLY DISCLAIMS ANY REPRESENTATION,
              WARRANTY OR UNDERTAKING IN ANY FORM WHATSOEVER TO ANY ENTITY OR
              PERSON, INCLUDING ANY REPRESENTATION, WARRANTY OR UNDERTAKING IN
              RELATION TO THE TRUTH, ACCURACY AND COMPLETENESS OF ANY OF THE
              INFORMATION SET OUT IN THESE TERMS OR THE WEBSITE.
            </Text>
          </li>
        </ul>
      </Text>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            Token Mill shall not bear any liability, whatsoever, for any damage
            or interruptions caused by any computer malware, spyware, or
            scareware that may affect your computer or other equipment, or any
            phishing, spoofing or other attack.
          </li>
          <li>
            Electronic transmissions of data including the internet are public
            media and any use of such media is public not private. Information
            related to or arising from such use is either public or the property
            of those collecting the information and not personal or private
            information. Token Mill does not warrant and cannot ensure the
            security or confidentiality of any information you transmit to the
            Platform. Accordingly, any information you transmit to the Platform
            is transmitted at your own risk.
          </li>
          <li>
            In the event of a dispute between any user and any third party
            issuer, you shall, to the fullest extent permitted by law, release
            the Token Mill Indemnified Parties from any and all claims, demands
            and damages (actual and consequential) of every kind and nature
            arising out of or in any way connected with such disputes.
          </li>
          <li>
            Each user and third party issuer acknowledges and agrees that the
            total aggregate liability of the Token Mill Indemnified Parties
            (whether arising under contract, negligence, tort, breach of
            statutory duty or otherwise) to any user or third party issuer shall
            not exceed an amount equal to US$200.
          </li>
          <li>
            Nothing in these Terms is intended to affect your rights under law.
            Each user and third party issuer acknowledges and agrees that these
            Terms allocate the risks between Token Mill and such user or third
            party issuer (as the case may be), and Token Mill only agrees to
            provide the Services in reliance upon this allocation of risk and
            the exclusion of damages as set forth in these Terms.
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        9. INDEMNITY
      </Heading>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            By using the Platform, you agree, to the fullest extent permitted by
            law, to indemnify and hold Token Mill, its directors, officers,
            employees, affiliates, agents, contractors, and licensors harmless
            with respect to any claims arising out of your breach of these
            Terms, your use of the Platform or any of the Services (including
            any regulatory action against Token Mill in connection with the
            same), your violation of any rights (including intellectual property
            rights) of any third party, your non-compliance with applicable laws
            or regulations in the jurisdiction in which you are accessing the
            Platform or the Services, or any action taken by Token Mill as part
            of its investigation of a suspected violation of these Terms or as a
            result of its finding or decision that a violation of these Terms
            has occurred. You agree to defend, indemnify and hold harmless Token
            Mill and its affiliates from and against any and all claims,
            damages, costs and expenses, including attorneys&apos; fees, arising
            from or related to your use of the Platform or any breach by you of
            these Terms.
          </li>
          <li>
            In particular, each third party issuer shall indemnify and hold the
            Token Mill Indemnified Parties harmless from any claim or demand
            (including attorneys&apos; fees and any fines, fees or penalties
            imposed by any regulatory authority) arising out of or related to:
            <ul>
              <li>
                any harmful or malicious hidden code which disrupts, disables,
                damages, corrupts, harms, or otherwise impedes the Platform or
                the Project, viruses, worms, traps, back doors, access codes or
                trap door devices;
              </li>
              <li>
                technical flaws, disturbances, economic exploits or issues in or
                with respect, or attacks on the Project, including without
                limitation any double spend attack, denial of service attack,
                transaction malleability attack, timejacking attack, delay
                routing attack, Sybil attack, eclipse attack, refund attack,
                balance attack, punitive and feather forking attack, or
                blockchain rollback;
              </li>
              <li>
                any fraudulent false or materially misleading information
                provided to Token Mill, or any omission of material information;
              </li>
              <li>
                any civil or criminal inquiry or investigation by any
                governmental authority into the third party issuer or the
                Project, or any products, services or digital token in
                connection therewith, or the promoters or issuer of said
                Project; or
              </li>
              <li>
                any other incident, occurrence, fact or event that has (either
                individually or in the aggregate), or which Token Mill (in its
                sole discretion) believes may have an adverse effect on the
                Project, or any products, services or digital token in
                connection therewith, or the promoters or issuer of said
                Project.
              </li>
            </ul>
          </li>
        </ul>
      </Text>

      <Heading pt={4} size="md">
        10. MISCELLANEOUS
      </Heading>

      <Text fontSize="md" as="div">
        <ul>
          <li>
            If any provision of these Terms shall be determined to be invalid or
            unenforceable under any rule, law or regulation or any governmental
            agency, local, state, or federal, such provision will be changed and
            interpreted to accomplish the objectives of the provision to the
            greatest extent possible under any applicable law and the validity
            or enforceability of any other provision of these Terms shall not be
            affected.
          </li>
          <li>
            All provisions of these Terms which by their nature extend beyond
            the expiration or termination of these Terms, including, without
            limitation, Sections pertaining to suspension or termination or
            access, debts owed to Token Mill, general use of the Platform,
            disputes with Token Mill, and general provisions, shall survive the
            termination or expiration of these Terms.
          </li>
          <li>
            The language in all parts of these Terms shall in all cases be
            construed as a whole, according to its fair meaning, and not
            strictly against Token Mill. The parties agree, to the fullest
            extent permitted by applicable law, to waive any applicable rule
            requiring the construction of these Terms or any provision hereof
            against Token Mill as the party drafting these Terms. Section
            headings in these Terms are for convenience only and are not to be
            construed as a part of these Terms or in any way limiting or
            amplifying the provisions hereof.
          </li>
          <li>
            These Terms constitute the entire agreement between the parties with
            respect to the subject matter herein, and shall replace and
            supersede all prior agreements, negotiations, or understandings,
            whether written or oral, with respect to the subject matter herein,
            and may not be contradicted by evidence of any prior agreement.
          </li>
          <li>
            These Terms, and any rights and licenses granted hereunder, are not
            transferred or assigned by you, but may be assigned by Token Mill
            without restriction.
          </li>
          <li>
            Save as expressly provided to the contrary in these Terms, a person
            who is not a party to these Terms has no right to enforce or to
            enjoy the benefit of any term of these Terms. Notwithstanding any
            term of these Terms, no consent of any party who is not a party to
            these Terms shall be required for the waiver, variation or
            termination of any part of these Terms.
          </li>
          <li>
            If you have any feedback, questions, or complaints, contact us via
            email at [*]. When you contact us please provide us with your name,
            address, and any other information we may need to identify you, your
            digital address, and the issue on which you have feedback,
            questions, or complaints.
          </li>
          <li>
            The laws of Panama (with the exclusion of its conflicts of law rules
            that might lead to the application of any other law) will govern the
            validity and construction of these Terms.
          </li>
          <li>
            Any controversy, claim, dispute or disagreement that may arise
            between (a) any user or third party issuer and (b) Token Mill out of
            or in relation to these Terms shall be settled by arbitration,
            following an attempt at Conciliation, administered by Panama
            Conciliation and Arbitration Centre in accordance with its
            procedural rules for the time being in force. The tribunal shall
            consist of 1 arbitrator, who shall have exclusive authority to
            decide all issues relating to the interpretation, applicability,
            enforceability and scope of this Agreement (including this
            arbitration agreement). The language used in the arbitral
            proceedings shall be English. Each party irrevocably submits to the
            jurisdiction and venue of such tribunal. Judgment upon the award may
            be entered by any court having jurisdiction thereof or having
            jurisdiction over the relevant party or its assets.
          </li>
          <li>
            You understand that by using the Platform or any Services provided
            on the Platform, you may encounter content that may be deemed by
            some to be offensive, indecent, or objectionable, which content may
            or may not be identified as such. You agree to use the Platform and
            any service at your sole risk and that Token Mill and its affiliates
            shall have no liability to you for content that may be deemed
            offensive, indecent, or objectionable
          </li>
          <li>
            Token Mill is an independent party for all purposes. You acknowledge
            and agree that no joint venture, partnership, employment, or agency
            relationship exists between you and Token Mill as a result of your
            use of the Platform and/or the Services.
          </li>
          <li>
            These Terms constitute the entire agreement between you and Token
            Mill with respect to the subject matter hereof and supersedes any
            and all prior discussions, agreements and understandings of any kind
            (including without limitation any prior versions of these Terms),
            and every nature between and among you and Token Mill.
          </li>
        </ul>
      </Text>
    </Flex>
  )
}

export default TermsAndConditions
