import {
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { VestingSchedule } from 'types/market'

import VestedTokenTableRow from './VestedTokenTableRow'

interface Props {
  baseTokenAddress: string | undefined
  chain: Chain
  isLoading: boolean
  marketAddress: string
  marketCreatorAddress: string | undefined
  onReleaseSuccess: () => void
  vestingSchedules: VestingSchedule[] | undefined
}
const VestedTokensTable: React.FC<Props> = ({
  baseTokenAddress,
  chain,
  isLoading,
  marketAddress,
  marketCreatorAddress,
  onReleaseSuccess,
  vestingSchedules
}) => {
  return (
    <TableContainer w="full">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Beneficiary</Th>
            <Th>Vesting Schedule</Th>
            <Th>Cliff Date</Th>
            <Th>Unlock Rate</Th>
            <Th>Releasable</Th>
            <Th>Progress</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={6}>
                <Center minH="200px">
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          ) : vestingSchedules &&
            vestingSchedules.length > 0 &&
            baseTokenAddress ? (
            vestingSchedules.map((schedule, index) => (
              <VestedTokenTableRow
                key={index}
                schedule={schedule}
                chain={chain}
                baseTokenAddress={baseTokenAddress}
                onReleaseSuccess={onReleaseSuccess}
                marketAddress={marketAddress}
                marketCreatorAddress={marketCreatorAddress}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={6}>
                <Center h="200px">
                  <Text fontSize="sm">No vesting schedules found</Text>
                </Center>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default VestedTokensTable
