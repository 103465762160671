import useClaimCreatorFeesEVM from 'hooks/evm/useClaimCreatorFees'
import useClaimCreatorFeesSolana from 'solana/hooks/useClaimCreatorFees'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseClaimCreatorFeesProps {
  chain: Chain
  marketAddress: string
  quoteTokenAddress: string
  onClaimSuccess?: () => void
}

const useClaimCreatorFees = (props: UseClaimCreatorFeesProps) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const claimEVM = useClaimCreatorFeesEVM(props)
  const claimSolana = useClaimCreatorFeesSolana(props)

  switch (chainType) {
    case 'evm':
      return claimEVM
    case 'solana':
      return claimSolana
  }
}

export default useClaimCreatorFees
