import { createReducer } from '@reduxjs/toolkit'
import { TransactionsState } from 'types/transactions'

import {
  addTransaction,
  clearAllTransactions,
  clearTransactions,
  updateTransaction
} from './actions'

const initialState: TransactionsState = {}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addTransaction, (state, action) => {
      const { transaction, walletAddress } = action.payload
      if (!state[walletAddress]) {
        state[walletAddress] = []
      }
      state[walletAddress].push(transaction)
    })
    .addCase(updateTransaction, (state, action) => {
      const { transaction, walletAddress } = action.payload
      const walletTransactions = state[walletAddress]
      if (walletTransactions) {
        const index = walletTransactions.findIndex(
          (tx) => tx.hash === transaction.hash
        )
        if (index !== -1) {
          walletTransactions[index] = transaction
        }
      }
    })
    .addCase(clearTransactions, (state, action) => {
      const walletAddress = action.payload
      delete state[walletAddress]
    })
    .addCase(clearAllTransactions, () => {
      return {}
    })
)
