import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { RootState } from 'state/store'
import { ChainType } from 'types/chain'

interface ChainReferralInfo {
  code: string | undefined
  referrerAddress: string | undefined
}

type ReferralState = {
  [key in ChainType]: ChainReferralInfo
}

const initialState: ReferralState = {
  evm: { code: undefined, referrerAddress: undefined },
  solana: { code: undefined, referrerAddress: undefined }
}

const referralSlice = createSlice({
  initialState,
  name: 'referral',
  reducers: {
    setReferralCode: (
      state,
      action: PayloadAction<{ chain: ChainType; code: string | undefined }>
    ) => {
      const { chain, code } = action.payload
      state[chain].code = code
    },
    setReferralInfo: (
      state,
      action: PayloadAction<{
        chain: ChainType
        code: string | undefined
        referrerAddress: string | undefined
      }>
    ) => {
      const { chain, code, referrerAddress } = action.payload
      state[chain] = { code, referrerAddress }
    },
    setReferrerAddress: (
      state,
      action: PayloadAction<{ address: string | undefined; chain: ChainType }>
    ) => {
      const { address, chain } = action.payload
      state[chain].referrerAddress = address
    }
  }
})

export const { setReferralCode, setReferralInfo, setReferrerAddress } =
  referralSlice.actions

export const selectReferralState = (state: RootState) => state.referral

export default referralSlice.reducer

export const useReferralStorage = () => {
  const dispatch = useAppDispatch()

  const updateReferralInfo = useCallback(
    (
      chain: ChainType,
      code: string | undefined,
      referrerAddress: string | undefined
    ) => {
      dispatch(setReferralInfo({ chain, code, referrerAddress }))
    },
    [dispatch]
  )

  const referralState = useAppSelector((state) => selectReferralState(state))

  return { referralState, updateReferralInfo }
}
