import { ToastId, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useConnection } from '@solana/wallet-adapter-react'
import { TransactionSignature } from '@solana/web3.js'
import Popup from 'components/Popup'
import { useCallback } from 'react'
import React from 'react'
import {
  useAddTransaction,
  useUpdateTransaction
} from 'state/transactions/hooks'
import { Chain } from 'types/dexbarn'
import { TransactionStatus } from 'types/transactions'
import { getBlockExplorer } from 'utils/chains'
import { usePublicClient } from 'wagmi'

interface TransactionToastProps {
  chain: Chain
  description: string
  hash: string
  walletAddress: string
  confirmations?: number
}

const useTransactionToast = () => {
  const toast = useToast()
  const publicClient = usePublicClient()
  const { connection } = useConnection()

  const addTransaction = useAddTransaction()
  const updateTransaction = useUpdateTransaction()

  const updateToast = useCallback(
    (
      toastId: ToastId,
      description: string,
      status: 'success' | 'error' | 'warning',
      chain: Chain,
      hash: string,
      walletAddress: string,
      timestamp: number,
      subtitle?: string
    ) => {
      const blockExplorer = getBlockExplorer(hash, chain, 'tx')
      toast.update(toastId, {
        duration: 6500,
        render: (props) => (
          <Popup
            externalLink={{
              name: t`View on ${blockExplorer.name}`,
              url: blockExplorer.url
            }}
            summary={description}
            subtitle={subtitle}
            {...props}
          />
        ),
        status
      })

      let txStatus: TransactionStatus
      switch (status) {
        case 'success':
          txStatus = 'confirmed'
          break
        case 'error':
          txStatus = 'failed'
          break
        case 'warning':
          txStatus = 'unknown'
          break
      }

      updateTransaction(walletAddress, {
        chain,
        description,
        hash,
        status: txStatus,
        timestamp
      })
    },
    [toast, updateTransaction]
  )

  return useCallback(
    ({
      chain,
      confirmations,
      description,
      hash,
      walletAddress
    }: TransactionToastProps) => {
      const blockExplorer = getBlockExplorer(hash, chain, 'tx')
      const toastId = toast({
        duration: null,
        id: hash,
        position: 'top-right',
        render: (props) => (
          <Popup
            externalLink={{
              name: t`View on ${blockExplorer.name}`,
              url: blockExplorer.url
            }}
            summary={description}
            {...props}
          />
        ),
        status: 'loading'
      })

      const timestamp = Date.now() / 1000
      addTransaction(walletAddress, {
        chain,
        description,
        hash,
        status: 'pending',
        timestamp
      })

      const handleSuccess = () =>
        updateToast(
          toastId,
          description,
          'success',
          chain,
          hash,
          walletAddress,
          timestamp
        )
      const handleError = () =>
        updateToast(
          toastId,
          description,
          'error',
          chain,
          hash,
          walletAddress,
          timestamp
        )
      const handleWarning = () =>
        updateToast(
          toastId,
          description,
          'warning',
          chain,
          hash,
          walletAddress,
          timestamp,
          t`Unexpected Response: Your transaction was sent to the blockchain, but we didn't get a confirmation.`
        )

      switch (chain) {
        case 'avalanche':
          publicClient
            ?.waitForTransactionReceipt({
              confirmations,
              hash: hash as `0x${string}`
            })
            .then((receipt) => {
              receipt.status === 'success' ? handleSuccess() : handleError()
            })
            .catch(handleWarning)
          break
        case 'solana':
          const checkSolanaTransaction = async () => {
            try {
              const signature = hash as TransactionSignature

              const signatureStatuses = await connection.getSignatureStatuses(
                [signature],
                { searchTransactionHistory: true }
              )
              const signatureStatus =
                signatureStatuses.value.length > 0
                  ? signatureStatuses.value[0]
                  : null

              if (signatureStatus !== null) {
                if (signatureStatus.err) {
                  handleError()
                } else {
                  handleSuccess()
                }
                return
              }

              const latestBlockhash = await connection.getLatestBlockhash()
              const confirmation = await connection.confirmTransaction({
                blockhash: latestBlockhash.blockhash,
                lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                signature
              })

              confirmation.value.err ? handleError() : handleSuccess()
            } catch (error) {
              console.error('Error confirming Solana transaction:', error)
              handleWarning()
            }
          }

          checkSolanaTransaction()
          break
      }
    },
    [toast, publicClient, updateToast, connection, addTransaction]
  )
}

export default useTransactionToast
