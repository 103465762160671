import { IS_EVM_ENABLED } from 'constants/flag'
import { CNATIVE, WNATIVE } from 'constants/token'
import useGetMarketsFromSolbarn from 'hooks/barn/solana/useGetMarketsFromSolbarn'
import { useMemo } from 'react'
import useLiveMarketsSolana from 'solana/hooks/useLiveMarketsSolana'
import { Chain } from 'types/dexbarn'
import { Market } from 'types/market'
import { getChainId } from 'utils/chains'
import { getResizedImageUrl } from 'utils/image'

import useGetMarketsFromBarn, {
  UseGetMarketsParams
} from '../barn/evm/useGetMarketsFromBarn'
import useLiveMarketsEVM from '../evm/useLiveMarketsEVM'

const useGetTMMarkets = (
  params: {
    isPaused: boolean
  } & UseGetMarketsParams
) => {
  // fetch EVM data from barn
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingDexbarnMarkets
  } = useGetMarketsFromBarn(params)
  const dexbarnMarkets = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data]
  )

  // fetch solana data from solbarn
  const {
    data: solbarnData,
    fetchNextPage: fetchNextPageSolbarn,
    hasNextPage: hasNextPageSolbarn,
    isFetchingNextPage: isFetchingNextPageSolbarn,
    isLoading: isLoadingSolbarnMarkets
  } = useGetMarketsFromSolbarn(params)
  const solbarnMarkets = useMemo(
    () => solbarnData?.pages.flatMap((page) => page) || [],
    [solbarnData]
  )

  // live markets
  const { data: liveMarkets = [], pausedMarketsCount } = useLiveMarketsEVM({
    chain: params.chain,
    isPaused: params.isPaused
  })
  const {
    data: liveMarketsSolana = [],
    pausedMarketsCount: pausedMarketsCountSolana
  } = useLiveMarketsSolana({
    isPaused: params.isPaused
  })

  // process data
  const markets = useMemo(() => {
    const processedMarkets: Market[] = [...liveMarkets, ...liveMarketsSolana]

    const chainId = getChainId(params.chain)
    const nativeCurrencyEVM = CNATIVE[chainId]
    const wrappedNativeTokenEVM = WNATIVE[chainId]

    // TODO (EVM): read pct change fields
    processedMarkets.push(
      ...dexbarnMarkets.map((market) => {
        return {
          baseToken: market.baseToken,
          baseTokenPctChange1d: 0,
          baseTokenPctChange1h: 0,
          baseTokenPctChange5m: 0,
          baseTokenPctChange6h: 0,
          chain: params.chain,
          circulatingSupply: market.circulatingSupply,
          circulatingSupplyUsd: market.baseMarketCapUsd,
          createdAt: new Date(market.marketCreatedAt),
          description: market.description,
          liquidityUsd: market.quoteLiquidityUsd,
          logoUrl: getResizedImageUrl(market.iconUrl) || '',
          marketAddress: market.marketAddress,
          numberBuys: market.numberBids,
          numberSells: market.numberAsks,
          quoteToken: {
            address: market.quoteToken.address,
            decimals: market.quoteToken.decimals,
            name: market.quoteToken.name,
            priceNative: market.quoteToken.priceNative,
            priceUsd: market.quoteToken.priceUsd,
            symbol:
              market.quoteToken.address.toLowerCase() ===
              wrappedNativeTokenEVM.address.toLowerCase()
                ? nativeCurrencyEVM.symbol
                : market.quoteToken.symbol
          },
          stakingApr: 0,
          stakingPct: 0,
          vestingPct: 0,
          volumeUsd: market.volumeUsd
        }
      })
    )

    processedMarkets.push(
      ...solbarnMarkets.map((market) => {
        return {
          baseToken: {
            address: market.base_token.address,
            decimals: market.base_token.decimals,
            name: market.base_token.name,
            priceNative: market.base_token.price_native.toString(),
            priceUsd: market.base_token.price_usd,
            symbol: market.base_token.symbol
          },
          baseTokenPctChange1d: market.base_token_pct_change_1d,
          baseTokenPctChange1h: market.base_token_pct_change_1h,
          baseTokenPctChange5m: market.base_token_pct_change_5m,
          baseTokenPctChange6h: market.base_token_pct_change_6h,
          chain: 'solana' as Chain,
          circulatingSupply: market.circulating_supply,
          circulatingSupplyUsd: market.base_market_cap_usd,
          createdAt: new Date(market.market_created_at),
          description: market.description,
          liquidityUsd: market.quote_liquidity_usd,
          logoUrl: getResizedImageUrl(market.icon_url) || '',
          marketAddress: market.market_address,
          numberBuys: market.number_bids,
          numberSells: market.number_asks,
          quoteToken: {
            address: market.quote_token.address,
            decimals: market.quote_token.decimals,
            name: market.quote_token.name,
            priceNative: market.quote_token.price_native.toString(),
            priceUsd: market.quote_token.price_usd,
            symbol: market.quote_token.symbol.slice(0, 6)
          },
          stakingApr: market.staking_apr,
          stakingPct: market.staking_pct,
          vestingPct: market.vesting_pct,
          volumeUsd: market.volume_usd
        }
      })
    )

    // TODO (EVM): how do we aggregate markets from EVM and solana
    // const sortedMarkets = processedMarkets.sort((a, b) => {
    //   switch (params.orderBy) {
    //     case 'liquidity':
    //       return b.liquidityUsd - a.liquidityUsd
    //     case 'name':
    //       return a.baseToken.name.localeCompare(b.baseToken.name)
    //     case 'new':
    //       return b.createdAt.getTime() - a.createdAt.getTime()
    //     case 'volume':
    //       return b.volumeUsd - a.volumeUsd
    //     case 'market_cap':
    //       return b.circulatingSupplyUsd - a.circulatingSupplyUsd
    //     case 'num_swaps':
    //     case 'num_buys':
    //     case 'num_sells':
    //     case 'apeing':
    //     case 'dumping':
    //     case 'trending':
    //     case 'pct_change':
    //     case undefined:
    //       // These fields are not available in the current market object
    //       // Fallback to sorting by volume
    //       return 0
    //   }
    // })

    // if (!params.isDesc) {
    //   sortedMarkets.reverse()
    // }

    return processedMarkets
  }, [
    dexbarnMarkets,
    params.chain,
    liveMarkets,
    liveMarketsSolana,
    solbarnMarkets
  ])

  return {
    data: markets,
    fetchNextPage: () => {
      if (IS_EVM_ENABLED) {
        fetchNextPage()
      }
      fetchNextPageSolbarn()
    },
    hasNextPage: (hasNextPage && IS_EVM_ENABLED) || hasNextPageSolbarn,
    isFetchingNextPage: isFetchingNextPage || isFetchingNextPageSolbarn,
    isLoading: isLoadingDexbarnMarkets || isLoadingSolbarnMarkets,
    pausedMarketsCount: pausedMarketsCount + pausedMarketsCountSolana
  }
}

export default useGetTMMarkets
