import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Transaction } from 'types/transactions'

import {
  addTransaction,
  clearAllTransactions,
  clearTransactions,
  updateTransaction
} from './actions'

export const useWalletTransactions = (walletAddress: string) => {
  return useAppSelector((state) =>
    (state.transactions[walletAddress] || []).toReversed()
  )
}

export const useAddTransaction = () => {
  const dispatch = useAppDispatch()

  return useCallback(
    (walletAddress: string, transaction: Transaction) => {
      dispatch(
        addTransaction({
          transaction,
          walletAddress
        })
      )
    },
    [dispatch]
  )
}

export const useUpdateTransaction = () => {
  const dispatch = useAppDispatch()

  return useCallback(
    (walletAddress: string, transaction: Transaction) => {
      dispatch(updateTransaction({ transaction, walletAddress }))
    },
    [dispatch]
  )
}

export const useClearWalletTransactions = (walletAddress: string) => {
  const dispatch = useAppDispatch()

  return useCallback(() => {
    dispatch(clearTransactions(walletAddress))
  }, [dispatch, walletAddress])
}

export const useClearAllTransactions = () => {
  const dispatch = useAppDispatch()

  return useCallback(() => {
    dispatch(clearAllTransactions())
  }, [dispatch])
}
