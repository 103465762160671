import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useCallback, useEffect, useState } from 'react'
import { formatUnits } from 'viem'

interface TokenBalance {
  decimals: number
  formatted: string
  value: bigint
}

interface UseSolBalanceProps {
  enabled?: boolean
}

const useSolBalance = ({ enabled = true }: UseSolBalanceProps = {}) => {
  const { connection } = useConnection()
  const { publicKey } = useWallet()
  const [balance, setBalance] = useState<TokenBalance | undefined>(undefined)
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchBalance = useCallback(async () => {
    if (!enabled || !publicKey) {
      setBalance(undefined)
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const solBalance = await connection.getBalance(publicKey)
      const decimals = 9
      const value = BigInt(solBalance)

      setBalance({
        decimals,
        formatted: formatUnits(value, decimals),
        value
      })
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Can't fetch SOL balance")
      )
    } finally {
      setIsLoading(false)
    }
  }, [connection, publicKey, enabled])

  useEffect(() => {
    fetchBalance()
  }, [fetchBalance])

  const refetch = useCallback(() => {
    fetchBalance()
  }, [fetchBalance])

  return { data: balance, error, isLoading, refetch }
}

export default useSolBalance
