import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketData, TMMarketQueryParam } from 'types/dexbarn'

interface UseGetMarketFromBarnProps {
  chain: Chain
  enabled: boolean
  filterBy: TMMarketQueryParam.FilterBy
  marketAddress: string
}

const useGetMarketFromBarn = ({
  chain,
  enabled,
  filterBy,
  marketAddress
}: UseGetMarketFromBarnProps) => {
  const fetchMarket = useDexbarnGet<MarketData>(
    `/v1/markets/${chain}/${marketAddress}?filterBy=${filterBy}`
  )

  return useQuery<MarketData>({
    enabled,
    queryFn: () => fetchMarket(),
    queryKey: ['GetMarketEVM', marketAddress, chain, filterBy]
  })
}

export default useGetMarketFromBarn
