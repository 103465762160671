import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import React from 'react'

import SettingScreen from './index'

interface PopoverSettingsMenuProps {
  popoverTrigger: JSX.Element
}

const PopoverSettingsMenu = ({ popoverTrigger }: PopoverSettingsMenuProps) => {
  return (
    <Popover trigger="hover" placement="bottom-end" closeOnBlur={false}>
      <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
      <PopoverContent
        py={4}
        minW="450px"
        zIndex={5}
        maxH="80vh"
        overflowY="auto"
      >
        <SettingScreen />
      </PopoverContent>
    </Popover>
  )
}

export default PopoverSettingsMenu
