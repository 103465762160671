import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { chains } from 'constants/chains'
import useSwitchChain from 'hooks/evm/useSwitchChain'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { getChainId } from 'utils/chains'
import { useAccount } from 'wagmi'

interface Web3ButtonProps extends ButtonProps {
  chain: Chain
}

const Web3Button = forwardRef<Web3ButtonProps, 'button'>(
  ({ chain, ...props }, ref) => {
    const targetChain = chain
    const isTargetChainEVM = targetChain === 'avalanche'
    const targetChainId = getChainId(targetChain)
    const targetChainName = isTargetChainEVM
      ? chains.find((c) => c.id === targetChainId)?.name
      : 'Solana'

    const { openConnectModal } = useConnectModal()
    const { chain: walletChainEVM, isConnected: isEvmConnected } = useAccount()
    const { publicKey: solanaPublicKey } = useWallet()
    const { switchChain } = useSwitchChain()
    const { setVisible: setSolanaConnectModalVisible } = useWalletModal()

    const isSolanaConnected = !!solanaPublicKey
    const isConnected = isTargetChainEVM ? isEvmConnected : isSolanaConnected

    const isChainUnsupported = isTargetChainEVM
      ? targetChainId !== walletChainEVM?.id
      : false

    const handleConnect = () => {
      switch (targetChain) {
        case 'avalanche':
          openConnectModal?.()
          break
        case 'solana':
          setSolanaConnectModalVisible(true)
          break
      }
    }

    if (isConnected && isChainUnsupported) {
      return (
        <Button
          {...props}
          ref={ref}
          leftIcon={undefined}
          rightIcon={undefined}
          isDisabled={false}
          onClick={() => switchChain({ chainId: targetChainId })}
        >
          {t`Switch to ${targetChainName}`}
        </Button>
      )
    } else if (isConnected) {
      return <Button {...props} ref={ref} />
    } else {
      return (
        <Button {...props} ref={ref} isDisabled={false} onClick={handleConnect}>
          [<Trans>Connect Wallet</Trans>]
        </Button>
      )
    }
  }
)

export default Web3Button
