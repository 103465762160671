import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Center,
  HStack,
  Link,
  Spacer,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useWallet } from '@solana/wallet-adapter-react'
import React from 'react'
import { useWalletTransactions } from 'state/transactions/hooks'
import { TransactionStatus } from 'types/transactions'
import { getBlockExplorer } from 'utils/chains'
import { useAccount } from 'wagmi'

const getTransactionStatusTranslation = (status: TransactionStatus) => {
  switch (status) {
    case 'confirmed':
      return t`Confirmed`
    case 'failed':
      return t`Failed`
    case 'pending':
      return t`Pending`
    case 'unknown':
      return t`Unknown`
  }
}

const ActivityList = () => {
  const { address: evmAddress } = useAccount()
  const { publicKey } = useWallet()
  const solanaAddress = publicKey?.toBase58()

  const evmTransactions = useWalletTransactions(evmAddress || '')
  const solanaTransactions = useWalletTransactions(solanaAddress || '')
  const transactions = [...evmTransactions, ...solanaTransactions].sort(
    (a, b) => b.timestamp - a.timestamp
  )

  const latestTransactions = transactions.slice(0, 10)

  const CONFIRMATION_COLOR_MAP: { [key in TransactionStatus]: string } = {
    confirmed: 'candleGreen',
    failed: 'candleRed',
    pending: 'yellow.500',
    unknown: 'textSecondary'
  }

  return (
    <VStack spacing={0}>
      {latestTransactions.length === 0 ? (
        <Center h="100px">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>Most recent transactions will appear here...</Trans>
          </Text>
        </Center>
      ) : (
        latestTransactions.map((transaction) => {
          const blockExplorer = getBlockExplorer(
            transaction.hash,
            transaction.chain,
            'tx'
          )
          return (
            <Link
              key={transaction.hash}
              isExternal
              href={blockExplorer.url}
              py={2}
              px={{ base: 0, md: 4 }}
              _hover={{ bg: 'hover' }}
              w="full"
              fontSize="sm"
            >
              <HStack w="full">
                <VStack align="flex-start" spacing={0}>
                  <Text>{transaction.description}</Text>
                  <HStack>
                    <Text
                      textAlign="left"
                      fontWeight="normal"
                      color={CONFIRMATION_COLOR_MAP[transaction.status] ?? {}}
                    >
                      {getTransactionStatusTranslation(
                        transaction.status as TransactionStatus
                      )}
                    </Text>
                    {transaction.status === 'pending' ? (
                      <Spinner size="xs" color="yellow.500" />
                    ) : null}
                  </HStack>
                </VStack>

                <Spacer />

                <ExternalLinkIcon />
              </HStack>
            </Link>
          )
        })
      )}
    </VStack>
  )
}

export default ActivityList
