import { t } from '@lingui/macro'
import { TokenMillRouterAbi } from 'constants/abi/tokenMillRouter'
import { TM_ROUTER } from 'constants/addresses'
import { UseSwapProps } from 'hooks/tokenmill/useSwap'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { TokenMillQuoteEVM } from 'types/quote'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { PackedRoute } from 'utils/swap'
import { BaseError, formatUnits, getAddress, zeroAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

import useRouterSimulateSingle from './useRouterSimulateSingle'
import useWaitForTransactionReceipt from './useWaitForTransactionReceipt'

const useSwap = ({
  allowedSlippageBps,
  amountIn,
  chain,
  currencyIn,
  currencyOut,
  enabled,
  onSwapSuccess,
  referrerAddress
}: UseSwapProps) => {
  const chainId = getChainId(chain)
  const account = useAccount()
  const addTransactionToast = useTransactionToast()

  const { packedRoute, route, value } = useMemo(() => {
    if (!enabled) {
      return { packedRoute: undefined, route: undefined, value: undefined }
    }

    const route =
      currencyIn && currencyOut
        ? [
            currencyIn.address ? currencyIn.address : zeroAddress,
            currencyOut.address ? currencyOut.address : zeroAddress
          ]
        : undefined

    const packedRoute = route
      ? PackedRoute.encode(route, [[3, 0, 0]])
      : undefined

    const value =
      currencyIn && currencyIn.address === zeroAddress ? amountIn : BigInt(0)

    return {
      packedRoute,
      route,
      value
    }
  }, [currencyIn, currencyOut, amountIn, enabled])

  const { data: simulatedAmountOut, isLoading: isSimulating } =
    useRouterSimulateSingle({
      amount: amountIn,
      chain,
      enabled,
      packedRoute,
      value
    })

  const { amountOut, args, minAmountOut } = useMemo(() => {
    if (!simulatedAmountOut || !currencyOut || !amountIn || !account.address) {
      return {}
    }

    const amountOut = {
      formatted: formatUnits(simulatedAmountOut, currencyOut.decimals),
      value: simulatedAmountOut
    }

    const scaledMinAmountOut =
      (amountOut.value * (BigInt(10000) - BigInt(allowedSlippageBps))) /
      BigInt(10000)

    const minAmountOut = {
      formatted: formatUnits(scaledMinAmountOut, currencyOut.decimals),
      value: scaledMinAmountOut
    }

    const deadline = Math.round(Date.now() / 1000 + 60 * 5) // 5mins

    const args =
      packedRoute && amountIn && account.address && deadline
        ? ([
            packedRoute,
            account.address,
            amountIn,
            minAmountOut.value,
            BigInt(deadline),
            referrerAddress ? getAddress(referrerAddress) : zeroAddress
          ] as const)
        : undefined

    return { amountOut, args, minAmountOut }
  }, [
    simulatedAmountOut,
    currencyOut,
    packedRoute,
    amountIn,
    account.address,
    allowedSlippageBps,
    referrerAddress
  ])

  const swapExactInSimulateResult = useSimulateContract({
    abi: TokenMillRouterAbi,
    address: TM_ROUTER,
    args,
    chainId,
    functionName: 'swapExactIn',
    query: {
      enabled: !!args && enabled,
      gcTime: 0
    },
    value: value
  })

  const feeOnTransferSimulateResult = useSimulateContract({
    abi: TokenMillRouterAbi,
    address: TM_ROUTER,
    args,
    chainId,
    functionName: 'swapExactInSupportingFeeOnTransferTokens',
    query: {
      enabled: !!args && enabled
    },
    value
  })

  const quote = useMemo(() => {
    if (
      simulatedAmountOut &&
      currencyIn &&
      currencyOut &&
      amountIn &&
      amountIn &&
      route &&
      packedRoute &&
      amountOut &&
      minAmountOut
    ) {
      return {
        amountIn: {
          formatted: formatUnits(amountIn, currencyIn.decimals),
          value: amountIn
        },
        amountOut,
        currencyIn,
        currencyOut,
        minAmountOut,
        packedRoute,
        route
      } satisfies TokenMillQuoteEVM
    }

    return undefined
  }, [
    simulatedAmountOut,
    currencyIn,
    currencyOut,
    amountIn,
    route,
    packedRoute,
    amountOut,
    minAmountOut
  ])

  const transactionSummary = useMemo(() => {
    if (!quote) return ''
    const inputSymbol = quote.currencyIn.symbol
    const outputSymbol = quote.currencyOut.symbol
    return t`Swap ${formattedNum(
      quote.amountIn.formatted
    )} ${inputSymbol} for ${formattedNum(
      quote.amountOut.formatted
    )} ${outputSymbol}`
  }, [quote])

  const config = getConfigWithGasLimitIncreased({
    config: swapExactInSimulateResult.isError
      ? feeOnTransferSimulateResult.data
      : swapExactInSimulateResult.data,
    percentageIncrease: 10
  })

  const {
    data: hash,
    isPending,
    reset,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        addTransactionToast({
          chain,
          description: transactionSummary,
          hash,
          walletAddress: account.address || ''
        })
      }
    }
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    chain,
    hash,
    onTransactionSuccess: onSwapSuccess
  })

  const error = useMemo(() => {
    if (
      !swapExactInSimulateResult.error ||
      !feeOnTransferSimulateResult.error
    ) {
      return undefined
    }

    const defaultErrorSummary = swapExactInSimulateResult.error
      ? (swapExactInSimulateResult.error as BaseError).shortMessage
      : undefined
    const feeOnTransferErrorSummary = feeOnTransferSimulateResult.error
      ? (feeOnTransferSimulateResult.error as BaseError).shortMessage
      : undefined

    const isErrorDueToSlippageTooLow =
      swapExactInSimulateResult.error?.message?.includes(
        'LBRouter__InsufficientAmountOut'
      ) ||
      feeOnTransferSimulateResult.error?.message?.includes(
        'LBRouter__InsufficientAmountOut'
      )

    const message =
      swapExactInSimulateResult.error?.message ||
      feeOnTransferSimulateResult.error?.message
    const summary =
      defaultErrorSummary ||
      feeOnTransferErrorSummary ||
      t`Error: there was a problem preparing the transaction. Please try again.`

    if (!message) {
      return undefined
    }

    return {
      message,
      summary: isErrorDueToSlippageTooLow
        ? t`Error: the slippage is too low for this trade.`
        : summary
    }
  }, [swapExactInSimulateResult, feeOnTransferSimulateResult])

  return {
    error,
    isLoadingQuote: isSimulating,
    isSwapping: isPending || isWaitingForTransaction,
    quote,
    resetSwap: reset,
    swapAsync: config ? () => writeContractAsync(config.request) : undefined
  }
}

export default useSwap
