import { UseTokenBalanceProps } from 'hooks/useTokenBalance'
import { getChainId, getChainType } from 'utils/chains'
import { getAddress, isAddress } from 'viem'
import { useAccount, useBalance } from 'wagmi'

const useTokenBalance = ({ chain, enabled, token }: UseTokenBalanceProps) => {
  const { address: account } = useAccount()
  const chainId = chain ? getChainId(chain) : undefined
  const chainType = chain ? getChainType(chain) : undefined

  return useBalance({
    address: account,
    chainId,
    query: {
      enabled: enabled && !!account && chainType === 'evm' && token !== 'native'
    },
    token: token && isAddress(token) ? getAddress(token) : undefined
  })
}

export default useTokenBalance
