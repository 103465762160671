import { ChevronDownIcon } from '@chakra-ui/icons'
import { Heading, HStack } from '@chakra-ui/react'
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import React from 'react'

import TransactionSettings from './index'

const MobileTransactionSettings = () => {
  return (
    <HStack justify="space-between">
      <Heading size="sm">Priority Fee</Heading>

      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            h="44px"
            flexShrink={0}
            rightIcon={<ChevronDownIcon />}
          >
            [Txn Settings]
          </Button>
        </PopoverTrigger>
        <PopoverContent width="fit-content">
          <PopoverBody>
            <TransactionSettings />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  )
}

export default MobileTransactionSettings
