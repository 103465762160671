import useSwapEVM from 'hooks/evm/useSwap'
import useGetReferrerAddressOnChain from 'hooks/referrals/useGetReferrerAddress'
import useSwapSolana from 'solana/hooks/useSwap'
import { Chain } from 'types/dexbarn'
import { Token } from 'types/token'
import { getChainType } from 'utils/chains'

import useAccountOnChain from './useAccountOnChain'

export interface UseSwapProps {
  allowedSlippageBps: number
  chain: Chain
  enabled: boolean
  marketAddress: string
  onSwapSuccess: () => void
  amountIn?: bigint
  currencyIn?: Token
  currencyOut?: Token
  referrerAddress?: string
}

const useSwap = (props: UseSwapProps) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const account = useAccountOnChain(chain)
  const { data: referrerAddress } = useGetReferrerAddressOnChain({
    chain,
    enabled: props.enabled
  })
  const isSelfReferral =
    referrerAddress?.toLowerCase() === account.address?.toLowerCase()

  const swapEVM = useSwapEVM({
    ...props,
    enabled: props.enabled && chainType === 'evm',
    referrerAddress: isSelfReferral ? undefined : referrerAddress
  })

  const swapSolana = useSwapSolana({
    ...props,
    enabled: props.enabled && chainType === 'solana',
    referrerAddress: isSelfReferral ? undefined : referrerAddress
  })

  switch (chain) {
    case 'avalanche':
      return swapEVM
    case 'solana':
      return swapSolana
  }
}

export default useSwap
