import { useWallet } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { PortfolioToken } from 'types/portfolio'
import { SolbarnUserToken } from 'types/solbarn'

function useUserOwnedTokens() {
  const { publicKey } = useWallet()

  const fetchUserTokens = useSolbarnGet<SolbarnUserToken[]>(
    `/users/portfolio/${publicKey?.toBase58()}`
  )

  return useQuery({
    enabled: !!publicKey,
    queryFn: fetchUserTokens,
    queryKey: ['solanaTokens', publicKey?.toBase58()],
    select: (data) => {
      return data
        .map((token) => {
          return {
            amount: token.amount,
            chain: 'solana',
            marketAddress: token.market_address,
            priceUsd: token.token.price_usd,
            tokenAddress: token.token.address,
            tokenLogoURI: token.icon_url,
            tokenSymbol: token.token.symbol,
            valueUsd: token.amount_usd
          } satisfies PortfolioToken
        })
        .filter((token) => token.amount > 0)
    }
  })
}

export default useUserOwnedTokens
