import { t } from '@lingui/macro'
import { TokenMillFactoryAbi } from 'constants/abi/tokenMillFactory'
import { TM_FACTORY_ADDRESS } from 'constants/addresses'
import { UseClaimCreatorFeesProps } from 'hooks/tokenmill/useClaimCreatorFees'
import useTransactionToast from 'hooks/useTransactionToast'
import { getChainId } from 'utils/chains'
import { getAddress, isAddress } from 'viem'
import {
  useAccount,
  usePublicClient,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

const useClaimCreatorFeesEVM = ({
  chain,
  marketAddress,
  onClaimSuccess
}: UseClaimCreatorFeesProps) => {
  const chainId = getChainId(chain)
  const publicClient = usePublicClient({ chainId })
  const { address: account, chain: walletChain } = useAccount()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: TokenMillFactoryAbi,
    address: TM_FACTORY_ADDRESS,
    args: isAddress(marketAddress) ? [getAddress(marketAddress)] : undefined,
    chainId,
    functionName: 'claimFees',
    query: {
      enabled: walletChain?.id === chainId && !!marketAddress,
      gcTime: 0
    }
  })

  const { isPending, reset, writeContractAsync } = useWriteContract({
    mutation: {
      onSuccess: async (hash) => {
        if (!publicClient) return

        const transactionSummary = t`Claimed creator fees`

        addTransactionToast({
          chain,
          description: transactionSummary,
          hash,
          walletAddress: account || ''
        })

        const receipt = await publicClient.waitForTransactionReceipt({ hash })
        if (receipt.status === 'success') {
          onClaimSuccess?.()
        }
      }
    }
  })

  const claimAsync = config?.request
    ? async () => await writeContractAsync(config.request)
    : undefined

  return {
    claimAsync,
    isClaiming: isPending,
    resetClaim: reset
  }
}

export default useClaimCreatorFeesEVM
