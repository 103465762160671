import { IS_TESTNET } from 'constants/chains'
import { SOLBARN_TESTNET_WSS_URL, SOLBARN_WSS_URL } from 'constants/dexbarn'
import { CNATIVE } from 'constants/token'
import { UseLiveTradesProps } from 'hooks/tokenmill/useLiveTrades'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { RecentTrade } from 'types/market'
import { getChainId } from 'utils/chains'
import { formatUnits } from 'viem'

interface WSTradeData {
  base_amount: string
  base_amount_native: string
  base_amount_usd: string
  base_token_id: string
  base_token_price_native: string
  base_token_price_usd: string
  block_number: number
  chain: Chain
  id: string
  index: number
  inner_index: number
  market_id: string
  quote_amount: string
  quote_amount_native: string
  quote_amount_usd: string
  quote_token_id: string
  quote_token_price_native: string
  quote_token_price_usd: string
  sender: string
  signature: string
  swap_type: string
  timestamp: number
}

const useLiveTrades = ({
  baseTokenSymbol,
  chain,
  isPaused,
  marketAddress
}: UseLiveTradesProps) => {
  const chainId = getChainId(chain)
  const nativeCurrency = CNATIVE[chainId]

  const [liveTrades, setLiveTrades] = useState<WSTradeData[]>([])
  const [lastPausedTimestamp, setLastPausedTimestamp] = useState<number | null>(
    null
  )
  const wsRef = useRef<WebSocket | null>(null)

  useEffect(() => {
    if (isPaused) {
      setLastPausedTimestamp(Date.now())
    } else {
      setLastPausedTimestamp(null)
    }
  }, [isPaused])

  const filteredTrades = useMemo(() => {
    if (!lastPausedTimestamp) return liveTrades
    return liveTrades.filter((trade) => trade.timestamp <= lastPausedTimestamp)
  }, [liveTrades, lastPausedTimestamp])

  useEffect(() => {
    const enabled = chain === 'solana'
    if (!enabled) {
      if (wsRef.current) {
        wsRef.current.close()
        wsRef.current = null
      }
      return
    }

    wsRef.current = new WebSocket(
      `${
        IS_TESTNET ? SOLBARN_TESTNET_WSS_URL : SOLBARN_WSS_URL
      }/markets/ws/${marketAddress}`
    )

    wsRef.current.onmessage = (event) => {
      const newTrade: WSTradeData = {
        ...JSON.parse(event.data),
        timestamp: Date.now()
      }

      setLiveTrades((prevTrades) => {
        const exists = prevTrades.some(
          (trade) => trade.signature === newTrade.signature
        )
        if (exists) return prevTrades

        return [newTrade, ...prevTrades]
      })
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close()
      }
    }
  }, [marketAddress, chain])

  const trades: RecentTrade[] = useMemo(() => {
    if (chain !== 'solana') return []

    return filteredTrades.map((trade) => {
      let swapType: 'ask' | 'bid'
      if (trade.swap_type.includes('Buy')) {
        swapType = 'bid'
      } else {
        swapType = 'ask'
      }

      const baseToken = {
        address: trade.base_token_id,
        decimals: 6,
        priceUsd: parseFloat(trade.base_token_price_usd),
        symbol: baseTokenSymbol
      }

      const quoteToken = {
        address: trade.quote_token_id,
        decimals: 9,
        priceUsd: parseFloat(trade.quote_token_price_usd),
        symbol: nativeCurrency.symbol
      }

      return {
        amountIn: formatUnits(
          BigInt(swapType === 'ask' ? trade.base_amount : trade.quote_amount),
          swapType === 'ask' ? baseToken.decimals : quoteToken.decimals
        ),
        amountOut: formatUnits(
          BigInt(swapType === 'ask' ? trade.quote_amount : trade.base_amount),
          swapType === 'ask' ? quoteToken.decimals : baseToken.decimals
        ),
        baseTokenPriceUsd: Number(trade.base_token_price_usd),
        orderType: swapType,
        timestamp: new Date(trade.timestamp || Date.now()).toISOString(),
        tokenIn: swapType === 'ask' ? baseToken : quoteToken,
        tokenOut: swapType === 'ask' ? quoteToken : baseToken,
        txnHash: trade.signature,
        userAddress: trade.sender
      }
    })
  }, [filteredTrades, baseTokenSymbol, nativeCurrency.symbol, chain])

  const pausedDataCount = useMemo(() => {
    if (!lastPausedTimestamp) return 0
    return liveTrades.filter((trade) => trade.timestamp > lastPausedTimestamp)
      .length
  }, [liveTrades, lastPausedTimestamp])

  return {
    data: trades,
    pausedDataCount
  }
}

export default useLiveTrades
