import binanceWallet from '@binance/w3w-rainbow-connector-v2'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  bitgetWallet,
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  safeWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets'
import { http } from 'viem'
import { createConfig } from 'wagmi'

import { chains, TokenMillChainId } from './chains'

const walletConnectProjectId =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        coinbaseWallet,
        metaMaskWallet,
        binanceWallet,
        walletConnectWallet,
        braveWallet,
        injectedWallet,
        rabbyWallet,
        okxWallet,
        safeWallet,
        bitgetWallet
      ]
    }
  ],
  { appName: 'Token Mill', projectId: walletConnectProjectId }
)

export const wagmiConfig = createConfig({
  batch: { multicall: true },
  chains,
  connectors,
  transports: {
    [TokenMillChainId.FUJI]: http()
  }
})
