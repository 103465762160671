import {
  Box,
  Divider,
  Heading,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import ConnectWalletPlaceholder from 'components/ConnectWalletPlaceholder'
import { IS_EVM_ENABLED } from 'constants/flag'
import useGetUserReferralCodes from 'hooks/referrals/useGetUserReferralCodes'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import React from 'react'

import ReferralTableRow, { ReferralTableRowProps } from './ReferralTableRow'

const Referrals = () => {
  const { address: addressEVM } = useAccountOnChain('avalanche')
  const { address: addressSolana } = useAccountOnChain('solana')

  const {
    data: userReferralCodes,
    isLoading: isLoadingUserReferralCodes,
    refetch: refetchUserReferralCodes
  } = useGetUserReferralCodes()

  const onDeleteReferralCodeSuccess = () => {
    refetchUserReferralCodes()
  }

  const referralData: ReferralTableRowProps[] = [
    {
      chain: 'solana',
      isLoadingUserReferralCodes,
      nativeCurrencySymbol: 'SOL',
      onDeleteReferralCodeSuccess,
      referralCode: userReferralCodes?.solana,
      walletAddress: addressSolana
    }
  ]

  if (IS_EVM_ENABLED) {
    referralData.push({
      chain: 'avalanche',
      isLoadingUserReferralCodes,
      nativeCurrencySymbol: 'AVAX',
      onDeleteReferralCodeSuccess,
      referralCode: userReferralCodes?.avalanche,
      walletAddress: addressEVM
    })
  }

  if (!(addressEVM && IS_EVM_ENABLED) && !addressSolana) {
    return (
      <ConnectWalletPlaceholder
        title="Referrals"
        description="Connect your wallet to create and view referral codes"
      />
    )
  }

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <Box p={{ base: 4, md: 6 }}>
        <Heading size="sm">Referrals</Heading>
      </Box>

      <Divider />

      <Box p={{ base: 4, md: 6 }}>
        <VStack w="full" pt={8} spacing={6}>
          <Text textAlign="center" maxW="800px">
            Create referral codes and invite your friends! You earn 10% of fees.
            Each token market has fees determined by the creator.
          </Text>

          <Box overflowX="auto" w="full">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th pl={0}>Wallet</Th>
                  <Th>Referral Code</Th>
                  <Th isNumeric>Claimable Fees</Th>
                  <Th pr={0} isNumeric>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {referralData.map((row, index) => (
                  <ReferralTableRow key={index} {...row} />
                ))}
              </Tbody>
            </Table>
          </Box>
        </VStack>
      </Box>
    </Box>
  )
}

export default Referrals
