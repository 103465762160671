import { t } from '@lingui/macro'
import { TokenMillStakingAbi } from 'constants/abi/tokenMillStaking'
import { TM_STAKING } from 'constants/addresses'
import { UseUnstakeTokensProps } from 'hooks/tokenmill/useUnstakeTokens'
import useTransactionToast from 'hooks/useTransactionToast'
import { useDebounce } from 'use-debounce'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress, isAddress } from 'viem'
import {
  useAccount,
  usePublicClient,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

const useUnstakeTokens = ({
  amount,
  baseTokenAddress,
  baseTokenSymbol,
  chain,
  onUnstakeSuccess
}: UseUnstakeTokensProps) => {
  const chainId = getChainId(chain)
  const client = usePublicClient({ chainId })
  const { address: account, chain: walletChain } = useAccount()
  const addTransactionToast = useTransactionToast()

  const [debouncedAmount] = useDebounce(amount, 250)

  const { data: config } = useSimulateContract({
    abi: TokenMillStakingAbi,
    address: TM_STAKING,
    args:
      debouncedAmount && account && isAddress(baseTokenAddress)
        ? [getAddress(baseTokenAddress), account, debouncedAmount]
        : undefined,
    chainId,
    functionName: 'withdraw',
    query: {
      enabled: walletChain?.id === chainId && !!account && !!debouncedAmount,
      gcTime: 0
    }
  })

  const publicClient = usePublicClient({ chainId })
  const { isPending, reset, writeContractAsync } = useWriteContract({
    mutation: {
      onSuccess: async (hash) => {
        if (!publicClient) return

        if (amount) {
          const transactionSummary = t`Unstaked ${formattedNum(
            formatUnits(amount, 18)
          )} ${baseTokenSymbol}`

          addTransactionToast({
            chain,
            description: transactionSummary,
            hash,
            walletAddress: account || ''
          })
        }

        const receipt = await publicClient.waitForTransactionReceipt({ hash })
        if (receipt.status === 'success') {
          onUnstakeSuccess?.()
        }
      }
    }
  })

  const unstakeAsync =
    config?.request && client
      ? async () => await writeContractAsync(config.request)
      : undefined

  return {
    isUnstaking: isPending,
    resetUnstake: reset,
    unstakeAsync
  }
}

export default useUnstakeTokens
