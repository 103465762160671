import { useInfiniteQuery } from '@tanstack/react-query'
import { IS_EVM_ENABLED } from 'constants/flag'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, MarketData, TMMarketQueryParam } from 'types/dexbarn'

export interface UseGetMarketsParams {
  chain: Chain
  excludeLowVolumeMarkets?: boolean
  filterBy?: TMMarketQueryParam.FilterBy
  isDesc?: boolean
  orderBy?: TMMarketQueryParam.OrderBy
  pageSize?: number
}

const useGetMarketsFromBarn = ({
  chain,
  excludeLowVolumeMarkets,
  filterBy,
  isDesc,
  orderBy = 'new',
  pageSize = 20
}: UseGetMarketsParams) => {
  const fetchMarkets = useDexbarnGet<MarketData[]>(`/v1/markets/${chain}`)

  return useInfiniteQuery<MarketData[]>({
    enabled: IS_EVM_ENABLED,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      fetchMarkets({
        params: {
          excludeLowVolumeMarkets,
          filterBy,
          isDesc,
          orderBy,
          pageNum: pageParam,
          pageSize
        }
      }),
    queryKey: [
      'GetMarkets',
      pageSize,
      orderBy,
      filterBy,
      excludeLowVolumeMarkets,
      isDesc,
      chain
    ]
  })
}

export default useGetMarketsFromBarn
