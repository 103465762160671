import { CNATIVE } from 'constants/token'
import useGetMarketFromSolbarn from 'hooks/barn/solana/useGetMarketFromSolbarn'
import { UseGetMarketProps } from 'hooks/tokenmill/useGetMarket'
import { useMemo } from 'react'
import { getCurrentPrices, PricePoint } from 'utils/bondingCurves'
import { getChainId, getChainType } from 'utils/chains'
import { getResizedImageUrl } from 'utils/image'
import { convertToSolbarnFilterBy } from 'utils/solbarn'
import { formatUnits } from 'viem'

import useGetMarketStakingData from './useGetMarketStakingData'
import useMarketData from './useMarketData'

const useGetMarketSolana = ({
  chain,
  filterBy,
  marketAddress
}: UseGetMarketProps) => {
  const chainId = getChainId(chain)
  const chainType = getChainType(chain)

  const { data: dexbarnMarket, isLoading: isLoadingDexbarnMarket } =
    useGetMarketFromSolbarn({
      enabled: chainType === 'solana',
      filterBy: convertToSolbarnFilterBy(filterBy),
      marketAddress
    })

  const {
    data: marketData,
    isLoading: isLoadingMarketData,
    refetch: refetchMarketData
  } = useMarketData(marketAddress)

  const { data: marketStakingData } = useGetMarketStakingData({
    baseTokenAddress: marketData?.baseTokenMint.toBase58(),
    chain,
    enabled: !!marketData,
    marketAddress
  })

  const market = useMemo(() => {
    if (!dexbarnMarket || !marketData) return undefined

    const isMarketAvailableOnBarn =
      dexbarnMarket.base_token.address !== 'dummy-base-token'
    const baseTokenDecimals = isMarketAvailableOnBarn
      ? dexbarnMarket.base_token.decimals
      : 6

    const circulatingSupply = dexbarnMarket.circulating_supply
    const circulatingSupplyUsd = dexbarnMarket?.base_market_cap_usd

    const totalSupply = Number(
      formatUnits(marketData.totalSupply, baseTokenDecimals)
    )

    const numPoints = marketData.askPrices.length
    const askPricesPoints: PricePoint[] = marketData.askPrices.map(
      (price, i) => ({
        price: Number(formatUnits(price, 10)),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )
    const bidPricesPoints: PricePoint[] = marketData.bidPrices.map(
      (price, i) => ({
        price: Number(formatUnits(price, 10)),
        supply: (totalSupply / (numPoints - 1)) * i
      })
    )

    const { askPrice, bidPrice } = getCurrentPrices(
      askPricesPoints,
      bidPricesPoints,
      circulatingSupply +
        Number(marketStakingData?.totalLockedAmount?.formatted || '0')
    )

    return {
      askPrice,
      askPricesPoints,
      baseToken: {
        address: marketData.baseTokenMint.toBase58(),
        decimals: baseTokenDecimals,
        name: isMarketAvailableOnBarn
          ? dexbarnMarket.base_token.name
          : marketData.baseTokenMetadata?.name || '',
        symbol: isMarketAvailableOnBarn
          ? dexbarnMarket.base_token.symbol
          : marketData.baseTokenMetadata?.symbol || ''
      },
      baseTokenLogoUrl: getResizedImageUrl(dexbarnMarket.icon_url) || '',
      baseTokenPctChange1d: dexbarnMarket.base_token_pct_change_1d,
      baseTokenPctChange1h: dexbarnMarket.base_token_pct_change_1h,
      baseTokenPctChange5m: dexbarnMarket.base_token_pct_change_5m,
      baseTokenPctChange6h: dexbarnMarket.base_token_pct_change_6h,
      baseTokenPriceNative: dexbarnMarket.base_token.price_native,
      baseTokenPriceUsd: dexbarnMarket.base_token.price_usd,
      bidPrice,
      bidPricesPoints,
      chain,
      circulatingSupply,
      createdAt: dexbarnMarket.market_created_at,
      creator: marketData.creator.toBase58(),
      description: dexbarnMarket.description,
      discordUrl: dexbarnMarket.discord_url,
      fees: marketData.fees,
      liquidityUsd: dexbarnMarket.quote_liquidity_usd,
      marketAddress,
      marketCapUsd: circulatingSupplyUsd,
      nbBuyers: dexbarnMarket.number_buyers,
      nbBuys: dexbarnMarket.number_bids,
      nbSellers: dexbarnMarket.number_sellers,
      nbSells: dexbarnMarket.number_asks,
      nbSwaps: dexbarnMarket.number_swaps,
      quoteCurrency: {
        address: marketData.quoteTokenMint.toBase58(),
        decimals: marketData.quoteTokenDecimals,
        name: CNATIVE[chainId].name,
        symbol: CNATIVE[chainId].symbol
      },
      stakingFeesUsd:
        (dexbarnMarket.staking_share / 10000) * dexbarnMarket.swap_fees_usd,
      swapFeesUsd: dexbarnMarket.swap_fees_usd,
      telegramUrl: dexbarnMarket.telegram_url,
      totalSupply,
      twitterUrl: dexbarnMarket.twitter_url,
      volumeUsd: dexbarnMarket.volume_usd,
      websiteUrl: dexbarnMarket.website_url
    }
  }, [
    chainId,
    chain,
    marketAddress,
    dexbarnMarket,
    marketData,
    marketStakingData
  ])

  return {
    data: market,
    isLoading: isLoadingDexbarnMarket || isLoadingMarketData,
    refetch: () => {
      refetchMarketData()
    }
  }
}

export default useGetMarketSolana
