import { PortfolioToken } from 'types/portfolio'

// TODO (EVM): implement useUserOwnedTokensEVM
const useUserOwnedTokensEVM = () => {
  const data: PortfolioToken[] = []

  return {
    data,
    isLoading: false
  }
}

export default useUserOwnedTokensEVM
