import useTokenBalanceEVM from 'hooks/evm/useTokenBalance'
import useSolBalance from 'solana/hooks/useSolBalance'
import useTokenBalanceSolana from 'solana/hooks/useTokenBalance'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseTokenBalanceProps {
  chain: Chain
  enabled?: boolean
  token?: string | 'native'
}

const useTokenBalance = (props: UseTokenBalanceProps) => {
  const { chain, token } = props
  const chainType = getChainType(chain)

  const resultEVM = useTokenBalanceEVM({
    ...props,
    enabled: chainType === 'evm'
  })
  const resultSolana = useTokenBalanceSolana({
    ...props,
    enabled: chainType === 'solana' && token !== 'native'
  })
  const resultSolanaNative = useSolBalance({
    enabled: chainType === 'solana' && token === 'native'
  })

  switch (chainType) {
    case 'evm':
      return resultEVM
    case 'solana':
      return token === 'native' ? resultSolanaNative : resultSolana
  }
}

export default useTokenBalance
