import { PublicKey } from '@solana/web3.js'

export function shortenAddress(
  address?: string,
  chars = 4
): string | undefined {
  if (!address) return undefined
  return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`
}

export function isSolanaAddress(address: string): boolean {
  try {
    new PublicKey(address)
    return true
  } catch (error) {
    return false
  }
}
