import { InputRightElement, Kbd, useOutsideClick } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import SearchPanel from 'components/SearchPanel'
import useSearchMarkets from 'hooks/barn/useSearchMarkets'
import React, { useState } from 'react'

import SearchResults from './SearchResults'
import useSearchPanelKeyboardNavigation from './useSearchPanelKeyboardNavigation'

const HeaderSearchPanel = () => {
  const [query, setQuery] = useState('')
  const [focused, setFocused] = useState(false)

  const { data: markets = [], isLoading: isLoadingMarkets } = useSearchMarkets({
    query
  })

  const {
    activeIndex: activeResultIndex,
    closePanel,
    inputRef,
    resultsContainerRef,
    setActiveIndex
  } = useSearchPanelKeyboardNavigation({
    focused,
    markets,
    setFocused
  })

  const ref = React.useRef<HTMLDivElement | null>(null)
  useOutsideClick({ handler: closePanel, ref: ref })

  return (
    <SearchPanel
      resultsContainerRef={resultsContainerRef}
      searchPanelRef={ref}
      inputRef={inputRef}
      inputRightElement={
        !focused ? (
          <InputRightElement h="full">
            <Kbd>/</Kbd>
          </InputRightElement>
        ) : undefined
      }
      placeholder={t`Search by name, symbol or address`}
      query={query}
      setQuery={setQuery}
      queryMinLength={2}
      focused={focused}
      setFocused={setFocused}
      searchResults={
        <SearchResults
          onMouseEnter={(index) => {
            setActiveIndex(index)
          }}
          activeResultIndex={activeResultIndex}
          markets={markets}
          isLoadingMarkets={isLoadingMarkets}
          onRowClick={() => {
            setQuery('')
            setFocused(false)
          }}
        />
      }
      panelMaxWidth="700px"
    />
  )
}

export default HeaderSearchPanel
