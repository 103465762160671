import { Box, Button, ButtonProps } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { formattedNum } from 'utils/format'

interface InputBalanceButtonProps {
  balance: string
  title?: string
}

const InputBalanceButton = ({
  balance,
  title,
  ...props
}: InputBalanceButtonProps & ButtonProps) => (
  <Button data-cy="input-balance-button" variant="ghost" size="xs" {...props}>
    <Box as="span" color="textSecondary" pr={0.5}>
      {title ? title : <Trans>Balance:</Trans>}{' '}
    </Box>
    {formattedNum(balance)}
  </Button>
)

export default InputBalanceButton
