import useClaimReferrerFeesEVM from 'hooks/evm/useClaimReferrerFees'
import useClaimReferrerFeesSolana from 'solana/hooks/useClaimReferrerFees'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseClaimReferrerFeesProps {
  chain: Chain
  quoteTokenAddress: string
  onClaimSuccess?: () => void
}

const useClaimReferrerFees = (props: UseClaimReferrerFeesProps) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const claimEVM = useClaimReferrerFeesEVM(props)
  const claimSolana = useClaimReferrerFeesSolana(props)

  switch (chainType) {
    case 'evm':
      return claimEVM
    case 'solana':
      return claimSolana
  }
}

export default useClaimReferrerFees
