import { t } from '@lingui/macro'
import { TokenMillStakingAbi } from 'constants/abi/tokenMillStaking'
import { TM_STAKING } from 'constants/addresses'
import { UseReleaseTokensProps } from 'hooks/tokenmill/useReleaseTokens'
import useTransactionToast from 'hooks/useTransactionToast'
import { getChainId } from 'utils/chains'
import { getAddress, isAddress } from 'viem'
import {
  useAccount,
  usePublicClient,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

const useReleaseTokensEVM = ({
  baseTokenAddress,
  chain,
  enabled,
  onReleaseSuccess,
  vestingPlanIndex
}: UseReleaseTokensProps) => {
  const chainId = getChainId(chain)
  const publicClient = usePublicClient({ chainId })
  const { address: account, chain: walletChain } = useAccount()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: TokenMillStakingAbi,
    address: TM_STAKING,
    args: isAddress(baseTokenAddress)
      ? [getAddress(baseTokenAddress), BigInt(vestingPlanIndex)]
      : undefined,
    chainId,
    functionName: 'unlock',
    query: {
      enabled: enabled && walletChain?.id === chainId,
      gcTime: 0
    }
  })

  const { isPending, writeContractAsync } = useWriteContract({
    mutation: {
      onSuccess: async (hash) => {
        if (!publicClient) return
        const transactionSummary = t`Unlocked tokens`
        addTransactionToast({
          chain,
          description: transactionSummary,
          hash,
          walletAddress: account || ''
        })
        const receipt = await publicClient.waitForTransactionReceipt({ hash })
        if (receipt.status === 'success') {
          onReleaseSuccess?.()
        }
      }
    }
  })

  const releaseTokensAsync = config?.request
    ? async () => await writeContractAsync(config.request)
    : undefined

  return {
    isReleasing: isPending,
    releaseTokensAsync
  }
}

export default useReleaseTokensEVM
