import { Divider, VStack } from '@chakra-ui/react'
import React from 'react'
import { WalletMenuEnum } from 'types/walletMenu'

import ConnectedAccount from './ConnectedAccount'
import WalletMenuLink from './WalletMenuLink'
import WalletMenuRow from './WalletMenuRow'

interface ConnectedScreenProps {
  isOpen: boolean
  onClose: () => void
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
}

const ConnectedScreen = ({ setWalletMenuDisplay }: ConnectedScreenProps) => {
  return (
    <VStack spacing={4}>
      <ConnectedAccount />

      <Divider borderColor="textPrimary" opacity={0.1} />

      <VStack w="full">
        <WalletMenuLink title="Portfolio" href="/portfolio" />
        <WalletMenuLink title="Referrals" href="/referrals" />
        <WalletMenuRow
          title="Activity"
          menuDisplay={WalletMenuEnum.Activity}
          setWalletMenuDisplay={setWalletMenuDisplay}
        />
        <WalletMenuRow
          title="Settings"
          menuDisplay={WalletMenuEnum.Settings}
          setWalletMenuDisplay={setWalletMenuDisplay}
        />
      </VStack>
    </VStack>
  )
}

export default ConnectedScreen
