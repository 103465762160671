import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

const WalletMenuItem = ({
  children,
  ...props
}: { children: React.ReactNode } & ButtonProps) => {
  return (
    <Button
      alignItems="center"
      justifyContent="flex-start"
      w="full"
      variant="ghost"
      _hover={{ bg: 'hover' }}
      cursor="pointer"
      borderRadius={0}
      px={3}
      {...props}
    >
      {children}
    </Button>
  )
}

export default WalletMenuItem
