import { ChevronRightIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { WalletMenuEnum } from 'types/walletMenu'

import WalletMenuItem from './WalletMenuItem'

interface Props {
  menuDisplay: WalletMenuEnum
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
  title: string
}

const WalletMenuRow = ({ menuDisplay, setWalletMenuDisplay, title }: Props) => {
  return (
    <Flex w="full" justifyContent="flex-start" flexDir="column" rowGap={2}>
      <WalletMenuItem onClick={() => setWalletMenuDisplay(menuDisplay)}>
        <Text fontWeight="semibold">{title}</Text>
        <ChevronRightIcon textColor="textSecondary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
    </Flex>
  )
}

export default WalletMenuRow
