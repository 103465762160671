import { configureStore } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'

import authentication from './authentication/reducer'
import referral from './referral'
import settings from './settings/reducer'
import transactions from './transactions/reducer'

const PERSISTED_KEYS: string[] = [
  'settings',
  'authentication',
  'transactions',
  'referral'
]

const store = configureStore({
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    save({ states: PERSISTED_KEYS })
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
  reducer: {
    authentication,
    referral,
    settings,
    transactions
  }
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
