import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from '@chakra-ui/react'
import ActivityList from 'components/WalletMenu/ActivityScreen/ActivityList'
import ConnnectedAccount from 'components/WalletMenu/ConnectedScreen/ConnectedAccount'
import SettingsScreen from 'components/WalletMenu/SettingScreen'
import React from 'react'

const MobileAccountMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Button w="full" bg="transparent" onClick={onOpen}>
        <Flex alignItems="center" pos="relative">
          [Wallet]
          <Box
            top={-1}
            boxSize={2}
            right={-3}
            position="absolute"
            borderRadius="full"
            bgColor="candleGreen"
          />
        </Flex>
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="bgPrimary">
          <DrawerCloseButton borderRadius={0} />
          <DrawerBody>
            <>
              <ConnnectedAccount />

              <Divider my={4} />

              <Box>
                <Tabs isLazy variant="bracketed">
                  <TabList>
                    <Tab>Activity</Tab>
                    <Tab>Settings</Tab>
                  </TabList>
                  <TabPanels px={0} pb={0}>
                    <TabPanel px={0}>
                      <ActivityList />
                    </TabPanel>
                    <TabPanel px={0}>
                      <SettingsScreen hideHeader />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileAccountMenu
