import {
  Box,
  BoxProps,
  Button,
  HStack,
  Text,
  useColorMode,
  VStack
} from '@chakra-ui/react'
import { CNATIVE } from 'constants/token'
import {
  createChart,
  CrosshairMode,
  IChartApi,
  LineData,
  LineStyle,
  MouseEventParams,
  SeriesMarker,
  UTCTimestamp
} from 'lightweight-charts'
import React, { useEffect, useRef, useState } from 'react'
import { RefreshIcon } from 'theme/icons'
import { Chain } from 'types/dexbarn'
import {
  calculateQuoteAmountToBuySupply,
  fillDataWithWhitespace,
  PricePoint
} from 'utils/bondingCurves'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'

interface BondingCurvesChartProps {
  askPricePoints: PricePoint[]
  bidPricePoints: PricePoint[]
  chain: Chain
  askPrice?: number
  bidPrice?: number
  circulatingSupply?: number
  containerProps?: BoxProps
}

const BondingCurvesChart = ({
  askPrice,
  askPricePoints,
  bidPrice,
  bidPricePoints,
  chain,
  circulatingSupply,
  containerProps
}: BondingCurvesChartProps) => {
  const chainId = getChainId(chain)
  const nativeCurrency = CNATIVE[chainId]

  const { colorMode } = useColorMode()
  const borderColor = colorMode === 'light' ? '#ececfe' : '#2f3146'
  const textSecondaryColor = colorMode === 'light' ? '#8c8cb1' : '#9295bf'

  const chartContainerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<IChartApi | null>(null)

  const [hoveredAskPrice, setHoveredAskPrice] = useState<number | undefined>(
    undefined
  )
  const [hoveredBidPrice, setHoveredBidPrice] = useState<number | undefined>(
    undefined
  )
  const [hoveredSupply, setHoveredSupply] = useState<number | undefined>(
    circulatingSupply
  )
  const [hoveredQuoteTokenAmount, setHoveredQuoteTokenAmount] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    if (!chartContainerRef.current) return

    const chart = createChart(chartContainerRef.current, {
      crosshair: {
        horzLine: {
          labelVisible: false
        },
        mode: CrosshairMode.Magnet,
        vertLine: {
          labelVisible: false
        }
      },
      grid: {
        horzLines: {
          color: borderColor,
          style: LineStyle.Dashed
        },
        vertLines: {
          color: borderColor,
          style: LineStyle.Dashed
        }
      },
      layout: {
        background: {
          color: 'transparent'
        },
        textColor: textSecondaryColor
      },
      leftPriceScale: {
        visible: false
      },
      rightPriceScale: {
        borderColor: borderColor,
        minimumWidth: 20,
        scaleMargins: {
          bottom: 0,
          top: 0.02
        },
        visible: true
      },
      timeScale: {
        borderColor,
        fixLeftEdge: false,
        fixRightEdge: false,
        tickMarkFormatter: (time: number) => {
          return formattedNum(time, { places: 2 })
        },
        tickMarkMaxCharacterLength: 14
      },
      width: chartContainerRef.current.clientWidth
    })

    chartRef.current = chart

    const askLineSeries = chart.addLineSeries({
      color: 'rgba(253, 73, 60, 0.3)',
      lastValueVisible: false,
      lineWidth: 2,
      priceFormat: {
        formatter: (price: number) => {
          return formattedNum(price, {
            allowSmallDecimals: true
          })
        },
        minMove: 0.00001,
        type: 'custom'
      },
      priceLineVisible: false
    })

    const bidLineSeries = chart.addLineSeries({
      color: 'rgba(0, 200, 83, 0.3)',
      lastValueVisible: false,
      lineWidth: 2,
      priceFormat: {
        formatter: (price: number) => {
          return formattedNum(price, {
            allowSmallDecimals: true
          })
        },
        minMove: 0.00001,
        type: 'custom'
      },
      priceLineVisible: false
    })

    const askAreaSeries = chart.addAreaSeries({
      bottomColor: 'rgba(253, 73, 60, 0.1)',
      lastValueVisible: true,
      lineColor: 'rgba(253, 73, 60, 1)',
      lineWidth: 2,
      priceLineVisible: true,
      topColor: 'rgba(253, 73, 60, 0.4)'
    })

    const bidAreaSeries = chart.addAreaSeries({
      bottomColor: 'rgba(0, 200, 83, 0.1)',
      lastValueVisible: true,
      lineColor: 'rgba(0, 200, 83, 1)',
      lineWidth: 2,
      priceLineVisible: true,
      topColor: 'rgba(0, 200, 83, 0.4)'
    })

    const MAX_POINTS = 1000
    const filledAskData = circulatingSupply
      ? fillDataWithWhitespace(
          askPricePoints,
          askPrice,
          circulatingSupply,
          MAX_POINTS
        )
      : askPricePoints
    const filledBidData = circulatingSupply
      ? fillDataWithWhitespace(
          bidPricePoints,
          bidPrice,
          circulatingSupply,
          MAX_POINTS
        )
      : bidPricePoints

    const askCustomData = filledAskData.map((point) => ({
      time: point.supply as unknown as UTCTimestamp,
      value: point.price
    })) as LineData[]

    const bidCustomData = filledBidData.map((point) => ({
      time: point.supply as unknown as UTCTimestamp,
      value: point.price
    })) as LineData[]

    const askAreaData = circulatingSupply
      ? askCustomData.filter(
          (point) => (point.time as number) <= circulatingSupply
        )
      : []

    const bidAreaData = circulatingSupply
      ? bidCustomData.filter(
          (point) => (point.time as number) <= circulatingSupply
        )
      : []

    askLineSeries.setData(askCustomData)
    bidLineSeries.setData(bidCustomData)
    askAreaSeries.setData(askAreaData)
    bidAreaSeries.setData(bidAreaData)

    // Add markers for original price points
    const askMarkers: SeriesMarker<UTCTimestamp>[] = askPricePoints.map(
      (point) => ({
        color: 'rgba(253, 73, 60, 1)',
        position: 'inBar',
        shape: 'circle',
        size: 0.1,
        time: point.supply as unknown as UTCTimestamp
      })
    )

    const bidMarkers: SeriesMarker<UTCTimestamp>[] = bidPricePoints.map(
      (point) => ({
        color: 'rgba(0, 200, 83, 1)',
        position: 'inBar',
        shape: 'circle',
        size: 0.1,
        time: point.supply as unknown as UTCTimestamp
      })
    )

    askLineSeries.setMarkers(askMarkers)
    bidLineSeries.setMarkers(bidMarkers)

    chart.subscribeCrosshairMove((param: MouseEventParams) => {
      if (param.time === undefined || param.point === undefined) {
        setHoveredAskPrice(undefined)
        setHoveredBidPrice(undefined)
        setHoveredSupply(circulatingSupply)
        setHoveredQuoteTokenAmount(undefined)
        return
      }

      const supply = param.time as number
      const askDataPoint = filledAskData.find(
        (point) => point.supply === supply
      )
      const bidDataPoint = filledBidData.find(
        (point) => point.supply === supply
      )

      if (askDataPoint && bidDataPoint) {
        setHoveredAskPrice(askDataPoint.price)
        setHoveredBidPrice(bidDataPoint.price)
        setHoveredSupply(askDataPoint.supply)

        const quoteAmountToBuySupply = calculateQuoteAmountToBuySupply(
          askDataPoint.supply,
          askPricePoints
        )
        setHoveredQuoteTokenAmount(quoteAmountToBuySupply)
      }
    })

    if (askPricePoints.length > 0) {
      chart.timeScale().setVisibleRange({
        from: 0 as unknown as UTCTimestamp,
        to: Math.max(
          ...askPricePoints.map((point) => point.supply)
        ) as unknown as UTCTimestamp
      })
    }

    const handleResize = () => {
      if (chartContainerRef.current) {
        chart.resize(chartContainerRef.current.clientWidth, 400)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      chart.remove()
    }
  }, [
    askPricePoints,
    bidPricePoints,
    chain,
    circulatingSupply,
    borderColor,
    textSecondaryColor,
    askPrice,
    bidPrice
  ])

  const handleResetDomain = () => {
    if (chartRef.current) {
      chartRef.current.timeScale().setVisibleRange({
        from: 0 as unknown as UTCTimestamp,
        to: Math.max(
          ...askPricePoints.map((point) => point.supply)
        ) as unknown as UTCTimestamp
      })
    }
  }

  return (
    <Box
      ref={chartContainerRef}
      height="400px"
      pos="relative"
      w="full"
      {...containerProps}
    >
      <VStack
        pos="absolute"
        top={2}
        left={0}
        zIndex={10}
        align="flex-start"
        spacing={1}
        borderRadius="md"
      >
        <Text fontSize="sm">
          Supply:{' '}
          {hoveredSupply !== undefined
            ? formattedNum(hoveredSupply, { places: 2 })
            : '--'}
        </Text>

        <Text fontSize="sm">
          Cost:{' '}
          {hoveredQuoteTokenAmount !== undefined
            ? formattedNum(hoveredQuoteTokenAmount, { places: 2 })
            : '--'}{' '}
          {nativeCurrency.symbol}
        </Text>

        <HStack spacing={1}>
          <Box bg="rgba(253, 73, 60, 1)" boxSize={2} borderRadius="full" />
          <Text fontSize="sm">
            Ask price{' '}
            {hoveredAskPrice
              ? `${formattedNum(hoveredAskPrice, {
                  allowSmallDecimals: true
                })} ${nativeCurrency.symbol}`
              : ''}
          </Text>
        </HStack>

        <HStack spacing={1}>
          <Box bg="rgba(0, 200, 83, 1)" boxSize={2} borderRadius="full" />
          <Text fontSize="sm">
            Bid price{' '}
            {hoveredBidPrice
              ? `${formattedNum(hoveredBidPrice, {
                  allowSmallDecimals: true
                })} ${nativeCurrency.symbol}`
              : ''}
          </Text>
        </HStack>

        <Button
          ml={-3}
          aria-label="reset chart view"
          leftIcon={<RefreshIcon />}
          variant="ghost"
          borderRadius="full"
          size="sm"
          onClick={handleResetDomain}
        >
          Reset View
        </Button>
      </VStack>
    </Box>
  )
}

export default BondingCurvesChart
