import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MarketData } from 'types/dexbarn'

interface UseSearchPanelKeyboardNavigationProps {
  focused: boolean
  setFocused: React.Dispatch<React.SetStateAction<boolean>>
  markets?: MarketData[]
}

const useSearchPanelKeyboardNavigation = ({
  markets,
  setFocused
}: UseSearchPanelKeyboardNavigationProps) => {
  const navigate = useNavigate()

  const resultsContainerRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [activeIndex, setActiveIndex] = React.useState<number | undefined>()

  const closePanel = React.useCallback(() => {
    setFocused(false)
    setActiveIndex(undefined)
    inputRef.current?.blur()
  }, [setFocused])

  const scrollToItemIfNeeded = React.useCallback((index: number) => {
    const container = resultsContainerRef.current
    if (!container) return

    const selectedItem = document.getElementById(`search-result-${index}`)
    if (!selectedItem) return

    const containerTop = container.getBoundingClientRect().top
    const containerBottom = container.getBoundingClientRect().bottom
    const itemTop = selectedItem.getBoundingClientRect().top
    const itemBottom = selectedItem.getBoundingClientRect().bottom

    if (itemTop < containerTop) {
      container.scrollTop -= containerTop - itemTop
    } else if (itemBottom > containerBottom) {
      container.scrollTop += itemBottom - containerBottom
    }
  }, [])

  const downHandler = React.useCallback(
    (event: KeyboardEvent) => {
      // Check if the active element is an input or textarea
      const activeElement = document.activeElement
      const isInputFocused =
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement

      if (event.key === '/' && !isInputFocused) {
        event.preventDefault()
        inputRef.current?.focus()
        setFocused(true)
      } else if (event.key === 'Escape') {
        closePanel()
      } else if (event.key === 'ArrowDown') {
        const length = markets?.length ?? 0
        if (length === 0) return
        const newIndex =
          activeIndex === undefined ? 0 : (activeIndex + 1) % length
        setActiveIndex(newIndex)
        scrollToItemIfNeeded(newIndex)
      } else if (event.key === 'ArrowUp') {
        const length = markets?.length ?? 0
        if (length === 0) return
        const newIndex =
          activeIndex === undefined || activeIndex === 0
            ? length - 1
            : activeIndex - 1
        setActiveIndex(newIndex)
        scrollToItemIfNeeded(newIndex)
      } else if (event.key === 'Enter') {
        if (
          activeIndex !== undefined &&
          markets &&
          activeIndex < markets.length
        ) {
          closePanel()

          const market = markets[activeIndex]
          navigate(`/${market.chain}/${market.marketAddress}`)
        }
      }
    },
    [
      markets,
      navigate,
      activeIndex,
      closePanel,
      setFocused,
      scrollToItemIfNeeded
    ]
  )

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [downHandler])

  return {
    activeIndex,
    closePanel,
    inputRef,
    resultsContainerRef,
    setActiveIndex
  }
}

export default useSearchPanelKeyboardNavigation
