import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Skeleton,
  SkeletonText,
  Text,
  VStack
} from '@chakra-ui/react'
import ExpandableText from 'components/ExpandableText'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import React from 'react'

interface TokenInfoSectionProps {
  market: ReturnType<typeof useGetMarket>['data']
}

const TokenInfoSection = ({ market }: TokenInfoSectionProps) => {
  const token = market?.baseToken
  const twitterUrl = market?.twitterUrl
  const discordUrl = market?.discordUrl
  const telegramUrl = market?.telegramUrl
  const websiteUrl = market?.websiteUrl

  const hasSocialUrls =
    !!twitterUrl || !!discordUrl || !!telegramUrl || !!websiteUrl

  return (
    <Box w="full" p={{ base: 0, md: 6 }}>
      <HStack align="flex-start">
        <Skeleton
          h="80px"
          w={!!market ? 'auto' : '80px'}
          isLoaded={!!market}
          flexShrink={0}
        >
          <Image src={market?.baseTokenLogoUrl} h="80px" objectFit="contain" />
        </Skeleton>

        <VStack align="flex-start">
          <VStack spacing={0} align="flex-start">
            <Skeleton isLoaded={!!market}>
              <HStack flexWrap="wrap" spacing={0} columnGap={2}>
                <Text fontWeight="bold">{token?.symbol || 'symbol'}</Text>
                <Text textColor="textSecondary">{token?.name || 'name'}</Text>
              </HStack>
            </Skeleton>

            {hasSocialUrls && (
              <Flex
                columnGap={2}
                fontSize="sm"
                textColor="textSecondary"
                flexWrap="wrap"
              >
                {twitterUrl && (
                  <Link href={twitterUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Twitter]
                    </Button>
                  </Link>
                )}

                {discordUrl && (
                  <Link href={discordUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Discord]
                    </Button>
                  </Link>
                )}

                {telegramUrl && (
                  <Link href={telegramUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Telegram]
                    </Button>
                  </Link>
                )}

                {websiteUrl && (
                  <Link href={websiteUrl} isExternal>
                    <Button variant="ghost" size="xs" px={0}>
                      [Website]
                    </Button>
                  </Link>
                )}
              </Flex>
            )}
          </VStack>

          <SkeletonText noOfLines={2} isLoaded={!!market}>
            <ExpandableText
              text={market?.description || '[no description]'}
              fontSize={{ base: 'xs', md: 'sm' }}
              numberOfCharactersWhenCollapsed={60}
            />
          </SkeletonText>
        </VStack>
      </HStack>
    </Box>
  )
}

export default TokenInfoSection
