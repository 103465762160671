import { Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { UseReleaseTokensProps } from 'hooks/tokenmill/useReleaseTokens'
import useTransactionToast from 'hooks/useTransactionToast'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import { buildTransactionWithPriorityFee } from 'utils/transaction'

const useReleaseTokensSolana = ({
  baseTokenAddress,
  marketAddress,
  vestingPlanId
}: UseReleaseTokensProps) => {
  const wallet = useWallet()
  const { connection } = useConnection()
  const addTransactionToast = useTransactionToast()
  const { maxTransactionFee } = useMaxTransactionFee()
  const { feeMode } = useFeeMode()

  const releaseTokens = async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')

    const program = new Program(TokenMillIdl as Idl, {
      connection
    })

    const market = new PublicKey(marketAddress)
    const baseTokenMint = new PublicKey(baseTokenAddress)
    const vestingPlan = new PublicKey(vestingPlanId)

    const marketBaseTokenAta = spl.getAssociatedTokenAddressSync(
      baseTokenMint,
      market,
      true,
      spl.TOKEN_2022_PROGRAM_ID
    )

    const userBaseTokenAta = spl.getAssociatedTokenAddressSync(
      baseTokenMint,
      wallet.publicKey,
      true,
      spl.TOKEN_2022_PROGRAM_ID
    )

    const staking = PublicKey.findProgramAddressSync(
      [Buffer.from('market_staking'), market.toBuffer()],
      program.programId
    )[0]

    const stakePosition = PublicKey.findProgramAddressSync(
      [
        Buffer.from('stake_position'),
        market.toBuffer(),
        wallet.publicKey.toBuffer()
      ],
      program.programId
    )[0]

    const instruction = await program.methods
      .release()
      .accountsPartial({
        baseTokenMint,
        baseTokenProgram: spl.TOKEN_2022_PROGRAM_ID,
        market,
        marketBaseTokenAta,
        stakePosition,
        staking,
        user: wallet.publicKey,
        userBaseTokenAta,
        vestingPlan
      })
      .instruction()

    const { latestBlockhash, transaction } =
      await buildTransactionWithPriorityFee(
        connection,
        [instruction],
        wallet.publicKey,
        maxTransactionFee,
        feeMode
      )

    const signature = await wallet.sendTransaction(transaction, connection)

    addTransactionToast({
      chain: 'solana',
      description: 'Unlocked tokens',
      hash: signature,
      walletAddress: wallet.publicKey.toBase58()
    })

    const result = await connection.confirmTransaction(
      {
        blockhash: latestBlockhash.blockhash,
        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
        signature
      },
      'processed'
    )

    if (result.value.err) {
      throw new Error(result.value.err.toString())
    }

    return signature
  }

  const mutation = useMutation({
    mutationFn: releaseTokens
  })

  return {
    isReleasing: mutation.isPending,
    releaseTokensAsync: mutation.mutateAsync
  }
}

export default useReleaseTokensSolana
