import { TokenMillFactoryAbi } from 'constants/abi/tokenMillFactory'
import { TM_FACTORY_ADDRESS } from 'constants/addresses'
import { UseGetPendingReferrerFeesProps } from 'hooks/referrals/useGetPendingReferrerFees'
import { getChainId } from 'utils/chains'
import { formatUnits, getAddress, isAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

const useGetPendingReferrerFeesEVM = ({
  chain,
  quoteTokenAddress
}: UseGetPendingReferrerFeesProps) => {
  const { address: account, chain: walletChain } = useAccount()
  const chainId = getChainId(chain)

  const {
    data: pendingReferrerFees,
    isLoading: isLoadingPendingReferrerFees,
    refetch: refetchPendingReferrerFees
  } = useReadContract({
    abi: TokenMillFactoryAbi,
    address: TM_FACTORY_ADDRESS,
    args:
      isAddress(quoteTokenAddress) && account
        ? [getAddress(quoteTokenAddress), account]
        : undefined,
    chainId,
    functionName: 'getReferrerFeesOf',
    query: {
      enabled:
        walletChain?.id === chainId &&
        isAddress(quoteTokenAddress) &&
        !!account,
      select: (data) => {
        return { formatted: formatUnits(data, 18), value: data }
      }
    }
  })

  return {
    isLoadingPendingReferrerFees,
    pendingReferrerFees,
    refetchPendingReferrerFees
  }
}

export default useGetPendingReferrerFeesEVM
