import { createAction } from '@reduxjs/toolkit'
import { Transaction } from 'types/transactions'

export const addTransaction = createAction<{
  transaction: Transaction
  walletAddress: string
}>('transactions/addTransaction')

export const updateTransaction = createAction<{
  transaction: Transaction
  walletAddress: string
}>('transactions/updateTransaction')

export const clearTransactions = createAction<string>(
  'transactions/clearTransactions'
)

export const clearAllTransactions = createAction(
  'transactions/clearAllTransactions'
)
