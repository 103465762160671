const fonts = [
  '"JetBrainsMono"',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  'Ubuntu',
  '"Helvetica Neue"',
  'Oxygen',
  'Cantarell',
  'sans-serif'
]

export const fontStyle = fonts.join(', ')
