import useClaimRewardsEVM from 'hooks/evm/useClaimStakingRewards'
import useClaimRewardsSolana from 'solana/hooks/useClaimStakingRewards'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseClaimStakingRewardsProps {
  baseTokenAddress: string
  chain: Chain
  marketAddress: string
  quoteTokenAddress: string
  onSuccess?: () => void
}

const useClaimStakingRewards = (props: UseClaimStakingRewardsProps) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const claimEVM = useClaimRewardsEVM(props)
  const claimSolana = useClaimRewardsSolana(props)

  switch (chainType) {
    case 'evm':
      return claimEVM
    case 'solana':
      return claimSolana
  }
}

export default useClaimStakingRewards
