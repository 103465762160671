import { Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'

const StakeFAQ = () => {
  return (
    <VStack align="flex-start" spacing={6} p={{ base: 4, md: 6 }} w="full">
      <Heading size="sm">STAKING FAQ</Heading>
      <VStack align="flex-start" spacing={1} fontSize="sm">
        <Text>• Stake your tokens and claim share of token trading fees</Text>
        <Text>• Fees are generated when tokens are bought from Token Mill</Text>
        <Text>
          • Rewards are distributed in real time and can be claimed at any time
        </Text>
        <Text>
          • Fees displayed are only historical guidance and not representative
          of future fees
        </Text>
        <Text>• You may unstake at any time</Text>
      </VStack>
    </VStack>
  )
}

export default StakeFAQ
