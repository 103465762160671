import { Idl, Program } from '@coral-xyz/anchor'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { UseGetStakePositionProps } from 'hooks/tokenmill/useGetStakePosition'
import { useCallback } from 'react'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { UserStakePosition } from 'types/market'
import { formatUnits } from 'viem'

const useGetStakePosition = ({
  enabled,
  marketAddress
}: UseGetStakePositionProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()

  const getStakePosition = useCallback(async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')

    const program = new Program(TokenMillIdl as Idl, { connection })
    const market = new PublicKey(marketAddress)

    const [stakePosition] = PublicKey.findProgramAddressSync(
      [
        Buffer.from('stake_position'),
        market.toBuffer(),
        wallet.publicKey.toBuffer()
      ],
      program.programId
    )

    try {
      const stakePositionAccount = await (
        program.account as any
      ).stakePosition.fetch(stakePosition)
      return stakePositionAccount
    } catch (error) {
      console.error('Error fetching stake position:', error)
      return null
    }
  }, [wallet.publicKey, marketAddress, connection])

  return useQuery({
    enabled: enabled && !!wallet.publicKey,
    queryFn: async (): Promise<UserStakePosition> => {
      const position = await getStakePosition()
      if (!position) {
        return {
          amountStaked: undefined,
          pendingRewards: undefined
        }
      }

      return {
        amountStaked: {
          formatted: formatUnits(BigInt(position.amountStaked), 6),
          value: BigInt(position.amountStaked)
        },
        pendingRewards: {
          formatted: formatUnits(BigInt(position.pendingRewards), 9),
          value: BigInt(position.pendingRewards)
        }
      }
    },
    queryKey: ['stakedBalance', wallet.publicKey?.toBase58(), marketAddress]
  })
}

export default useGetStakePosition
