import * as borsh from '@coral-xyz/borsh'
import { getTokenMetadata } from '@solana/spl-token'
import { TokenMetadata } from '@solana/spl-token-metadata'
import { useConnection } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'

const PRICES_LENGTH = 11

const marketFeesSchema = borsh.struct([
  borsh.u16('stakingFeeShare'),
  borsh.u16('creatorFeeShare'),
  borsh.u16('protocolFeeShare'),
  borsh.u16('_space'),
  borsh.u64('pendingStakingFees'),
  borsh.u64('pendingCreatorFees')
])

const marketSchema = borsh.struct([
  borsh.publicKey('config'),
  borsh.publicKey('creator'),
  borsh.publicKey('baseTokenMint'),
  borsh.publicKey('quoteTokenMint'),
  borsh.u64('baseReserve'),
  borsh.array(borsh.u64(), PRICES_LENGTH, 'bidPrices'),
  borsh.array(borsh.u64(), PRICES_LENGTH, 'askPrices'),
  borsh.u64('widthScaled'),
  borsh.u64('totalSupply'),
  marketFeesSchema.replicate('fees'),
  borsh.u8('quoteTokenDecimals'),
  borsh.array(borsh.u8(), 1, 'bump'),
  borsh.array(borsh.u8(), 6, '_space')
])

interface MarketFees {
  creatorFeeShare: number
  pendingCreatorFees: bigint
  pendingStakingFees: bigint
  protocolFeeShare: number
  stakingFeeShare: number
}

interface Market {
  askPrices: bigint[]
  baseReserve: bigint
  baseTokenMetadata: TokenMetadata | null
  baseTokenMint: PublicKey
  bidPrices: bigint[]
  bump: number[]
  config: PublicKey
  creator: PublicKey
  fees: MarketFees
  quoteTokenDecimals: number
  quoteTokenMint: PublicKey
  totalSupply: bigint
  widthScaled: bigint
}

function convertToMarket(rawMarket: any): Market {
  return {
    askPrices: rawMarket.askPrices.map((price: any) =>
      BigInt(price.toString())
    ),
    baseReserve: BigInt(rawMarket.baseReserve.toString()),
    baseTokenMetadata: null,
    baseTokenMint: rawMarket.baseTokenMint,
    bidPrices: rawMarket.bidPrices.map((price: any) =>
      BigInt(price.toString())
    ),
    bump: rawMarket.bump,
    config: rawMarket.config,
    creator: rawMarket.creator,
    fees: {
      creatorFeeShare: rawMarket.fees.creatorFeeShare,
      pendingCreatorFees: BigInt(rawMarket.fees.pendingCreatorFees.toString()),
      pendingStakingFees: BigInt(rawMarket.fees.pendingStakingFees.toString()),
      protocolFeeShare: rawMarket.fees.protocolFeeShare,
      stakingFeeShare: rawMarket.fees.stakingFeeShare
    },
    quoteTokenDecimals: rawMarket.quoteTokenDecimals,
    quoteTokenMint: rawMarket.quoteTokenMint,
    totalSupply: BigInt(rawMarket.totalSupply.toString()),
    widthScaled: BigInt(rawMarket.widthScaled.toString())
  }
}

const useMarketData = (marketAddress: string) => {
  const { connection } = useConnection()

  return useQuery<Market, Error>({
    queryFn: async () => {
      try {
        const accountInfo = await connection.getAccountInfo(
          new PublicKey(marketAddress)
        )

        if (!accountInfo) {
          throw new Error('Account not found')
        }

        const rawMarket = marketSchema.decode(
          Buffer.from(accountInfo.data.slice(8))
        )

        const market = convertToMarket(rawMarket)
        const baseTokenMetadata = await getTokenMetadata(
          connection,
          market.baseTokenMint
        )

        return { ...market, baseTokenMetadata }
      } catch (error) {
        throw error
      }
    },
    queryKey: ['marketData', marketAddress]
  })
}

export default useMarketData
