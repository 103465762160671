import 'react-datepicker/dist/react-datepicker.css'
import './chakra-react-datepicker.css'

import { CalendarIcon } from '@chakra-ui/icons'
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import ReactDatePicker, { DatePickerProps } from 'react-datepicker'

const customDateInput = (props: InputProps, ref: any) => (
  <Input autoComplete="off" background="white" ref={ref} {...props} />
)
customDateInput.displayName = 'DateInput'

const CustomInput = forwardRef(customDateInput)

const icon = <CalendarIcon fontSize="sm" />

const DatePicker2 = ({
  datePickerProps,
  inputProps
}: {
  datePickerProps: DatePickerProps
  inputProps: InputProps
}) => {
  return (
    <InputGroup>
      <ReactDatePicker
        customInput={<CustomInput {...inputProps} />}
        {...datePickerProps}
      />
      <InputRightElement color="textSecondary">{icon}</InputRightElement>
    </InputGroup>
  )
}

export default DatePicker2
