import useGetMarketStakingDataEVM from 'hooks/evm/useGetMarketStakingData'
import useGetMarketStakingDataSolana from 'solana/hooks/useGetMarketStakingData'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseGetMarketStakingDataProps {
  baseTokenAddress: string | undefined
  chain: Chain
  enabled: boolean
  marketAddress: string
}

const useGetMarketStakingData = (
  props: Omit<UseGetMarketStakingDataProps, 'enabled'>
) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const marketStakingDataEVM = useGetMarketStakingDataEVM({
    ...props,
    enabled: chainType === 'evm'
  })
  const marketStakingDataSolana = useGetMarketStakingDataSolana({
    ...props,
    enabled: chainType === 'solana'
  })

  switch (chainType) {
    case 'evm':
      return marketStakingDataEVM
    case 'solana':
      return marketStakingDataSolana
  }
}

export default useGetMarketStakingData
