import useGetStakePositionEVM from 'hooks/evm/useGetStakePosition'
import useGetStakePositionSolana from 'solana/hooks/useGetStakePosition'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseGetStakePositionProps {
  baseTokenAddress: string | undefined
  chain: Chain
  enabled: boolean
  marketAddress: string
}

const useGetStakePosition = (
  props: Omit<UseGetStakePositionProps, 'enabled'>
) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const stakePositionEVM = useGetStakePositionEVM({
    ...props,
    enabled: chainType === 'evm'
  })
  const stakePositionSolana = useGetStakePositionSolana({
    ...props,
    enabled: chainType === 'solana'
  })

  switch (chainType) {
    case 'evm':
      return stakePositionEVM
    case 'solana':
      return stakePositionSolana
  }
}

export default useGetStakePosition
