import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import {
  AuthTokens,
  Chain,
  PatchMarketArgs,
  PatchMarketResponse
} from 'types/dexbarn'
import { SolbarnMarketPatchResponse } from 'types/solbarn'

import useAccountOnChain from '../tokenmill/useAccountOnChain'

const usePatchMarket = ({ chain }: { chain: Chain }) => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()
  const account = useAccountOnChain(chain)
  const addErrorPopup = useAddErrorPopup()

  return useMutation<
    PatchMarketResponse,
    Error,
    {
      args: PatchMarketArgs
      authTokens: AuthTokens
      chain: Chain
      marketAddress: string
    }
  >({
    mutationFn: async ({ args, authTokens, chain, marketAddress }) => {
      if (!account.address) {
        throw new Error('Wallet not connected')
      }

      switch (chain) {
        case 'avalanche': {
          const response = await dexbarnClient.patch<PatchMarketResponse>(
            `/v1/markets/${chain}/${marketAddress}`,
            args,
            { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
          )
          return response.data
        }
        case 'solana': {
          const response =
            await solbarnClient.patch<SolbarnMarketPatchResponse>(
              `/markets/${marketAddress}`,
              {
                description: args.description,
                discord_url: args.discordUrl,
                icon_url: args.iconUrl,
                telegram_url: args.telegramUrl,
                twitter_url: args.twitterUrl,
                website_url: args.websiteUrl
              },
              {
                headers: { Authorization: `Bearer ${authTokens.accessToken}` }
              }
            )
          return {
            chain: 'solana',
            description: response.data.description,
            discordUrl: response.data.discord_url,
            iconUrl: response.data.icon_url,
            marketAddress,
            telegramUrl: response.data.telegram_url,
            twitterUrl: response.data.twitter_url,
            websiteUrl: response.data.website_url
          } satisfies PatchMarketResponse
        }
        default:
          throw new Error(`Unsupported chain: ${chain}`)
      }
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup({
          subtitle: error.response?.data.detail,
          summary: 'Unable to patch market'
        })
      } else if (error instanceof Error) {
        addErrorPopup({
          subtitle: error.message,
          summary: 'Unable to patch market'
        })
      }
      console.error(error)
    }
  })
}

export default usePatchMarket
