import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Hide,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Show,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import CopyableError from 'components/CopyableError'
import LoginModal from 'components/LoginModal'
import NumericalInput from 'components/NumericalInput'
import Popup from 'components/Popup'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import Warning from 'components/Warning'
import Web3Button from 'components/Web3Button'
import { IS_EVM_ENABLED } from 'constants/flag'
import { CNATIVE } from 'constants/token'
import usePatchMarket from 'hooks/barn/usePatchMarket'
import { useS3ImageUpload } from 'hooks/barn/useS3ImageUpload'
import useCreateMarketAndToken, {
  VestingArgs
} from 'hooks/tokenmill/useCreateMarketAndToken'
import { useCreateTokenLogoUploadUrl } from 'hooks/tokenmill/useCreateTokenLogoUploadUrl'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useCreateVestingPlan from 'solana/hooks/useCreateVestingPlan'
import { useGetAuthTokens, useIsLoggedIn } from 'state/authentication/hooks'
import { AuthTokens, Chain } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import {
  CurveType,
  generatePricePoints,
  getIsTotalSupplyValid,
  PricePoint
} from 'utils/bondingCurves'
import { getChainId } from 'utils/chains'
import { formatNumberNoScientific, formattedNum } from 'utils/format'
import { BaseError } from 'viem'

import BondingCurvesChart from './BondingCurvesChart'
import CreateTokenHeader from './CreateTokenHeader'
import CreateTokenSocialUrlInputs from './CreateTokenSocialUrlInputs'
import ImagePicker from './ImagePicker'
import InitialPurchaseInput from './InitialPurchaseInput'
import PricePointsInput from './PricePointsInput'
import TokenCreationStatusModal from './TokenCreationStatusModal'

type Mode = 'milltard' | 'millbrain'

export type CreateTokenStatus =
  | 'idle'
  | 'create_market'
  | 'create_vesting'
  | 'patch_market'
  | 'done'
  | 'error_create_market'
  | 'error_create_vesting'
  | 'error_patch_market'

const defaultMaxPrices: {
  [chain in Chain]: { [curveType in CurveType]: string }
} = {
  avalanche: {
    exponential: '1',
    logarithmic: '0.01',
    power: '1',
    quadratic: '0.01'
  },
  solana: {
    exponential: '0.15',
    logarithmic: '0.0015',
    power: '0.0015',
    quadratic: '0.0015'
  }
}

const defaultValues = {
  alpha: '3',
  creatorFeeShare: '20',
  fees: '1',
  minPrice: '0',
  nbIntervals: '10',
  normalizedC: '2',
  stakingFeeShare: '60',
  totalSupply: '100000000'
}

const curveTypeToIndex: Record<CurveType, number> = {
  exponential: 2,
  logarithmic: 3,
  power: 0,
  quadratic: 1
}

const CreateToken = () => {
  const toast = useToast()
  const addErrorPopup = useAddErrorPopup()

  // state
  const decimals = 18
  const [mode, setMode] = useState<Mode>('milltard')
  const [selectedChain, setSelectedChain] = useState<Chain>('solana')
  const [selectedCurveType, setSelectedCurveType] = useState<CurveType>('power')
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [name, setName] = useState<string>('')
  const [isNameValid, setIsNameValid] = useState<boolean>(true)
  const [symbol, setSymbol] = useState<string>('')
  const [isSymbolValid, setIsSymbolValid] = useState<boolean>(true)
  const [description, setDescription] = useState<string>('')
  const [totalSupply, setTotalSupply] = useState<string>(
    defaultValues.totalSupply
  )
  const [isTotalSupplyValid, setIsTotalSupplyValid] = useState<boolean>(true)
  const [totalSupplyError, setTotalSupplyError] = useState<string>('')
  const [normalizedC, setNormalizedC] = useState<string>(
    defaultValues.normalizedC
  )
  const [isNormalizedCValid, setIsNormalizedCValid] = useState<boolean>(true)
  const [alpha, setAlpha] = useState<string>(defaultValues.alpha)
  const [isAlphaValid, setIsAlphaValid] = useState<boolean>(true)
  const [minPrice, setMinPrice] = useState<string>(defaultValues.minPrice)
  const [isMinPriceValid, setIsMinPriceValid] = useState<boolean>(true)
  const [maxPrice, setMaxPrice] = useState<string>(
    defaultMaxPrices[selectedChain].quadratic
  )
  const [isMaxPriceValid, setIsMaxPriceValid] = useState<boolean>(true)
  const [nbIntervals, setNbIntervals] = useState<string>('10')
  const [isNbIntervalsValid, setIsNbIntervalsValid] = useState<boolean>(true)
  const [askPricePointsString, setAskPricePointsString] = useState<string>('')
  const [bidPricePointsString, setBidPricePointsString] = useState<string>('')
  const [askPricePointsInputError, setAskPricePointsInputError] =
    useState<string>('')
  const [bidPricePointsInputError, setBidPricePointsInputError] =
    useState<string>('')
  const [askPricePoints, setAskPricePoints] = useState<PricePoint[]>([])
  const [bidPricePoints, setBidPricePoints] = useState<PricePoint[]>([])
  const [fees, setFees] = useState<string>(defaultValues.fees)
  const [creatorFeeShare, setCreatorFeeShare] = useState<string>(
    defaultValues.creatorFeeShare
  )
  const [stakingFeeShare, setStakingFeeShare] = useState<string>(
    defaultValues.stakingFeeShare
  )
  const [isFeeShareValid, setIsFeeShareValid] = useState<boolean>(true)
  const [isFeeValid, setIsFeeValid] = useState<boolean>(true)
  const [socialUrls, setSocialUrls] = useState({
    discord: '',
    telegram: '',
    twitter: '',
    website: ''
  })
  const [uploadedIconUrl, setUploadedIconUrl] = useState<string | null>(null)
  const [vestingArgs, setVestingArgs] = useState<VestingArgs | undefined>()
  const [isVestingArgsValid, setIsVestingArgsValid] = useState<boolean>(true)
  const [creationStatus, setCreationStatus] =
    useState<CreateTokenStatus>('idle')
  const [hint, setHint] = useState<string>('')
  const [createdMarketAddress, setCreatedMarketAddress] = useState<string>('')
  const [createdBaseTokenAddress, setCreatedBaseTokenAddress] =
    useState<string>('')

  const chainId = getChainId(selectedChain)
  const nativeCurrency = CNATIVE[chainId]

  const fullyDilutedValuation = useMemo(() => {
    const totalSupplyNum = parseInt(totalSupply)
    const maxPriceNum = parseFloat(maxPrice)

    if (isNaN(totalSupplyNum) || isNaN(maxPriceNum)) {
      return 0
    }

    return totalSupplyNum * maxPriceNum
  }, [totalSupply, maxPrice])

  // form validation
  const validateForm = useCallback(() => {
    const minPriceNum = parseFloat(minPrice)
    const maxPriceNum = parseFloat(maxPrice)
    const normalizedCNum = parseFloat(normalizedC)
    const alphaNum = parseFloat(alpha)
    const totalSupplyNum = parseInt(totalSupply)
    const nbIntervalsNum = parseInt(nbIntervals)
    const feesNum = parseFloat(fees)
    const creatorFeeShareNum = parseFloat(creatorFeeShare)
    const stakingFeeShareNum = parseFloat(stakingFeeShare)

    // validate nbIntervals
    const isNbIntervalsValid = nbIntervalsNum >= 1 && nbIntervalsNum <= 20
    setIsNbIntervalsValid(isNbIntervalsValid)

    // validate min price
    const isMinPriceValid =
      minPriceNum >= 0 && minPriceNum < 1e9 && minPriceNum < maxPriceNum
    setIsMinPriceValid(isMinPriceValid)

    // validate max price
    const isMaxPriceValid =
      maxPriceNum > 0 && maxPriceNum <= 1e9 && maxPriceNum > minPriceNum
    setIsMaxPriceValid(isMaxPriceValid)

    // validate normalizedC
    const isNormalizedCValid = normalizedCNum >= 0 && normalizedCNum <= 10
    setIsNormalizedCValid(isNormalizedCValid)

    // validate alpha
    const isAlphaValid = alphaNum >= -5 && alphaNum <= 5
    setIsAlphaValid(isAlphaValid)

    // validate total supply
    const totalSupplyChecker = getIsTotalSupplyValid({
      decimals,
      nbIntervals: nbIntervalsNum,
      totalSupply: totalSupplyNum
    })
    const isTotalSupplyValid = totalSupplyChecker.isValid
    setIsTotalSupplyValid(isTotalSupplyValid)
    setTotalSupplyError(totalSupplyChecker.error || '')

    // Validate fees and fee shares
    const isFeeValid = feesNum >= 0 && feesNum <= 99.99
    setIsFeeValid(isFeeValid)
    const isFeeShareValid =
      creatorFeeShareNum + stakingFeeShareNum === 80 &&
      creatorFeeShareNum >= 0 &&
      creatorFeeShareNum <= 80 &&
      stakingFeeShareNum >= 0 &&
      stakingFeeShareNum <= 80
    setIsFeeShareValid(isFeeShareValid)

    // validate price points
    const pricePointsSameLength =
      askPricePoints.length > 0 &&
      bidPricePoints.length > 0 &&
      askPricePoints.length === bidPricePoints.length
    const bidPriceLowerThanAskPrice = pricePointsSameLength
      ? askPricePoints.every(
          (ask, index) => ask.price >= bidPricePoints[index].price
        )
      : false
    const arePricePointsValid =
      pricePointsSameLength && bidPriceLowerThanAskPrice
    if (!pricePointsSameLength) {
      setAskPricePointsInputError(
        'Ask and bid price points must have same length'
      )
      setBidPricePointsInputError(
        'Ask and bid price points must have same length'
      )
    } else if (!bidPriceLowerThanAskPrice) {
      setAskPricePointsInputError('')
      setBidPricePointsInputError('Bid price must be lower than ask price')
    } else {
      setAskPricePointsInputError('')
      setBidPricePointsInputError('')
    }

    return (
      isNbIntervalsValid &&
      isMinPriceValid &&
      isMaxPriceValid &&
      isNormalizedCValid &&
      isAlphaValid &&
      isTotalSupplyValid &&
      isFeeValid &&
      arePricePointsValid &&
      !!name &&
      isNameValid &&
      !!symbol &&
      isSymbolValid &&
      !!selectedImage &&
      isFeeShareValid
    )
  }, [
    minPrice,
    maxPrice,
    normalizedC,
    alpha,
    totalSupply,
    decimals,
    nbIntervals,
    name,
    isNameValid,
    symbol,
    isSymbolValid,
    fees,
    askPricePoints,
    bidPricePoints,
    selectedImage,
    creatorFeeShare,
    stakingFeeShare
  ])

  const isFormValid = useMemo(() => validateForm(), [validateForm])

  // update price points
  useEffect(() => {
    const minPriceNum = parseFloat(minPrice)
    const maxPriceNum = parseFloat(maxPrice)
    const nbIntervalsNum = parseInt(nbIntervals)
    const totalSupplyNum = parseInt(totalSupply)
    let alphaNum = parseFloat(alpha)
    let normalizedCNum = parseFloat(normalizedC)
    const feesNum = parseFloat(fees)

    if (selectedCurveType !== 'quadratic') {
      normalizedCNum = 0
    }

    if (selectedCurveType !== 'power') {
      alphaNum = 0
    }

    if (
      isNaN(minPriceNum) ||
      isNaN(maxPriceNum) ||
      isNaN(nbIntervalsNum) ||
      isNaN(totalSupplyNum) ||
      isNaN(normalizedCNum) ||
      isNaN(alphaNum) ||
      isNaN(feesNum) ||
      !isNbIntervalsValid ||
      !isMaxPriceValid ||
      !isMinPriceValid ||
      !isFeeValid ||
      !isTotalSupplyValid ||
      !isNormalizedCValid ||
      !isAlphaValid
    ) {
      return
    }

    try {
      const points = generatePricePoints({
        Pmax: maxPriceNum,
        Pmin: minPriceNum,
        alpha: alphaNum,
        curveType: selectedCurveType,
        normalizedC: normalizedCNum,
        numPoints: nbIntervalsNum + 1,
        xmax: totalSupplyNum
      })
      setAskPricePoints(points)
      const bidPoints = points.map((p) => ({
        ...p,
        price: parseFloat((p.price * (1 - feesNum / 100)).toPrecision(3))
      }))
      setBidPricePoints(bidPoints)
      setAskPricePointsString(
        points.map((p) => formatNumberNoScientific(p.price)).join(',')
      )
      setBidPricePointsString(
        bidPoints.map((p) => formatNumberNoScientific(p.price)).join(',')
      )
    } catch (err) {
      console.error(err)
      return
    }
  }, [
    minPrice,
    maxPrice,
    nbIntervals,
    totalSupply,
    selectedCurveType,
    alpha,
    normalizedC,
    isNbIntervalsValid,
    isMaxPriceValid,
    isMinPriceValid,
    fees,
    isFeeValid,
    isTotalSupplyValid,
    isNormalizedCValid,
    isAlphaValid,
    selectedChain
  ])

  // create market and token
  const {
    createMarketAndTokenAsync,
    error: createMarketSimulateError,
    isSimulating: isSimulatingCreateMarket
  } = useCreateMarketAndToken({
    askPrices: askPricePoints.map((point) => point.price),
    bidPrices: bidPricePoints.map((point) => point.price),
    chain: selectedChain,
    creatorFeeShareBps:
      creatorFeeShare && isFeeShareValid
        ? Math.round(parseFloat(creatorFeeShare) * 100)
        : undefined,
    decimals,
    enabled: isFormValid && isVestingArgsValid,
    name,
    stakingFeeShareBps:
      stakingFeeShare && isFeeShareValid
        ? Math.round(parseFloat(stakingFeeShare) * 100)
        : undefined,
    symbol,
    totalSupply:
      totalSupply && isTotalSupplyValid ? parseInt(totalSupply) : undefined,
    vestingArgs
  })

  // create vesting plan
  const { createVestingPlanAsync } = useCreateVestingPlan({
    chain: selectedChain,
    vestingArgs
  })

  // token logo upload
  const createTokenLogoUploadUrl = useCreateTokenLogoUploadUrl({
    chain: selectedChain
  })
  const uploadImage = useS3ImageUpload()

  // create token click handler
  const {
    isOpen: isLoginModalOpen,
    onClose: onLoginModalClose,
    onOpen: onLoginModalOpen
  } = useDisclosure()
  const { mutateAsync: patchMarket } = usePatchMarket({ chain: selectedChain })
  const isLoggedIn = useIsLoggedIn(selectedChain)
  const { tokens: authTokens } = useGetAuthTokens(selectedChain)
  const createToken = useCallback(
    async (authTokens: AuthTokens | undefined) => {
      if (!isFormValid || !isVestingArgsValid || !createMarketAndTokenAsync) {
        return
      }

      if (!isLoggedIn || !authTokens) {
        onLoginModalOpen()
        return
      }

      async function runPatchMatch(marketAddress: string, iconUrl: string) {
        if (!authTokens) {
          return
        }

        setCreationStatus('patch_market')
        await patchMarket({
          args: {
            description,
            discordUrl: socialUrls.discord,
            iconUrl: iconUrl,
            telegramUrl: socialUrls.telegram,
            twitterUrl: socialUrls.twitter,
            websiteUrl: socialUrls.website
          },
          authTokens,
          chain: selectedChain,
          marketAddress
        })
      }

      try {
        // If we're retrying after a vesting error, jump straight to that step
        if (creationStatus === 'error_create_vesting') {
          setCreationStatus('create_vesting')
          await createVestingPlanAsync([
            createdMarketAddress,
            createdBaseTokenAddress
          ])

          if (uploadedIconUrl) {
            await runPatchMatch(createdMarketAddress, uploadedIconUrl)
            setCreationStatus('done')
          }

          return
        }

        // If we're retrying after a market patch error, jump straight to that step
        if (creationStatus === 'error_patch_market' && uploadedIconUrl) {
          await runPatchMatch(createdMarketAddress, uploadedIconUrl)
          setCreationStatus('done')
          return
        }

        // Create market
        setCreationStatus('create_market')
        if (!selectedImage) throw new Error('No image selected')

        // upload logo
        const { destinationUrl, fields, presignedUploadUrl } =
          await createTokenLogoUploadUrl({
            authTokens,
            contentType: selectedImage.type
          })
        await uploadImage(presignedUploadUrl, selectedImage, fields)
        const iconUrl = destinationUrl
        setUploadedIconUrl(iconUrl)

        if (!iconUrl) {
          throw new Error('Unable to upload logo')
        }

        // Create market and token
        const { baseTokenAddress, hash, marketAddress } =
          await createMarketAndTokenAsync({
            logoURI: iconUrl
          })

        // Save market and base token addresses
        setCreatedMarketAddress(marketAddress)
        setCreatedBaseTokenAddress(baseTokenAddress)

        // Create vesting plan
        if (vestingArgs && selectedChain === 'solana') {
          setCreationStatus('create_vesting')
          await createVestingPlanAsync([marketAddress, baseTokenAddress])
        }

        // PATCH market
        await runPatchMatch(marketAddress, iconUrl)

        // Update toast
        toast.update(hash, {
          duration: null,
          render: (props) => (
            <Popup
              externalLink={{
                name: 'View Token',
                url: `/${selectedChain}/${marketAddress}`
              }}
              summary="Created token"
              {...props}
            />
          ),
          status: 'success'
        })

        setCreationStatus('done')
      } catch (err) {
        if (err instanceof Error) {
          if (
            err.name !== 'UserRejectedRequestError' &&
            !err.message.includes('User rejected the request')
          ) {
            addErrorPopup({
              subtitle: 'Unable to create token',
              summary: err.message
            })
          }
        }
        setCreationStatus((curr) =>
          curr === 'create_vesting'
            ? 'error_create_vesting'
            : curr === 'patch_market'
              ? 'error_patch_market'
              : 'error_create_market'
        )
        throw err
      }
    },
    [
      createMarketAndTokenAsync,
      createTokenLogoUploadUrl,
      description,
      isLoggedIn,
      isFormValid,
      isVestingArgsValid,
      patchMarket,
      selectedImage,
      socialUrls,
      uploadImage,
      addErrorPopup,
      toast,
      onLoginModalOpen,
      selectedChain,
      createVestingPlanAsync,
      vestingArgs,
      creationStatus,
      createdMarketAddress,
      createdBaseTokenAddress,
      uploadedIconUrl
    ]
  )

  const handleCurveTypeChange = (value: CurveType) => {
    setSelectedCurveType(value)
    setMaxPrice(defaultMaxPrices[selectedChain][value])
    setNbIntervals(defaultValues.nbIntervals)
    if (value === 'power') {
      setAlpha(defaultValues.alpha)
    } else if (value === 'quadratic') {
      setNormalizedC(defaultValues.normalizedC)
    }
  }

  const handleChainChange = (value: Chain) => {
    setSelectedChain(value)
    setMaxPrice(defaultMaxPrices[value][selectedCurveType])
    setNbIntervals(defaultValues.nbIntervals)
  }

  const handleCreateTokenClick = () => {
    if (isFormValid && isVestingArgsValid && !!createMarketAndTokenAsync) {
      setHint('')
      createToken(authTokens)
    } else if (!name) {
      setHint('Name is required')
    } else if (!symbol) {
      setHint('Symbol is required')
    } else if (!selectedImage) {
      setHint('Token logo is required')
    } else if (!isVestingArgsValid) {
      setHint('Supply % and vesting dates are required')
    }
  }

  const handleResetToDefault = () => {
    setSelectedCurveType('power')
    setMinPrice(defaultValues.minPrice)
    setMaxPrice(defaultMaxPrices[selectedChain]['power'])
    setNbIntervals(defaultValues.nbIntervals)
    setAlpha(defaultValues.alpha)
    setNormalizedC(defaultValues.normalizedC)
    setTotalSupply(defaultValues.totalSupply)
    setFees(defaultValues.fees)
    setStakingFeeShare(defaultValues.stakingFeeShare)
    setCreatorFeeShare(defaultValues.creatorFeeShare)
  }

  return (
    <Box maxW="1600px" margin="0 auto">
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={onLoginModalClose}
        onLoginSuccess={(tokens) => createToken(tokens)}
        chain={selectedChain}
        type="token"
      />

      {selectedChain === 'solana' ? (
        <TokenCreationStatusModal
          createTokenStatus={creationStatus}
          marketAddress={createdMarketAddress}
          chain={selectedChain}
          onRetry={() => createToken(authTokens)}
          baseTokenSymbol={symbol}
          isVestingEnabled={!!vestingArgs}
        />
      ) : null}

      <Grid
        templateColumns={{ base: '1fr', lg: '1fr 1px 1.5fr' }}
        alignItems="flex-start"
      >
        <Box>
          <CreateTokenHeader />

          <Divider />

          <VStack p={{ base: 4, md: 6 }} spacing={4} align="flex-start">
            <Heading size="md">Token Info</Heading>

            <Grid w="full" gap={4} templateColumns="160px auto">
              <ImagePicker
                selectedImage={selectedImage}
                setSelectedImage={(file) => {
                  setSelectedImage(file)
                  setHint('')
                }}
              />

              <SimpleGrid columns={1} gap={4} w="full">
                <VStack align="flex-start">
                  <HStack w="full" justify="space-between">
                    <Text textColor="textPrimary" fontSize="sm">
                      Name
                    </Text>
                    <Text
                      textColor={isNameValid ? 'textSecondary' : 'red.500'}
                      fontSize="sm"
                    >
                      {name.length}/32
                    </Text>
                  </HStack>
                  <Input
                    placeholder="Enter Name"
                    value={name}
                    isInvalid={!isNameValid}
                    onChange={(e) => {
                      const val = e.currentTarget.value
                      setIsNameValid(val.length > 0 && val.length <= 32)
                      setName(val)
                      setHint('')
                    }}
                  />
                </VStack>

                <VStack align="flex-start">
                  <HStack w="full" justify="space-between">
                    <Text textColor="textPrimary" fontSize="sm">
                      Symbol
                    </Text>
                    <Text
                      textColor={isSymbolValid ? 'textSecondary' : 'red.500'}
                      fontSize="sm"
                    >
                      {symbol.length}/8
                    </Text>
                  </HStack>
                  <Input
                    placeholder="Enter Symbol"
                    value={symbol}
                    isInvalid={!isSymbolValid}
                    onChange={(e) => {
                      const val = e.currentTarget.value
                      setIsSymbolValid(val.length > 0 && val.length <= 8)
                      setSymbol(val)
                      setHint('')
                    }}
                  />
                </VStack>
              </SimpleGrid>
            </Grid>

            {IS_EVM_ENABLED ? (
              <VStack align="flex-start" w="full">
                <Text textColor="textPrimary" fontSize="sm">
                  Chain
                </Text>

                <Select
                  bg="bgSecondary"
                  borderRadius={0}
                  border={0}
                  value={selectedChain}
                  onChange={(e) => handleChainChange(e.target.value as Chain)}
                >
                  <option value="avalanche">Avalanche</option>
                  <option value="solana">Solana</option>
                </Select>
              </VStack>
            ) : null}

            <VStack align="flex-start" w="full">
              <Text textColor="textPrimary" fontSize="sm">
                Description
              </Text>
              <Input
                placeholder="PUMP IT"
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
                borderColor={isSymbolValid ? 'border' : 'red.500'}
              />
            </VStack>

            <CreateTokenSocialUrlInputs
              socialUrls={socialUrls}
              setSocialUrls={setSocialUrls}
            />
          </VStack>

          <Divider />

          <Hide below="md">
            <InitialPurchaseInput
              chain={selectedChain}
              baseToken={symbol}
              quoteToken={nativeCurrency}
              askPricePoints={askPricePoints}
              onVestingArgsChange={setVestingArgs}
              setIsVestingArgsValid={setIsVestingArgsValid}
            />

            <Divider />

            <VStack p={{ base: 4, md: 6 }} spacing={4}>
              <Web3Button
                chain={selectedChain}
                variant="boxShadowFlat"
                bg="accent.500"
                w="full"
                size="lg"
                isLoading={
                  creationStatus === 'create_market' ||
                  creationStatus === 'create_vesting' ||
                  creationStatus === 'patch_market' ||
                  isSimulatingCreateMarket
                }
                loadingText={
                  isSimulatingCreateMarket
                    ? 'Preparing Transaction'
                    : creationStatus === 'patch_market'
                      ? 'Waiting for token to be available on Token Mill'
                      : 'Creating Token'
                }
                isDisabled={
                  !(isFormValid || !name || !symbol || !selectedImage)
                }
                onClick={handleCreateTokenClick}
              >
                {creationStatus === 'create_market' ||
                creationStatus === 'create_vesting' ||
                creationStatus === 'patch_market'
                  ? 'Retry'
                  : 'Create Token'}
              </Web3Button>

              {createMarketSimulateError ? (
                <CopyableError
                  error={createMarketSimulateError?.message}
                  summary={
                    (createMarketSimulateError as BaseError).shortMessage
                  }
                />
              ) : null}

              {hint ? <Warning text={hint} /> : null}

              {createdMarketAddress && creationStatus === 'done' ? (
                <VStack
                  p={4}
                  w="full"
                  bg="bgSecondary"
                  align="flex-start"
                  fontSize="sm"
                >
                  <Text>
                    Token Created {shortenAddress(createdMarketAddress)}
                  </Text>
                  <Link
                    href={`/${selectedChain}/${createdMarketAddress}`}
                    isExternal
                    color="accent.500"
                  >
                    <HStack w="fit-content">
                      <Text textColor="accent.500">View Token</Text>
                      <ExternalLinkIcon />
                    </HStack>
                  </Link>
                </VStack>
              ) : null}
            </VStack>
          </Hide>
        </Box>

        <Hide below="md">
          <Divider orientation="vertical" />
        </Hide>

        <Box>
          <VStack p={{ base: 4, md: 6 }} spacing={4} align="flex-start">
            <HStack spacing={4}>
              <Heading size="md">Curve</Heading>
              <Button
                size="xs"
                variant="boxShadowFlat"
                onClick={handleResetToDefault}
                bg="bgSecondary"
              >
                [reset to default]
              </Button>
            </HStack>

            <Flex
              w="full"
              gap={{ base: 0, md: 4 }}
              flexDir={{ base: 'column', md: 'row' }}
            >
              <Button
                flex={1}
                variant="boxShadowFlat"
                onClick={() => {
                  setMode('milltard')
                  handleResetToDefault()
                }}
                opacity={mode === 'milltard' ? 1 : 0.5}
                border="1px solid"
                borderColor={mode === 'milltard' ? 'accent.500' : 'transparent'}
                bg="bgSecondary"
                h="48px"
              >
                <HStack w="full" spacing={3}>
                  <Text>{mode === 'milltard' ? '[X]' : '[ ]'}</Text>
                  <VStack spacing={0} align="flex-start">
                    <Text>Mill-tard</Text>
                    <Text textColor="textSecondary">
                      Default settings ready to launch.
                    </Text>
                  </VStack>
                </HStack>
              </Button>
              <Button
                flex={1}
                variant="boxShadowFlat"
                onClick={() => setMode('millbrain')}
                opacity={mode === 'millbrain' ? 1 : 0.5}
                border="1px solid"
                bg="bgSecondary"
                borderColor={
                  mode === 'millbrain' ? 'accent.500' : 'transparent'
                }
                h="48px"
              >
                <HStack w="full" spacing={3}>
                  <Text>{mode === 'millbrain' ? '[X]' : '[ ]'}</Text>
                  <VStack w="full" spacing={0} align="flex-start">
                    <Text>Mill-brain</Text>
                    <Text textColor="textSecondary">
                      Only unlock if you have 1M IQ math.
                    </Text>
                  </VStack>
                </HStack>
              </Button>
            </Flex>

            <Hide below="md">
              <Tabs
                defaultIndex={0}
                index={curveTypeToIndex[selectedCurveType]}
                className="w-full"
                variant="bracketed"
              >
                <TabList>
                  <Tab
                    value="power"
                    onClick={() => handleCurveTypeChange('power')}
                  >
                    Pow
                  </Tab>
                  <Tab
                    value="quadratic"
                    onClick={() => handleCurveTypeChange('quadratic')}
                  >
                    Quad
                  </Tab>
                  <Tab
                    value="exponential"
                    onClick={() => handleCurveTypeChange('exponential')}
                  >
                    Exp
                  </Tab>
                  <Tab
                    value="logarithmic"
                    onClick={() => handleCurveTypeChange('logarithmic')}
                  >
                    Log
                  </Tab>
                </TabList>
              </Tabs>
            </Hide>

            <Show below="md">
              <Select
                bg="bgSecondary"
                borderRadius={0}
                border={0}
                defaultValue="power"
                onChange={(e) =>
                  handleCurveTypeChange(e.target.value as CurveType)
                }
                variant="outline"
                className="w-full"
              >
                <option value="power">Pow</option>
                <option value="quadratic">Quad</option>
                <option value="exponential">Exp</option>
                <option value="logarithmic">Log</option>
              </Select>
            </Show>

            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} w="full">
              <VStack align="flex-start">
                <Text
                  textColor="textPrimary"
                  fontSize="sm"
                  opacity={mode === 'milltard' ? 0.5 : 1}
                >
                  Min Price
                </Text>
                <InputGroup>
                  <NumericalInput
                    inputType="decimal"
                    placeholder="0"
                    value={minPrice}
                    onValueChange={(value) => {
                      setMinPrice(value)
                    }}
                    isInvalid={!isMinPriceValid}
                    isDisabled={mode === 'milltard'}
                  />
                  <InputRightElement
                    textColor="textSecondary"
                    fontSize="sm"
                    mr={1}
                  >
                    {nativeCurrency.symbol}
                  </InputRightElement>
                </InputGroup>
              </VStack>

              <VStack align="flex-start">
                <Text
                  textColor="textPrimary"
                  fontSize="sm"
                  opacity={mode === 'milltard' ? 0.5 : 1}
                >
                  Max Price
                </Text>
                <InputGroup>
                  <NumericalInput
                    inputType="decimal"
                    placeholder="0"
                    value={maxPrice}
                    onValueChange={(value) => {
                      setMaxPrice(value)
                    }}
                    isInvalid={!isMaxPriceValid}
                    isDisabled={mode === 'milltard'}
                  />
                  <InputRightElement
                    textColor="textSecondary"
                    fontSize="sm"
                    mr={1}
                  >
                    {nativeCurrency.symbol}
                  </InputRightElement>
                </InputGroup>
              </VStack>

              <VStack align="flex-start">
                <HStack justify="space-between" w="full">
                  <Text
                    textColor="textPrimary"
                    fontSize="sm"
                    opacity={mode === 'milltard' ? 0.5 : 1}
                  >
                    Total Supply
                  </Text>

                  {fullyDilutedValuation ? (
                    <Text textColor="textSecondary" fontSize="sm">
                      FDV: {formattedNum(fullyDilutedValuation)}{' '}
                      {nativeCurrency.symbol}
                    </Text>
                  ) : null}
                </HStack>
                <NumericalInput
                  placeholder="0"
                  inputType="integer"
                  value={totalSupply}
                  onValueChange={(value) => {
                    setTotalSupply(value)
                  }}
                  isInvalid={!isTotalSupplyValid}
                  isDisabled={mode === 'milltard'}
                />
                <Slider
                  min={10_000_000}
                  max={1_000_000_000}
                  onChange={(value) => {
                    setTotalSupply(value.toString())
                  }}
                  step={10_000_000}
                  defaultValue={100_000_000}
                  value={parseInt(totalSupply)}
                  focusThumbOnChange={false}
                  isDisabled={mode === 'milltard'}
                >
                  <SliderTrack h="8px">
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </VStack>

              <VStack>
                <Grid
                  templateColumns="minmax(0, 1fr) minmax(0, 3fr)"
                  gap={2}
                  alignItems="flex-end"
                >
                  <VStack align="flex-start">
                    <Text
                      textColor="textPrimary"
                      fontSize="sm"
                      opacity={mode === 'milltard' ? 0.5 : 1}
                    >
                      Fees
                    </Text>
                    <InputGroup>
                      <NumericalInput
                        inputType="decimal"
                        placeholder="0"
                        value={fees}
                        onValueChange={setFees}
                        isInvalid={!isFeeValid}
                        isDisabled={mode === 'milltard'}
                      />
                      <InputRightElement
                        opacity={mode === 'milltard' ? 0.5 : 1}
                      >
                        %
                      </InputRightElement>
                    </InputGroup>
                  </VStack>

                  <VStack align="flex-start">
                    <TouchFriendlyTooltip
                      label="Fees are distributed among creators, stakers, and the protocol. Creator and staking shares are adjustable, while the protocol share is fixed at 20%."
                      placement="top"
                    >
                      <Text
                        textColor="textPrimary"
                        fontSize="sm"
                        textDecor="underline"
                        textDecorationStyle="dotted"
                        opacity={mode === 'milltard' ? 0.5 : 1}
                      >
                        Creator / Staking / Protocol Shares
                      </Text>
                    </TouchFriendlyTooltip>

                    <HStack>
                      <InputGroup>
                        <NumericalInput
                          inputType="decimal"
                          placeholder="0"
                          value={creatorFeeShare}
                          onValueChange={(value) => {
                            setCreatorFeeShare(value)
                            setStakingFeeShare(
                              value ? (80 - parseFloat(value)).toString() : ''
                            )
                          }}
                          isInvalid={!isFeeShareValid}
                          isDisabled={mode === 'milltard'}
                        />
                        <InputRightElement
                          opacity={mode === 'milltard' ? 0.5 : 1}
                        >
                          %
                        </InputRightElement>
                      </InputGroup>

                      <InputGroup>
                        <NumericalInput
                          inputType="decimal"
                          placeholder="0"
                          value={stakingFeeShare}
                          onValueChange={(value) => {
                            setStakingFeeShare(value)
                            setCreatorFeeShare(
                              value ? (80 - parseFloat(value)).toString() : ''
                            )
                          }}
                          isInvalid={!isFeeShareValid}
                          isDisabled={mode === 'milltard'}
                        />
                        <InputRightElement
                          opacity={mode === 'milltard' ? 0.5 : 1}
                        >
                          %
                        </InputRightElement>
                      </InputGroup>

                      <InputGroup>
                        <NumericalInput
                          inputType="decimal"
                          isReadOnly
                          isDisabled
                          value="20"
                        />
                        <InputRightElement
                          opacity={mode === 'milltard' ? 0.5 : 1}
                        >
                          %
                        </InputRightElement>
                      </InputGroup>
                    </HStack>
                  </VStack>
                </Grid>

                <Slider
                  min={0}
                  max={10}
                  onChange={(value) => {
                    setFees(value.toString())
                  }}
                  step={0.1}
                  defaultValue={1}
                  value={parseFloat(fees)}
                  focusThumbOnChange={false}
                  isDisabled={mode === 'milltard'}
                >
                  <SliderTrack h="8px">
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </VStack>

              <VStack align="flex-start">
                <TouchFriendlyTooltip label="The number of intervals is fixed on Solana">
                  <Text
                    textColor="textPrimary"
                    fontSize="sm"
                    textDecor="underline"
                    textDecorationStyle="dotted"
                    opacity={mode === 'milltard' ? 0.5 : 1}
                  >
                    Nb Intervals
                  </Text>
                </TouchFriendlyTooltip>
                <NumericalInput
                  inputType="integer"
                  placeholder="0"
                  value={nbIntervals}
                  onValueChange={setNbIntervals}
                  isInvalid={!isNbIntervalsValid}
                  isDisabled={selectedChain === 'solana' || mode === 'milltard'}
                />
                <Slider
                  min={2}
                  max={20}
                  onChange={(value) => {
                    setNbIntervals(value.toString())
                  }}
                  step={1}
                  defaultValue={10}
                  isDisabled={selectedChain === 'solana' || mode === 'milltard'}
                  value={parseInt(nbIntervals)}
                  focusThumbOnChange={false}
                >
                  <SliderTrack h="8px">
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
                {!isNbIntervalsValid && (
                  <Text color="red.500" fontSize="sm">
                    Nb intervals must be between 1 and 20
                  </Text>
                )}
              </VStack>

              {selectedCurveType === 'quadratic' && (
                <VStack align="flex-start">
                  <Text
                    textColor="textPrimary"
                    fontSize="sm"
                    opacity={mode === 'milltard' ? 0.5 : 1}
                  >
                    C (normalized)
                  </Text>
                  <NumericalInput
                    inputType="decimal"
                    placeholder="0"
                    value={normalizedC}
                    onValueChange={setNormalizedC}
                    isInvalid={!isNormalizedCValid}
                    isDisabled={mode === 'milltard'}
                  />
                  <Slider
                    min={0}
                    max={10}
                    onChange={(value) => {
                      setNormalizedC(value.toString())
                    }}
                    step={0.1}
                    defaultValue={2}
                    value={parseFloat(normalizedC)}
                    focusThumbOnChange={false}
                    isDisabled={mode === 'milltard'}
                  >
                    <SliderTrack h="8px">
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </VStack>
              )}

              {selectedCurveType === 'power' && (
                <VStack align="flex-start">
                  <Text
                    textColor="textPrimary"
                    fontSize="sm"
                    opacity={mode === 'milltard' ? 0.5 : 1}
                  >
                    ⍺
                  </Text>
                  <NumericalInput
                    inputType="decimal"
                    placeholder="0"
                    value={alpha}
                    onValueChange={setAlpha}
                    isInvalid={!isAlphaValid}
                    isDisabled={mode === 'milltard'}
                  />
                  <Slider
                    min={-5}
                    max={5}
                    onChange={(value) => {
                      setAlpha(value.toString())
                    }}
                    step={0.001}
                    defaultValue={0.2}
                    value={parseFloat(alpha)}
                    focusThumbOnChange={false}
                    isDisabled={mode === 'milltard'}
                  >
                    <SliderTrack h="8px">
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </VStack>
              )}
            </SimpleGrid>

            {totalSupplyError ? <Warning text={totalSupplyError} /> : null}
            {!isFeeShareValid ? (
              <Warning text="Fee shares must total 80% and be greater than 0" />
            ) : null}

            <BondingCurvesChart
              chain={selectedChain}
              askPricePoints={askPricePoints}
              bidPricePoints={bidPricePoints}
            />

            <PricePointsInput
              title="Ask Price Points"
              pricePoints={askPricePoints}
              setPricePoints={setAskPricePoints}
              pricePointsError={askPricePointsInputError}
              setPricePointsError={setAskPricePointsInputError}
              pricePointsString={askPricePointsString}
              setPricePointsString={setAskPricePointsString}
              xMax={totalSupply ? parseInt(totalSupply) : 0}
              isDisabled={mode === 'milltard'}
            />

            <PricePointsInput
              title="Bid Price Points"
              pricePoints={bidPricePoints}
              setPricePoints={setBidPricePoints}
              pricePointsError={bidPricePointsInputError}
              setPricePointsError={setBidPricePointsInputError}
              pricePointsString={bidPricePointsString}
              setPricePointsString={setBidPricePointsString}
              xMax={totalSupply ? parseInt(totalSupply) : 0}
              isDisabled={mode === 'milltard'}
            />
          </VStack>
        </Box>
      </Grid>

      <Show below="md">
        <InitialPurchaseInput
          chain={selectedChain}
          baseToken={symbol}
          quoteToken={nativeCurrency}
          askPricePoints={askPricePoints}
          onVestingArgsChange={setVestingArgs}
          setIsVestingArgsValid={setIsVestingArgsValid}
        />

        <Divider />

        <VStack p={{ base: 4, md: 6 }} spacing={4}>
          <Web3Button
            chain={selectedChain}
            variant="boxShadowFlat"
            bg="accent.500"
            w="full"
            size="lg"
            isLoading={
              creationStatus === 'create_market' ||
              creationStatus === 'create_vesting' ||
              isSimulatingCreateMarket
            }
            loadingText={
              isSimulatingCreateMarket
                ? 'Preparing Transaction'
                : 'Creating Token'
            }
            isDisabled={!(isFormValid || !name || !symbol || !selectedImage)}
            onClick={handleCreateTokenClick}
          >
            Create Token
          </Web3Button>

          {createMarketSimulateError ? (
            <CopyableError
              error={createMarketSimulateError?.message}
              summary={(createMarketSimulateError as BaseError).shortMessage}
            />
          ) : null}

          {hint ? <Warning text={hint} /> : null}

          {createdMarketAddress && creationStatus === 'done' ? (
            <VStack
              p={4}
              w="full"
              bg="bgSecondary"
              align="flex-start"
              fontSize="sm"
            >
              <Text>Token Created {shortenAddress(createdMarketAddress)}</Text>
              <Link
                href={`/${selectedChain}/${createdMarketAddress}`}
                isExternal
                color="accent.500"
              >
                <HStack w="fit-content">
                  <Text textColor="accent.500">View Token</Text>
                  <ExternalLinkIcon />
                </HStack>
              </Link>
            </VStack>
          ) : null}
        </VStack>
      </Show>
    </Box>
  )
}

export default CreateToken
