import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, HStack, Link, Skeleton, Text } from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'

interface TokenInfoGridElementProps {
  title: string
  isLoading?: boolean
  link?: string
  secondaryValue?: string
  tooltipLabel?: React.ReactNode
  value?: string
}

const TokenInfoGridElement = ({
  isLoading,
  link,
  secondaryValue,
  title,
  tooltipLabel,
  value
}: TokenInfoGridElementProps) => {
  return (
    <Box fontSize="sm">
      <Text textColor="textSecondary">{title}</Text>
      <Skeleton isLoaded={value !== undefined} minH="21px">
        {link ? (
          <Link isExternal href={link}>
            <HStack spacing={1}>
              <Text>{value}</Text>
              <ExternalLinkIcon boxSize={4} />
            </HStack>
          </Link>
        ) : (
          <HStack>
            <Skeleton isLoaded={!isLoading} minW="40px">
              {tooltipLabel ? (
                <TouchFriendlyTooltip label={tooltipLabel}>
                  <Text
                    fontWeight="semibold"
                    textDecor="underline"
                    textDecorationStyle="dotted"
                  >
                    {value}
                  </Text>
                </TouchFriendlyTooltip>
              ) : (
                <Text fontWeight="semibold">{value}</Text>
              )}
            </Skeleton>
            {secondaryValue && (
              <Text textColor="textSecondary">{secondaryValue}</Text>
            )}
          </HStack>
        )}
      </Skeleton>
    </Box>
  )
}

export default TokenInfoGridElement
