export const TokenMillRouterAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'v1Factory',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'v2_0Router',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'v2_1Factory',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'v2_2Factory',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tmFactory',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'wnative',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    stateMutability: 'payable',
    type: 'receive'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'v',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'sv',
        type: 'uint256'
      }
    ],
    name: 'getFactory',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getWNative',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'bytes[]',
        name: 'routes',
        type: 'bytes[]'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      {
        internalType: 'bool',
        name: 'exactIn',
        type: 'bool'
      }
    ],
    name: 'simulate',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'route',
        type: 'bytes'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      {
        internalType: 'bool',
        name: 'exactIn',
        type: 'bool'
      }
    ],
    name: 'simulateSingle',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'route',
        type: 'bytes'
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountOutMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'deadline',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      }
    ],
    name: 'swapExactIn',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'route',
        type: 'bytes'
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountOutMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'deadline',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      }
    ],
    name: 'swapExactInSupportingFeeOnTransferTokens',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'route',
        type: 'bytes'
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amountInMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'deadline',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      }
    ],
    name: 'swapExactOut',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'target',
        type: 'address'
      }
    ],
    name: 'AddressEmptyCode',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'AddressInsufficientBalance',
    type: 'error'
  },
  {
    inputs: [],
    name: 'FailedInnerCall',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Math__DivisionByZero',
    type: 'error'
  },
  {
    inputs: [],
    name: 'PackedRoute__InvalidId',
    type: 'error'
  },
  {
    inputs: [],
    name: 'PackedRoute__InvalidRoute',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__ExceedsDeadline',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__ExceedsMaxInputAmount',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InsufficientLiquidity',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InsufficientOutputAmount',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InvalidAmounts',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InvalidId',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InvalidMarket',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__InvalidRecipient',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__NativeTransferFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Router__OnlyWNative',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'Router__Simulation',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256[]',
        name: 'amounts',
        type: 'uint256[]'
      }
    ],
    name: 'Router__Simulations',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      }
    ],
    name: 'SafeERC20FailedOperation',
    type: 'error'
  }
] as const
