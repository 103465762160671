import { Box, Text, TextProps } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'

interface ExpandableTextProps extends TextProps {
  numberOfCharactersWhenCollapsed: number
  text: string
}

const ExpandableText = ({
  numberOfCharactersWhenCollapsed,
  text,
  ...props
}: ExpandableTextProps): JSX.Element => {
  const [isExpanded, setExpanded] = useState(
    text.length <= numberOfCharactersWhenCollapsed
  )

  const fmtText = useMemo(() => {
    return isExpanded ? text : text.slice(0, numberOfCharactersWhenCollapsed)
  }, [text, isExpanded, numberOfCharactersWhenCollapsed])

  useEffect(() => {
    setExpanded(text.length <= numberOfCharactersWhenCollapsed)
  }, [text, numberOfCharactersWhenCollapsed])

  return (
    <Text {...props} whiteSpace="pre-line">
      {fmtText}
      {!isExpanded ? (
        <Box
          as="span"
          p="0.25rem"
          ml="-0.25rem"
          cursor="pointer"
          onClick={() => setExpanded(true)}
        >
          ...{' '}
          <Box as="span" fontWeight="semibold" color="textPrimary">
            more
          </Box>
        </Box>
      ) : null}
    </Text>
  )
}

export default ExpandableText
