import { Divider, Heading, HStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { ArrowLeftIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'

import WalletMenuItem from '../ConnectedScreen/WalletMenuItem'
import ActivityList from './ActivityList'

interface ActivityScreenProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
}

const ActivityScreen = ({ setWalletMenuDisplay }: ActivityScreenProps) => {
  return (
    <>
      <HStack px={3} align="center" pb={3}>
        <WalletMenuItem
          columnGap={4}
          onClick={() => setWalletMenuDisplay(WalletMenuEnum.Default)}
        >
          <ArrowLeftIcon />
          <Heading size="sm">
            <Trans>Activity</Trans>
          </Heading>
        </WalletMenuItem>
      </HStack>

      <Divider mb={4} />

      <ActivityList />
    </>
  )
}

export default ActivityScreen
