import { createAction } from '@reduxjs/toolkit'
import { AuthTokens, Chain } from 'types/dexbarn'

export const updateTokens = createAction<{
  address: string
  chain: Chain
  tokens: AuthTokens
}>('authentication/updateTokens')

export const clearTokens = createAction<{ address: string; chain: Chain }>(
  'authentication/clearTokens'
)
