import { HStack } from '@chakra-ui/react'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import TokenInfoGridElement from 'pages/TokenMillDetail/TokenInfoGridElement'
import React from 'react'
import { VestingSchedule } from 'types/market'
import { formattedNum } from 'utils/format'

interface VestingAnalyticsProps {
  market: ReturnType<typeof useGetMarket>['data']
  vestingSchedules: VestingSchedule[] | undefined
}

const VestingAnalytics = ({
  market,
  vestingSchedules
}: VestingAnalyticsProps) => {
  const upcomingUnlock = React.useMemo(() => {
    if (!vestingSchedules?.length || !market) return undefined

    const now = Date.now()
    const upcomingSchedules = vestingSchedules.filter(
      (schedule) => (schedule.start + schedule.cliffDuration) * 1000 > now
    )

    const upcomingSchedule = upcomingSchedules.sort(
      (a, b) => a.start + a.cliffDuration - (b.start + b.cliffDuration)
    )[0]

    if (!upcomingSchedule) return undefined

    const cliffEnd = upcomingSchedule.start + upcomingSchedule.cliffDuration
    const totalVestingDuration = cliffEnd + upcomingSchedule.vestingDuration

    const amountUnlockedAtCliff =
      Number(upcomingSchedule.total.formatted) *
      (cliffEnd / totalVestingDuration)

    return {
      amountUnlockedAtCliff,
      circulatingSupplyPercent:
        (amountUnlockedAtCliff / market.circulatingSupply) * 100,
      cliffEnd: cliffEnd * 1000
    }
  }, [vestingSchedules, market])

  const next7DaysEmission = React.useMemo(() => {
    if (!vestingSchedules?.length || !market) return undefined

    const now = Math.floor(Date.now() / 1000)
    const sevenDaysFromNow = now + 7 * 24 * 60 * 60

    const amount = vestingSchedules.reduce((total, schedule) => {
      const vestingEndTime =
        schedule.start + schedule.cliffDuration + schedule.vestingDuration
      const cliffEndTime = schedule.start + schedule.cliffDuration

      // Skip if vesting already completed
      if (now >= vestingEndTime) {
        return total
      }

      // Handle cliff ending within next 7 days
      if (cliffEndTime > now && cliffEndTime <= sevenDaysFromNow) {
        const periodStart = cliffEndTime
        const periodEnd = Math.min(sevenDaysFromNow, vestingEndTime)

        const totalVestingAmount = Number(schedule.total.formatted)
        const emissionRate = totalVestingAmount / schedule.vestingDuration
        const periodDuration = periodEnd - periodStart

        return total + emissionRate * periodDuration
      }

      // Handle already vesting tokens
      if (now >= cliffEndTime) {
        const periodStart = now
        const periodEnd = Math.min(sevenDaysFromNow, vestingEndTime)

        const totalVestingAmount = Number(schedule.total.formatted)
        const emissionRate = totalVestingAmount / schedule.vestingDuration
        const periodDuration = periodEnd - periodStart

        return total + emissionRate * periodDuration
      }

      return total
    }, 0)

    return {
      amount,
      circulatingSupplyPercent: (amount / market.circulatingSupply) * 100,
      usd: amount * (market.baseTokenPriceUsd || 0)
    }
  }, [vestingSchedules, market])

  return (
    <HStack
      w="full"
      rowGap={{ base: 4, md: 12 }}
      columnGap={{ base: 8, md: 12 }}
      p={{ base: 4, md: 6 }}
    >
      <TokenInfoGridElement
        title="Upcoming Unlock"
        value={
          upcomingUnlock && market
            ? formattedNum(
                upcomingUnlock.amountUnlockedAtCliff *
                  (market.baseTokenPriceUsd || 0),
                { usd: true }
              )
            : '--'
        }
        tooltipLabel={
          upcomingUnlock && market && market.circulatingSupply > 0
            ? `${formattedNum(upcomingUnlock.circulatingSupplyPercent, {
                places: 2
              })}% of circulating supply`
            : undefined
        }
      />

      <TokenInfoGridElement
        title="Next 7D Emission"
        value={
          next7DaysEmission && next7DaysEmission.amount > 0
            ? formattedNum(next7DaysEmission.usd, { usd: true })
            : '$0'
        }
        tooltipLabel={
          next7DaysEmission && next7DaysEmission.amount > 0
            ? `${next7DaysEmission.circulatingSupplyPercent.toFixed(
                2
              )}% of circulating supply`
            : undefined
        }
      />
    </HStack>
  )
}

export default VestingAnalytics
