import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const menuListStyle = {
  bg: 'bgPrimary',
  borderColor: 'border',
  borderRadius: 0
}

const baseStyle = defineStyle({
  item: {
    _hover: { bg: 'hover' },
    bg: 'transparent',
    borderRadius: 0,
    px: 2
  },
  list: {
    ...menuListStyle,
    display: 'flex',
    flexDir: 'column',
    gap: 0.5,
    p: 2
  }
})

export const menuTheme = defineStyleConfig({
  baseStyle
})
