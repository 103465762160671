import useUnstakeTokensEVM from 'hooks/evm/useUnstakeTokens'
import useUnstakeTokensSolana from 'solana/hooks/useUnstakeTokens'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseUnstakeTokensProps {
  amount: bigint | undefined
  baseTokenAddress: string
  baseTokenSymbol: string
  chain: Chain
  marketAddress: string
  onUnstakeSuccess?: () => void
}

const useUnstakeTokens = (props: UseUnstakeTokensProps) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const unstakeEVM = useUnstakeTokensEVM(props)
  const unstakeSolana = useUnstakeTokensSolana(props)

  switch (chainType) {
    case 'evm':
      return unstakeEVM
    case 'solana':
      return unstakeSolana
  }
}

export default useUnstakeTokens
