import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter
} from '@solana/wallet-adapter-wallets'
import { IS_TESTNET } from 'constants/chains'

// Solana network configuration
export const config = IS_TESTNET
  ? {
      endpoint: 'https://api.devnet.solana.com',
      network: WalletAdapterNetwork.Devnet
    }
  : {
      endpoint: 'https://rpc-solana.tokenmill.xyz',
      network: WalletAdapterNetwork.Mainnet
    }

// Wallet adapters
export const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter()]
