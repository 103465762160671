import { Tab, TabList, Tabs, useColorMode } from '@chakra-ui/react'
import React from 'react'

const ColorModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  const handleTabsChange = () => {
    toggleColorMode()
  }

  const initialTabIndex = colorMode === 'dark' ? 1 : 0

  return (
    <Tabs
      defaultIndex={initialTabIndex}
      onChange={handleTabsChange}
      variant="bracketed"
    >
      <TabList>
        <Tab>Light</Tab>
        <Tab>Dark</Tab>
      </TabList>
    </Tabs>
  )
}

export default ColorModeToggle
