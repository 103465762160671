import { t } from '@lingui/macro'
import { useMemo, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { getChainId, getChainType } from 'utils/chains'
import { erc20Abi, getAddress, isAddress, maxUint256, parseAbi } from 'viem'
import {
  useAccount,
  useReadContract,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

import useTransactionToast from '../useTransactionToast'
import useWaitForTransactionReceipt from './useWaitForTransactionReceipt'

export type TokenApprovalType = 'one_time' | 'unlimited'

interface UseApproveSpenderIfNeededProps {
  chain: Chain
  spender: string
  amount?: bigint
  token?: string
  tokenSymbol?: string
}

const useApproveSpenderIfNeeded = ({
  amount: transactionAmount,
  chain,
  spender,
  token,
  tokenSymbol
}: UseApproveSpenderIfNeededProps) => {
  const { address: account, chain: walletChain } = useAccount()
  const chainId = getChainId(chain)
  const chainType = getChainType(chain)
  const addTransactionToast = useTransactionToast()

  const [approvalType, setApprovalType] =
    useState<TokenApprovalType>('one_time')
  const amount = useMemo(
    () => (approvalType === 'unlimited' ? maxUint256 : transactionAmount),
    [approvalType, transactionAmount]
  )

  const {
    data: allowance,
    isLoading: isLoadingAllowance,
    refetch: refetchAllowance
  } = useReadContract({
    abi: erc20Abi,
    address: token && isAddress(token) ? getAddress(token) : undefined,
    args:
      account && isAddress(spender)
        ? [account, getAddress(spender)]
        : undefined,
    chainId,
    functionName: 'allowance',
    query: {
      enabled: !!account && amount !== undefined && chainType === 'evm',
      gcTime: 0
    }
  })

  const isInitiallyApproved = useMemo(
    () =>
      allowance !== undefined && amount !== undefined
        ? allowance >= amount
        : undefined,
    [allowance, amount]
  )

  const { data: config } = useSimulateContract({
    abi: parseAbi(['function approve(address spender, uint256 amount)']),
    address: token && isAddress(token) ? getAddress(token) : undefined,
    args:
      amount && isAddress(spender) ? [getAddress(spender), amount] : undefined,
    chainId,
    functionName: 'approve',
    query: {
      enabled:
        !isInitiallyApproved &&
        amount !== undefined &&
        allowance !== undefined &&
        walletChain?.id === chainId &&
        chainType === 'evm',
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    reset,
    writeContract,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) =>
        addTransactionToast({
          chain,
          description: t`Approve ${tokenSymbol}`,
          hash,
          walletAddress: account || ''
        })
    }
  })

  const approve = config?.request
    ? () => writeContract(config.request)
    : undefined
  const approveAsync = config?.request
    ? () => writeContractAsync(config.request)
    : undefined

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransactionReceipt({
    chain,
    hash,
    onTransactionSuccess: () => refetchAllowance()
  })

  return {
    approvalType,
    approve,
    approveAsync,
    isApproved: isInitiallyApproved || receipt?.status === 'success',
    isApproving: isWaitingForTransaction || isPending,
    isLoadingAllowance,
    isSuccess,
    refetchAllowance,
    reset,
    setApprovalType
  }
}

export default useApproveSpenderIfNeeded
