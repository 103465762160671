import useReleaseTokensEVM from 'hooks/evm/useReleaseTokens'
import useReleaseTokensSolana from 'solana/hooks/useReleaseTokensSolana'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseReleaseTokensProps {
  baseTokenAddress: string
  chain: Chain
  marketAddress: string
  vestingPlanId: string
  vestingPlanIndex: number
  enabled?: boolean
  onReleaseSuccess?: () => void
}

const useReleaseTokens = (props: UseReleaseTokensProps) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const releaseEVM = useReleaseTokensEVM({
    ...props,
    enabled: chainType === 'evm' && props.enabled
  })
  const releaseSolana = useReleaseTokensSolana({
    ...props,
    enabled: chainType === 'solana' && props.enabled
  })

  switch (chainType) {
    case 'evm':
      return releaseEVM
    case 'solana':
      return releaseSolana
  }
}

export default useReleaseTokens
