import { TMMarketQueryParam } from 'types/dexbarn'
import { SolbarnMarketQueryParam } from 'types/solbarn'

export const convertToSolbarnFilterBy = (
  filterBy: TMMarketQueryParam.FilterBy
): SolbarnMarketQueryParam.FilterBy => {
  switch (filterBy) {
    case '1h':
      return 'h1'
    case '1d':
      return 'd1'
    case '5m':
      return 'm5'
    case '6h':
      return 'h6'
    case '7d':
      return 'd7'
      break
    case '14d':
      return 'd14'
    case '30d':
      return 'd30'
  }
}
