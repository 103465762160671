import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { Link } from 'react-router-dom'
import { MarketData } from 'types/dexbarn'
import { formatShortDistanceToNow, formattedNum } from 'utils/format'

interface Props {
  market: MarketData
  onClick: () => void
  id?: string
  isHighlighted?: boolean
  onMouseEnter?: () => void
}

const MarketSearchResultRow = ({
  id,
  isHighlighted,
  market,
  onClick,
  onMouseEnter
}: Props) => {
  return (
    <HStack
      id={id}
      as={Link}
      aria-label={`Link to ${market.baseToken.symbol}`}
      to={`/${market.chain}/${market.marketAddress}`}
      px={4}
      py={3}
      spacing={4}
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      borderRadius={0}
      bg={isHighlighted ? 'hover' : undefined}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <CurrencyLogo
        address={market.baseToken.address}
        symbol={market.baseToken.symbol}
        chain={market.chain}
        logoURI={market.iconUrl}
        boxSize={8}
      />

      <VStack align="flex-start" spacing={2}>
        <HStack spacing={2} fontSize="sm">
          <Text fontWeight="bold">{market.baseToken.symbol}</Text>
          <Text textColor="textSecondary">{market.baseToken.name}</Text>
          <Text fontSize="sm">
            {formattedNum(market.baseToken.priceUsd, {
              usd: true
            })}
          </Text>
          <Text
            fontSize="sm"
            textColor={market.baseTokenPctChange >= 0 ? 'green.400' : 'red.500'}
          >
            {formattedNum(market.baseTokenPctChange, { places: 2 })}%
          </Text>
        </HStack>

        <HStack>
          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              MCAP
            </Box>{' '}
            {formattedNum(market.baseMarketCapUsd, {
              usd: true
            })}
          </Text>

          <Text textColor="textSecondary">•</Text>

          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              VOL
            </Box>{' '}
            {formattedNum(market.volumeUsd, {
              usd: true
            })}
          </Text>

          <Text textColor="textSecondary">•</Text>

          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              LIQ
            </Box>{' '}
            {formattedNum(market.quoteLiquidityUsd, { places: 2, usd: true })}
          </Text>

          <Text textColor="textSecondary">•</Text>

          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              TXNS
            </Box>{' '}
            {formattedNum(market.numberSwaps)}
          </Text>

          <Text textColor="textSecondary">•</Text>

          <Text fontSize="sm">
            {formatShortDistanceToNow(new Date(market.marketCreatedAt))} ago
          </Text>
        </HStack>
      </VStack>
    </HStack>
  )
}

export default MarketSearchResultRow
