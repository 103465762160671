import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
)
const bracketed = {
  tab: {
    _before: {
      content: "'[\u00A0]'",
      marginRight: '8px',
      whiteSpace: 'pre'
    },
    _selected: {
      _before: {
        content: "'[X]'",
        marginRight: '8px',
        whiteSpace: 'pre'
      },
      bg: '#3cb7fd',
      color: '#181823'
    },
    color: 'textPrimary',
    fontSize: 'sm',
    fontWeight: 'medium',
    mr: 2,
    position: 'relative',
    px: 1,
    py: 2
  },
  tablist: {
    border: 'none'
  }
}

const bracketedSecondary = {
  ...bracketed,
  tab: {
    ...bracketed.tab,
    _selected: {
      ...bracketed.tab._selected,
      _dark: {
        bg: 'whiteAlpha.200'
      },
      bg: 'blueGray',
      color: 'textPrimary'
    }
  }
}

const line = {
  tab: {
    _before: {
      content: "'[\u00A0]'",
      marginRight: '8px'
    },
    _selected: {
      _before: {
        content: "'[X]'",
        marginRight: '8px'
      },
      borderBottom: '3px solid #3cb7fd',
      color: 'textPrimary'
    },
    color: 'textPrimary',
    fontSize: 'sm',
    fontWeight: 'medium',
    mr: 2,
    position: 'relative',
    px: 1,
    py: 2,
    zIndex: 1
  },
  tablist: {
    borderBottom: '0px',
    borderColor: 'transparent'
  }
}

export const tabsTheme = defineMultiStyleConfig({
  variants: {
    bracketed,
    bracketedSecondary,
    line
  }
})
