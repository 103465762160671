import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import useClaimCreatorFees from 'hooks/tokenmill/useClaimCreatorFees'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { Token } from 'types/token'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface BalanceOverviewProps {
  chain: Chain
  marketAddress: string
  pendingCreatorFees: bigint | undefined
  quoteCurrency: Token
  onClaimSuccess?: () => void
}

const CreatorFees = ({
  chain,
  marketAddress,
  onClaimSuccess,
  pendingCreatorFees,
  quoteCurrency
}: BalanceOverviewProps) => {
  const fmtPendingCreatorFees = pendingCreatorFees
    ? formatUnits(pendingCreatorFees, quoteCurrency.decimals)
    : undefined

  const { claimAsync, isClaiming } = useClaimCreatorFees({
    chain,
    marketAddress,
    onClaimSuccess,
    quoteTokenAddress: quoteCurrency.address
  })

  return (
    <Box p={6} w="full">
      <VStack align="stretch" spacing={6}>
        <Heading size="sm">CREATOR FEES</Heading>

        <VStack align="stretch" spacing={2} fontSize="sm">
          <Flex
            w="full"
            borderBottom="1px dashed"
            borderColor="border"
            justifyContent="space-between"
            pb={1}
          >
            <Text textColor="textSecondary">Pending Fees:</Text>
            <Text>
              {fmtPendingCreatorFees
                ? formattedNum(fmtPendingCreatorFees)
                : '0'}{' '}
              {quoteCurrency.symbol}
            </Text>
          </Flex>
        </VStack>

        <Button
          bg="accent.500"
          size="lg"
          textColor="white"
          variant="boxShadowFlat"
          width="full"
          isDisabled={!claimAsync}
          onClick={() => {
            claimAsync?.()
          }}
          isLoading={isClaiming}
          loadingText="Claiming"
        >
          [CLAIM FEES]
        </Button>
      </VStack>
    </Box>
  )
}

export default CreatorFees
