import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import {
  setAreTermsAccepted,
  setFeeMode,
  setIsUKDisclaimerHidden,
  setMaxTransactionFee,
  setOriginCountryCode
} from './actions'
import { DEFAULT_FEE_MODE, FeeMode } from './reducer'

const useAreTermsAccepted = () => {
  const dispatch = useAppDispatch()
  const areTermsAccepted = useAppSelector(
    (state) => state.settings.areTermsAccepted
  )
  const updateAreTermsAccepted = useCallback(
    (areAccepted: boolean) => {
      dispatch(setAreTermsAccepted(areAccepted))
    },
    [dispatch]
  )
  return { areTermsAccepted, updateAreTermsAccepted }
}

const useOriginCountry = () => {
  const dispatch = useAppDispatch()
  const originCountryCode = useAppSelector(
    (state) => state.settings.originCountryCode
  )
  const updateOriginCountryCode = useCallback(
    (originCountryCode: string) => {
      dispatch(setOriginCountryCode(originCountryCode))
    },
    [dispatch]
  )
  return { originCountryCode, updateOriginCountryCode }
}

const useSetUKDisclaimerHidden = () => {
  const dispatch = useAppDispatch()
  const isUKDisclaimerHidden = useAppSelector(
    (state) => state.settings.isUKDisclaimerHidden
  )
  const setUKDisclaimerHidden = useCallback(
    (isHidden: boolean) => {
      dispatch(setIsUKDisclaimerHidden(isHidden))
    },
    [dispatch]
  )
  return { isUKDisclaimerHidden, setUKDisclaimerHidden }
}

const useMaxTransactionFee = () => {
  const dispatch = useAppDispatch()
  const maxTransactionFee = useAppSelector(
    (state) => state.settings.maxTransactionFee
  )
  const updateMaxTransactionFee = useCallback(
    (maxTransactionFee: string) => {
      dispatch(setMaxTransactionFee(maxTransactionFee))
    },
    [dispatch]
  )
  return {
    maxTransactionFee,
    updateMaxTransactionFee
  }
}

const useFeeMode = () => {
  const dispatch = useAppDispatch()
  const feeMode = useAppSelector((state) => state.settings.feeMode)
  const updateFeeMode = useCallback(
    (feeMode: FeeMode) => {
      dispatch(setFeeMode(feeMode))
    },
    [dispatch]
  )
  return { feeMode: feeMode || DEFAULT_FEE_MODE, updateFeeMode }
}

export {
  useAreTermsAccepted,
  useFeeMode,
  useMaxTransactionFee,
  useOriginCountry,
  useSetUKDisclaimerHidden
}
