import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { Chain } from 'types/dexbarn'

interface Web3MessageResponse {
  message: string
}

const useAuthMessage = ({
  chain
}: {
  chain: Chain
}): ((address: string) => Promise<Web3MessageResponse>) => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (address: string): Promise<Web3MessageResponse> => {
      try {
        switch (chain) {
          case 'avalanche': {
            const response = await dexbarnClient.post<Web3MessageResponse>(
              `/v1/auth/web3-message`,
              { address }
            )
            return response.data
          }
          case 'solana': {
            const response = await solbarnClient.post<Web3MessageResponse>(
              `/auth/web3-message`,
              { address }
            )
            return response.data
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to authenticate'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [dexbarnClient, solbarnClient, addErrorPopup, chain]
  )
}

export default useAuthMessage
