import { Idl, Program } from '@coral-xyz/anchor'
import * as spl from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useMutation } from '@tanstack/react-query'
import { UseClaimCreatorFeesProps } from 'hooks/tokenmill/useClaimCreatorFees'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback } from 'react'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import { buildTransactionWithPriorityFee } from 'utils/transaction'

const useClaimCreatorFeesSolana = ({
  chain,
  marketAddress,
  onClaimSuccess,
  quoteTokenAddress
}: UseClaimCreatorFeesProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const addTransactionToast = useTransactionToast()
  const { maxTransactionFee } = useMaxTransactionFee()
  const { feeMode } = useFeeMode()

  const getClaimCreatorFeesTransaction = useCallback(async () => {
    if (!wallet.publicKey) throw new Error('Wallet not connected')

    const program = new Program(TokenMillIdl as Idl, {
      connection
    })
    const market = new PublicKey(marketAddress)
    const quoteTokenMint = new PublicKey(quoteTokenAddress)

    const marketQuoteTokenAta = spl.getAssociatedTokenAddressSync(
      quoteTokenMint,
      market,
      true,
      spl.TOKEN_PROGRAM_ID
    )

    const creatorQuoteTokenAta = spl.getAssociatedTokenAddressSync(
      quoteTokenMint,
      wallet.publicKey,
      true,
      spl.TOKEN_PROGRAM_ID
    )

    const instructions = []

    // Check if the market's ATA exists
    const marketAtaInfo = await connection.getAccountInfo(marketQuoteTokenAta)
    if (!marketAtaInfo) {
      const createAtaIx = spl.createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        marketQuoteTokenAta,
        market,
        quoteTokenMint,
        spl.TOKEN_PROGRAM_ID
      )
      instructions.push(createAtaIx)
    }

    // Check if the creator's ATA exists
    const creatorAtaInfo = await connection.getAccountInfo(creatorQuoteTokenAta)
    if (!creatorAtaInfo) {
      const createCreatorAtaIx = spl.createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        creatorQuoteTokenAta,
        wallet.publicKey,
        quoteTokenMint,
        spl.TOKEN_PROGRAM_ID
      )
      instructions.push(createCreatorAtaIx)
    }

    // claim ix
    const claimIx = await program.methods
      .claimCreatorFees()
      .accounts({
        creator: wallet.publicKey,
        creatorQuoteTokenAta,
        market,
        marketQuoteTokenAta,
        quoteTokenMint,
        quoteTokenProgram: spl.TOKEN_PROGRAM_ID
      })
      .instruction()

    instructions.push(claimIx)

    // Unwrap wSOL
    if (quoteTokenAddress === spl.NATIVE_MINT.toBase58()) {
      const closeAccountIx = spl.createCloseAccountInstruction(
        creatorQuoteTokenAta,
        wallet.publicKey,
        wallet.publicKey,
        [],
        spl.TOKEN_PROGRAM_ID
      )
      instructions.push(closeAccountIx)
    }

    return buildTransactionWithPriorityFee(
      connection,
      instructions,
      wallet.publicKey,
      maxTransactionFee,
      feeMode
    )
  }, [
    wallet.publicKey,
    marketAddress,
    quoteTokenAddress,
    connection,
    maxTransactionFee,
    feeMode
  ])

  const {
    isPending: isClaiming,
    mutateAsync: claimAsync,
    reset: resetClaim
  } = useMutation({
    mutationFn: async () => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')

      const { latestBlockhash, transaction } =
        await getClaimCreatorFeesTransaction()

      const signature = await wallet.sendTransaction(transaction, connection)

      addTransactionToast({
        chain,
        description: 'Claimed creator fees',
        hash: signature,
        walletAddress: wallet.publicKey.toBase58()
      })

      const result = await connection.confirmTransaction(
        {
          blockhash: latestBlockhash.blockhash,
          lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
          signature
        },
        'processed'
      )

      if (result.value.err) {
        throw new Error(result.value.err.toString())
      }

      return signature
    },
    onSuccess: onClaimSuccess
  })

  return {
    claimAsync,
    isClaiming,
    resetClaim
  }
}

export default useClaimCreatorFeesSolana
