export interface PricePoint {
  price: number
  supply: number
}

export type CurveType = 'quadratic' | 'exponential' | 'logarithmic' | 'power'

interface BaseCurveParams {
  curveType: CurveType
  numPoints: number
  Pmax: number
  Pmin: number
  xmax: number
}

interface QuadraticCurveParams extends BaseCurveParams {
  curveType: 'quadratic'
  normalizedC: number
}

interface ExponentialCurveParams extends BaseCurveParams {
  curveType: 'exponential'
}

interface LogarithmicCurveParams extends BaseCurveParams {
  curveType: 'logarithmic'
}

interface PowerCurveParams extends BaseCurveParams {
  alpha: number
  curveType: 'power'
}

type CurveParams =
  | QuadraticCurveParams
  | ExponentialCurveParams
  | LogarithmicCurveParams
  | PowerCurveParams

function normalizeExponentialParams(
  params: ExponentialCurveParams
): ExponentialCurveParams {
  return {
    ...params,
    Pmax: Math.max(params.Pmin, Math.min(10, params.Pmax)),
    Pmin: Math.max(0, Math.min(10, params.Pmin)),
    xmax: Math.max(1, Math.min(15, params.xmax))
  }
}

function normalizeLogarithmicParams(
  params: LogarithmicCurveParams
): LogarithmicCurveParams {
  return {
    ...params,
    Pmax: Math.max(params.Pmin, Math.min(10, params.Pmax)),
    Pmin: Math.max(0, Math.min(10, params.Pmin)),
    xmax: Math.max(1, Math.min(15, params.xmax))
  }
}

export function generatePricePoints(params: CurveParams): PricePoint[] {
  const { curveType, numPoints, Pmax, Pmin, xmax } = params
  const points: PricePoint[] = []
  const step = xmax / (numPoints - 1)

  for (let i = 0; i < numPoints; i++) {
    const x = i * step
    let price: number
    let supply: number

    switch (curveType) {
      case 'quadratic': {
        const { normalizedC } = params
        if (normalizedC < 0 || normalizedC > 10) {
          throw new Error('Normalized C must be between 0 and 10')
        }

        // Calculate the min and max values for C
        const minC = (xmax * Pmax + 2 * Pmin) / 3
        const maxC = (xmax * Pmax + Pmin) / 2

        // Scale normalizedC to the actual C value
        const C = minC + (normalizedC / 10) * (maxC - minC)

        const a = (3 / (xmax * xmax * xmax)) * (xmax * (Pmax + Pmin) - 2 * C)
        const b = (2 / (xmax * xmax)) * (3 * C - xmax * (Pmax + 2 * Pmin))
        price = a * x * x + b * x + Pmin
        supply = x
        break
      }

      case 'exponential': {
        const normalizedParams = normalizeExponentialParams(
          params as ExponentialCurveParams
        )
        const normalizedXmax = normalizedParams.xmax
        const normalizedPmin = normalizedParams.Pmin
        const normalizedPmax = normalizedParams.Pmax

        const normalizedX = (i / (numPoints - 1)) * normalizedXmax
        const exp_x = Math.exp(normalizedX)
        const exp_xmax = Math.exp(normalizedXmax)
        const normalizedPrice =
          ((exp_x - 1) / (exp_xmax - 1)) * (normalizedPmax - normalizedPmin) +
          normalizedPmin

        supply = (normalizedX / normalizedXmax) * xmax
        price =
          ((normalizedPrice - normalizedPmin) /
            (normalizedPmax - normalizedPmin)) *
            (Pmax - Pmin) +
          Pmin
        break
      }

      case 'logarithmic': {
        const normalizedParams = normalizeLogarithmicParams(
          params as LogarithmicCurveParams
        )
        const normalizedXmax = normalizedParams.xmax
        const normalizedPmin = normalizedParams.Pmin
        const normalizedPmax = normalizedParams.Pmax

        const normalizedX = (i / (numPoints - 1)) * normalizedXmax
        const normalizedPrice =
          (Math.log(1 + normalizedX) / Math.log(1 + normalizedXmax)) *
            (normalizedPmax - normalizedPmin) +
          normalizedPmin

        supply = (normalizedX / normalizedXmax) * xmax
        price =
          ((normalizedPrice - normalizedPmin) /
            (normalizedPmax - normalizedPmin)) *
            (Pmax - Pmin) +
          Pmin
        break
      }

      case 'power': {
        const { alpha } = params
        if (alpha < -5 || alpha > 5) {
          throw new Error('Alpha must be between -15 and 15')
        }

        // Updated power formula
        price =
          ((-1 + Math.exp((alpha * x) / xmax)) / (-1 + Math.exp(alpha))) *
            (Pmax - Pmin) +
          Pmin
        supply = x
        break
      }
    }

    points.push({
      price: parseFloat(Math.max(Pmin, Math.min(Pmax, price)).toPrecision(3)),
      supply
    })
  }

  return points
}

export const getIsTotalSupplyValid = ({
  decimals,
  nbIntervals,
  totalSupply
}: {
  decimals: number
  nbIntervals: number
  totalSupply: number
}): { isValid: boolean; error?: string } => {
  if (isNaN(decimals) || decimals <= 0 || decimals > 18) {
    return { isValid: true }
  }

  if (isNaN(nbIntervals) || nbIntervals < 1 || nbIntervals > 20) {
    return { isValid: true }
  }

  if (isNaN(totalSupply) || totalSupply === 0) {
    return {
      error: 'Total supply must be greater than 0',
      isValid: false
    }
  }

  const basePrecision = BigInt(10) ** BigInt(decimals)
  const scaledSupply = BigInt(totalSupply) * basePrecision

  if (scaledSupply / BigInt(nbIntervals) < basePrecision) {
    return {
      error: 'Total supply per interval is less than the base token precision',
      isValid: false
    }
  }

  if (scaledSupply > BigInt(2 ** 128 - 1)) {
    return {
      error: 'Total supply exceeds the maximum allowed value (2^128 - 1)',
      isValid: false
    }
  }

  if (
    (scaledSupply / BigInt(nbIntervals)) * BigInt(nbIntervals) !==
    scaledSupply
  ) {
    return {
      error: 'Total supply is not evenly divisible by the number of intervals',
      isValid: false
    }
  }

  return { isValid: true }
}

export const parsePricePoints = (value: string, xMax: number): PricePoint[] => {
  const regex = /^(\d+(\.\d+)?)(,\d+(\.\d+)?)*$/

  if (!regex.test(value)) {
    throw new Error(
      'Invalid input format. Expected format: any decimal number separated by a comma.'
    )
  }

  const pricePoints: PricePoint[] = []
  const values = value.split(',')
  const steps = values.length

  let previousPrice = -Infinity

  for (let i = 0; i < values.length; i++) {
    const supply = (xMax / (steps - 1)) * i
    const price = parseFloat(values[i])
    if (isNaN(price)) {
      throw new Error(`Invalid price at index ${i}`)
    }
    if (price <= previousPrice) {
      throw new Error(
        `Price points are not monotonically increasing at index ${i}`
      )
    }
    previousPrice = price
    pricePoints.push({ price, supply })
  }

  return pricePoints
}

export const fillDataWithWhitespace = (
  data: PricePoint[],
  activePrice: number | undefined,
  circulatingSupply: number,
  maxPoints: number = 1000
) => {
  if (data.length === 0 || activePrice === undefined) return data

  let sortedData = [...data].sort((a, b) => a.supply - b.supply)

  // Add active price point at circulatingSupply if it doesn't exist
  const activePriceIndex = sortedData.findIndex(
    (point) => point.price === activePrice
  )
  if (activePriceIndex === -1) {
    sortedData.push({ price: activePrice, supply: circulatingSupply })
    sortedData = sortedData.sort((a, b) => a.supply - b.supply)
  }

  const minSupply = sortedData[0].supply
  const maxSupply = sortedData[sortedData.length - 1].supply
  const supplyRange = maxSupply - minSupply

  // Find the active price point (now guaranteed to exist)
  const activePricePoint = sortedData.find(
    (point) => point.price === activePrice
  )!

  // Calculate the number of points on each side of the active price point
  const pointsBeforeActive = Math.floor(
    ((maxPoints - 3) * (activePricePoint.supply - minSupply)) / supplyRange
  )
  const pointsAfterActive = maxPoints - 3 - pointsBeforeActive

  // Calculate step sizes for before and after the active point
  const stepBefore =
    (activePricePoint.supply - minSupply) / (pointsBeforeActive + 1)
  const stepAfter =
    (maxSupply - activePricePoint.supply) / (pointsAfterActive + 1)

  const filledData: PricePoint[] = []

  // Add the first point
  filledData.push(sortedData[0])

  // Add points before the active price point
  for (let i = 1; i <= pointsBeforeActive; i++) {
    const supply = minSupply + i * stepBefore
    const price = interpolatePrice(sortedData, supply)
    filledData.push({ price, supply })
  }

  // Add the active price point
  if (filledData.findIndex((point) => point.price === activePrice) === -1) {
    filledData.push(activePricePoint)
  }

  // Add points after the active price point
  for (let i = 1; i <= pointsAfterActive; i++) {
    const supply = activePricePoint.supply + i * stepAfter
    const price = interpolatePrice(sortedData, supply)
    filledData.push({ price, supply })
  }

  // Add the last point
  if (filledData[filledData.length - 1].supply !== maxSupply) {
    filledData.push(sortedData[sortedData.length - 1])
  }

  return filledData
}

const interpolatePrice = (data: PricePoint[], supply: number): number => {
  const index = data.findIndex((point) => point.supply > supply)
  if (index === -1) return data[data.length - 1].price
  if (index === 0) return data[0].price

  const before = data[index - 1]
  const after = data[index]
  const ratio = (supply - before.supply) / (after.supply - before.supply)
  return before.price + ratio * (after.price - before.price)
}

export function calculateQuoteAmountToBuySupply(
  supply: number,
  askPricePoints: PricePoint[]
): number {
  if (askPricePoints.length === 0) {
    return 0
  }

  const totalSupply = askPricePoints[askPricePoints.length - 1].supply

  const n = askPricePoints.length - 1
  const w = totalSupply / n
  const m = Math.floor(supply / w)

  let totalQuoteAmount = 0

  // Calculate the integral part
  for (let i = 0; i < m; i++) {
    const avgPrice = (askPricePoints[i + 1].price + askPricePoints[i].price) / 2
    totalQuoteAmount += avgPrice * w
  }

  // Calculate the remaining part
  const r = supply - m * w
  if (r > 0) {
    const p_m = askPricePoints[m].price
    const p_m_plus_1 =
      m + 1 < askPricePoints.length ? askPricePoints[m + 1].price : p_m

    totalQuoteAmount +=
      ((p_m_plus_1 - p_m) / (2 * w)) * Math.pow(r, 2) + p_m * r
  }

  return totalQuoteAmount
}

export function getCurrentPrices(
  askPricePoints: PricePoint[],
  bidPricePoints: PricePoint[],
  circulatingSupply: number
): { askPrice: number; bidPrice: number } {
  if (askPricePoints.length === 0 || bidPricePoints.length === 0) {
    return { askPrice: 0, bidPrice: 0 }
  }

  const askPrice = interpolatePrice(askPricePoints, circulatingSupply)
  const bidPrice = interpolatePrice(bidPricePoints, circulatingSupply)

  return { askPrice, bidPrice }
}
