import { Box, Divider, Grid, Hide } from '@chakra-ui/react'
import useGetMarket from 'hooks/tokenmill/useGetMarket'
import useGetVestingData from 'hooks/tokenmill/useGetVestingData'
import TokenInfoSection from 'pages/TokenMillDetail/TokenInfoSection'
import TokenMillDetailHeader from 'pages/TokenMillDetail/TokenMillDetailHeader'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Chain } from 'types/dexbarn'
import { isSolanaAddress } from 'utils/addresses'
import { isAddress } from 'viem'

import VestedTokensTable from './VestedTokensTable'
import VestingAnalytics from './VestingAnalytics'

interface VestingProps {
  chain: Chain
  marketAddress: string
}

const Vesting = ({ chain, marketAddress }: VestingProps) => {
  const { data: market, isLoading: isLoadingMarket } = useGetMarket({
    chain,
    filterBy: '1d',
    marketAddress
  })

  const {
    data: vestingSchedules,
    isLoading: isLoadingVestingData,
    refetch: refetchVestingData
  } = useGetVestingData({
    baseTokenAddress: market?.baseToken.address,
    chain,
    marketAddress
  })

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <Grid
        templateColumns={{ base: '1fr', md: '2fr 1px 1fr' }}
        w="full"
        alignItems="flex-start"
      >
        <Box>
          <TokenMillDetailHeader
            marketAddress={marketAddress}
            chain={chain}
            market={market}
          />

          <Divider />

          <VestingAnalytics
            vestingSchedules={vestingSchedules}
            market={market}
          />
        </Box>

        <Hide below="md">
          <Divider orientation="vertical" />
        </Hide>

        <Hide above="md">
          <Divider orientation="horizontal" />
        </Hide>

        <Hide below="md">
          <TokenInfoSection market={market} />
        </Hide>
      </Grid>

      <Divider />

      <VestedTokensTable
        vestingSchedules={vestingSchedules}
        isLoading={isLoadingMarket || isLoadingVestingData}
        chain={chain}
        baseTokenAddress={market?.baseToken.address}
        marketAddress={marketAddress}
        marketCreatorAddress={market?.creator}
        onReleaseSuccess={() => {
          refetchVestingData()
        }}
      />

      <Divider />
    </Box>
  )
}

const VestingWithRedirect = () => {
  const { chain: chainParam, marketAddress } = useParams()

  if (
    !marketAddress ||
    (chainParam !== 'avalanche' && chainParam !== 'solana')
  ) {
    return <Navigate to="/404" />
  }

  const chain = chainParam as Chain

  switch (chain) {
    case 'avalanche':
      if (!isAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
    case 'solana':
      if (!isSolanaAddress(marketAddress)) {
        return <Navigate to="/404" />
      }
      break
  }

  return <Vesting marketAddress={marketAddress} chain={chain} />
}

export default VestingWithRedirect
