import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens } from 'types/dexbarn'
import { Chain } from 'types/dexbarn'
import { SolbarnAuthTokens } from 'types/solbarn'

const useLogin = ({
  chain
}: {
  chain: Chain
}): ((
  address: string,
  signature: string,
  message: string
) => Promise<AuthTokens>) => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async (
      address: string,
      signature: string,
      message: string
    ): Promise<AuthTokens> => {
      try {
        const body = {
          address,
          message,
          signature
        }

        switch (chain) {
          case 'avalanche': {
            const response = await dexbarnClient.post<AuthTokens>(
              `/v1/auth/login`,
              body
            )
            return response.data
          }
          case 'solana': {
            const response = await solbarnClient.post<SolbarnAuthTokens>(
              `/auth/login`,
              body
            )
            return {
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to authenticate'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [dexbarnClient, solbarnClient, addErrorPopup, chain]
  )
}

export default useLogin
