export const TokenMillFactoryAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'staking',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'wnative',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    inputs: [],
    name: 'STAKING',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'WNATIVE',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'acceptOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'quoteToken',
        type: 'address'
      }
    ],
    name: 'addQuoteToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'market',
        type: 'address'
      }
    ],
    name: 'claimFees',
    outputs: [
      {
        internalType: 'uint256',
        name: 'claimedFees',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      }
    ],
    name: 'claimProtocolFees',
    outputs: [
      {
        internalType: 'uint256',
        name: 'claimedFees',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      }
    ],
    name: 'claimReferrerFees',
    outputs: [
      {
        internalType: 'uint256',
        name: 'claimedFees',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'uint96',
            name: 'tokenType',
            type: 'uint96'
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string'
          },
          {
            internalType: 'address',
            name: 'quoteToken',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256'
          },
          {
            internalType: 'uint16',
            name: 'creatorShare',
            type: 'uint16'
          },
          {
            internalType: 'uint16',
            name: 'stakingShare',
            type: 'uint16'
          },
          {
            internalType: 'uint256[]',
            name: 'bidPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'uint256[]',
            name: 'askPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'bytes',
            name: 'args',
            type: 'bytes'
          }
        ],
        internalType: 'struct ITMFactory.MarketCreationParameters',
        name: 'parameters',
        type: 'tuple'
      }
    ],
    name: 'createMarketAndToken',
    outputs: [
      {
        internalType: 'address',
        name: 'baseToken',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'market',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'uint96',
            name: 'tokenType',
            type: 'uint96'
          },
          {
            internalType: 'string',
            name: 'name',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'symbol',
            type: 'string'
          },
          {
            internalType: 'address',
            name: 'quoteToken',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256'
          },
          {
            internalType: 'uint16',
            name: 'creatorShare',
            type: 'uint16'
          },
          {
            internalType: 'uint16',
            name: 'stakingShare',
            type: 'uint16'
          },
          {
            internalType: 'uint256[]',
            name: 'bidPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'uint256[]',
            name: 'askPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'bytes',
            name: 'args',
            type: 'bytes'
          }
        ],
        internalType: 'struct ITMFactory.MarketCreationParameters',
        name: 'params',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'beneficiary',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'percent',
            type: 'uint256'
          },
          {
            internalType: 'uint80',
            name: 'start',
            type: 'uint80'
          },
          {
            internalType: 'uint80',
            name: 'cliffDuration',
            type: 'uint80'
          },
          {
            internalType: 'uint80',
            name: 'endDuration',
            type: 'uint80'
          }
        ],
        internalType: 'struct ITMFactory.VestingParameters[]',
        name: 'vestingParams',
        type: 'tuple[]'
      },
      {
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountQuoteIn',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'minAmountBaseOut',
        type: 'uint256'
      }
    ],
    name: 'createMarketAndVestings',
    outputs: [
      {
        internalType: 'address',
        name: 'baseToken',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'market',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amountBaseOut',
        type: 'uint256'
      },
      {
        internalType: 'uint256[]',
        name: 'vestingIds',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creator',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256'
      }
    ],
    name: 'getCreatorMarketAt',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creator',
        type: 'address'
      }
    ],
    name: 'getCreatorMarketsLength',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'market',
        type: 'address'
      }
    ],
    name: 'getCreatorOf',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getDefaultProtocolShare',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'market',
        type: 'address'
      }
    ],
    name: 'getFeeSharesOf',
    outputs: [
      {
        internalType: 'uint256',
        name: 'protocolShare',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'creatorShare',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'stakingShare',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenA',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenB',
        type: 'address'
      }
    ],
    name: 'getMarket',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      },
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256'
      }
    ],
    name: 'getMarketAt',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      }
    ],
    name: 'getMarketOf',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getMarketsLength',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getProtocolFeeRecipient',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      }
    ],
    name: 'getProtocolFees',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getQuoteTokens',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      }
    ],
    name: 'getReferrerFeesOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getReferrerShare',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint96',
        name: 'tokenType',
        type: 'uint96'
      }
    ],
    name: 'getTokenImplementation',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      }
    ],
    name: 'getTokenType',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'protocolFees',
        type: 'uint256'
      }
    ],
    name: 'handleProtocolFees',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'protocolShare',
        type: 'uint16'
      },
      {
        internalType: 'uint16',
        name: 'referrerShare',
        type: 'uint16'
      },
      {
        internalType: 'address',
        name: 'protocolFeeRecipient',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'initialOwner',
        type: 'address'
      }
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'quoteToken',
        type: 'address'
      }
    ],
    name: 'isQuoteToken',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'pendingOwner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'quoteToken',
        type: 'address'
      }
    ],
    name: 'removeQuoteToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'market',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'creator',
        type: 'address'
      }
    ],
    name: 'updateCreatorOf',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'market',
        type: 'address'
      },
      {
        internalType: 'uint16',
        name: 'creatorShare',
        type: 'uint16'
      },
      {
        internalType: 'uint16',
        name: 'stakingShare',
        type: 'uint16'
      }
    ],
    name: 'updateFeeSharesOf',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'protocolFeeRecipient',
        type: 'address'
      }
    ],
    name: 'updateProtocolFeeRecipient',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'protocolShare',
        type: 'uint16'
      }
    ],
    name: 'updateProtocolShare',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'referrerShare',
        type: 'uint16'
      }
    ],
    name: 'updateReferrerShare',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint96',
        name: 'tokenType',
        type: 'uint96'
      },
      {
        internalType: 'address',
        name: 'implementation',
        type: 'address'
      }
    ],
    name: 'updateTokenImplementation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'market',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'protocolFees',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'referrerFees',
        type: 'uint256'
      }
    ],
    name: 'FeesReceived',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint64',
        name: 'version',
        type: 'uint64'
      }
    ],
    name: 'Initialized',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'quoteToken',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'creator',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'baseToken',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'market',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'totalSupply',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'symbol',
        type: 'string'
      },
      {
        indexed: false,
        internalType: 'uint8',
        name: 'decimals',
        type: 'uint8'
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'packedPrices',
        type: 'uint256[]'
      }
    ],
    name: 'MarketCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'market',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'creator',
        type: 'address'
      }
    ],
    name: 'MarketCreatorUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'market',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'protocolShare',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'creatorShare',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'stakingShare',
        type: 'uint256'
      }
    ],
    name: 'MarketFeeSharesUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferStarted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'recipient',
        type: 'address'
      }
    ],
    name: 'ProtocolFeeRecipientUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'claimedFees',
        type: 'uint256'
      }
    ],
    name: 'ProtocolFeesClaimed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'protocolShare',
        type: 'uint256'
      }
    ],
    name: 'ProtocolSharesUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'quoteToken',
        type: 'address'
      }
    ],
    name: 'QuoteTokenAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'quoteToken',
        type: 'address'
      }
    ],
    name: 'QuoteTokenRemoved',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'referrer',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'claimedFees',
        type: 'uint256'
      }
    ],
    name: 'ReferrerFeesClaimed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'referrerShare',
        type: 'uint256'
      }
    ],
    name: 'ReferrerShareUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint96',
        name: 'tokenType',
        type: 'uint96'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'implementation',
        type: 'address'
      }
    ],
    name: 'TokenImplementationUpdated',
    type: 'event'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'target',
        type: 'address'
      }
    ],
    name: 'AddressEmptyCode',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'AddressInsufficientBalance',
    type: 'error'
  },
  {
    inputs: [],
    name: 'ERC1167FailedCreateClone',
    type: 'error'
  },
  {
    inputs: [],
    name: 'FailedInnerCall',
    type: 'error'
  },
  {
    inputs: [],
    name: 'ImmutableCreate__DeploymentFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'ImmutableCreate__MaxLengthExceeded',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'i',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'bidPrice',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'askPrice',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__BidAskMismatch',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'baseDecimals',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'quoteDecimals',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__InvalidDecimals',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'lengthBid',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'lengthAsk',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__InvalidLength',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'totalSupply',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'nbIntervals',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__InvalidTotalSupply',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'widthScaled',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__InvalidWidthScaled',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'min',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'length',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'max',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__LengthOutOfBounds',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'i',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__OnlyIncreasingPrices',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'askPrice',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'maxPrice',
        type: 'uint256'
      }
    ],
    name: 'ImmutableHelper__PriceTooHigh',
    type: 'error'
  },
  {
    inputs: [],
    name: 'InvalidInitialization',
    type: 'error'
  },
  {
    inputs: [],
    name: 'NotInitializing',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address'
      }
    ],
    name: 'OwnableInvalidOwner',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'OwnableUnauthorizedAccount',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address'
      }
    ],
    name: 'SafeERC20FailedOperation',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__AddressZero',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__BalanceOfFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InsufficientOutputAmount',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidBalance',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidCaller',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidFeeShares',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidProtocolShare',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidQuoteToken',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidRecipient',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidReferrer',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidReferrerShare',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidTokenType',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidVestingPercents',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__InvalidVestingTotalPercents',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__MaxQuoteTokensExceeded',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__NoVestingParams',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__QuoteTokenAlreadyAdded',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__QuoteTokenNotFound',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__SameTokens',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__TooManyQuoteTokenSent',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__TransferFailed',
    type: 'error'
  },
  {
    inputs: [],
    name: 'TMFactory__ZeroFeeRecipients',
    type: 'error'
  }
] as const
