export const TokenMillLensAbi = [
  {
    inputs: [
      {
        internalType: 'contract ITMFactory',
        name: 'TMFactory',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'offset',
        type: 'uint256'
      }
    ],
    name: 'getAggregateMarketData',
    outputs: [
      {
        components: [
          {
            internalType: 'address[]',
            name: 'whitelistedQuoteTokens',
            type: 'address[]'
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'market',
                type: 'address'
              },
              {
                internalType: 'address',
                name: 'quoteToken',
                type: 'address'
              },
              {
                internalType: 'address',
                name: 'baseToken',
                type: 'address'
              }
            ],
            internalType: 'struct TMLens.MarketData[]',
            name: 'allMarketData',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct TMLens.AggregateMarketData',
        name: 'aggregateMarketData',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorAddress',
        type: 'address'
      }
    ],
    name: 'getCreatorData',
    outputs: [
      {
        components: [
          {
            internalType: 'address[]',
            name: 'creatorMarkets',
            type: 'address[]'
          },
          {
            internalType: 'uint256[]',
            name: 'creatorMarketPendingFees',
            type: 'uint256[]'
          }
        ],
        internalType: 'struct TMLens.CreatorData',
        name: 'creatorData',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'marketAddresses',
        type: 'address[]'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      }
    ],
    name: 'getMultipleDetailedMarketData',
    outputs: [
      {
        components: [
          {
            internalType: 'bool',
            name: 'marketExists',
            type: 'bool'
          },
          {
            internalType: 'address',
            name: 'quoteToken',
            type: 'address'
          },
          {
            internalType: 'address',
            name: 'baseToken',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'baseTokenType',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'quoteTokenDecimals',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'baseTokenDecimals',
            type: 'uint256'
          },
          {
            internalType: 'string',
            name: 'quoteTokenName',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'baseTokenName',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'quoteTokenSymbol',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'baseTokenSymbol',
            type: 'string'
          },
          {
            internalType: 'address',
            name: 'marketCreator',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'protocolShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'creatorShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'referrerShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'stakingShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'circulatingSupply',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'spotPriceFillBid',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'spotPriceFillAsk',
            type: 'uint256'
          },
          {
            internalType: 'uint256[]',
            name: 'askPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'uint256[]',
            name: 'bidPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'uint256',
            name: 'protocolPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'creatorPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'referrerPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'stakingPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalStaked',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalLocked',
            type: 'uint256'
          }
        ],
        internalType: 'struct TMLens.DetailedMarketData[]',
        name: 'detailedMarketData',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'userAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'offset',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'startUserVestings',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'offsetUserVestings',
        type: 'uint256'
      }
    ],
    name: 'getMultipleDetailedStakingDataPerUser',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'sharesAmount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'lockedSharesAmount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'pendingRewards',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'beneficiary',
                type: 'address'
              },
              {
                internalType: 'uint128',
                name: 'total',
                type: 'uint128'
              },
              {
                internalType: 'uint128',
                name: 'released',
                type: 'uint128'
              },
              {
                internalType: 'uint80',
                name: 'start',
                type: 'uint80'
              },
              {
                internalType: 'uint80',
                name: 'cliffDuration',
                type: 'uint80'
              },
              {
                internalType: 'uint80',
                name: 'vestingDuration',
                type: 'uint80'
              }
            ],
            internalType: 'struct ITMStaking.VestingSchedule[]',
            name: 'vestingSchedules',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct TMLens.SingleTokenUserStakingData[]',
        name: 'userStakingData',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'marketAddress',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      }
    ],
    name: 'getSingleDetailedMarketData',
    outputs: [
      {
        components: [
          {
            internalType: 'bool',
            name: 'marketExists',
            type: 'bool'
          },
          {
            internalType: 'address',
            name: 'quoteToken',
            type: 'address'
          },
          {
            internalType: 'address',
            name: 'baseToken',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'baseTokenType',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'quoteTokenDecimals',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'baseTokenDecimals',
            type: 'uint256'
          },
          {
            internalType: 'string',
            name: 'quoteTokenName',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'baseTokenName',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'quoteTokenSymbol',
            type: 'string'
          },
          {
            internalType: 'string',
            name: 'baseTokenSymbol',
            type: 'string'
          },
          {
            internalType: 'address',
            name: 'marketCreator',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'protocolShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'creatorShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'referrerShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'stakingShare',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalSupply',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'circulatingSupply',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'spotPriceFillBid',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'spotPriceFillAsk',
            type: 'uint256'
          },
          {
            internalType: 'uint256[]',
            name: 'askPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'uint256[]',
            name: 'bidPrices',
            type: 'uint256[]'
          },
          {
            internalType: 'uint256',
            name: 'protocolPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'creatorPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'referrerPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'stakingPendingFees',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalStaked',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalLocked',
            type: 'uint256'
          }
        ],
        internalType: 'struct TMLens.DetailedMarketData',
        name: 'detailedMarketData',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'userAddress',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'offset',
        type: 'uint256'
      }
    ],
    name: 'getSingleDetailedStakingDataPerUser',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'sharesAmount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'lockedSharesAmount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'pendingRewards',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'beneficiary',
                type: 'address'
              },
              {
                internalType: 'uint128',
                name: 'total',
                type: 'uint128'
              },
              {
                internalType: 'uint128',
                name: 'released',
                type: 'uint128'
              },
              {
                internalType: 'uint80',
                name: 'start',
                type: 'uint80'
              },
              {
                internalType: 'uint80',
                name: 'cliffDuration',
                type: 'uint80'
              },
              {
                internalType: 'uint80',
                name: 'vestingDuration',
                type: 'uint80'
              }
            ],
            internalType: 'struct ITMStaking.VestingSchedule[]',
            name: 'vestingSchedules',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct TMLens.SingleTokenUserStakingData',
        name: 'singleTokenUserStakingData',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address'
      }
    ],
    name: 'getSingleDetailedTokenStakingData',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'totalStaked',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'totalLocked',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'beneficiary',
                type: 'address'
              },
              {
                internalType: 'uint128',
                name: 'total',
                type: 'uint128'
              },
              {
                internalType: 'uint128',
                name: 'released',
                type: 'uint128'
              },
              {
                internalType: 'uint80',
                name: 'start',
                type: 'uint80'
              },
              {
                internalType: 'uint80',
                name: 'cliffDuration',
                type: 'uint80'
              },
              {
                internalType: 'uint80',
                name: 'vestingDuration',
                type: 'uint80'
              }
            ],
            internalType: 'struct ITMStaking.VestingSchedule[]',
            name: 'vestingSchedules',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct TMLens.DetailedTokenStakingData',
        name: 'detailedTokenStakingData',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
] as const
