import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import { AuthTokens, Chain } from 'types/dexbarn'

interface DeleteReferralCodeResponse {
  message: string
  user: string
}

interface DeleteReferralCodeVariables {
  authTokens: AuthTokens
}

const useDeleteReferralCode = ({
  chain
}: {
  chain: Chain
}): UseMutationResult<
  DeleteReferralCodeResponse,
  Error,
  DeleteReferralCodeVariables
> => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()
  const account = useAccountOnChain(chain)
  const addErrorPopup = useAddErrorPopup()

  return useMutation<
    DeleteReferralCodeResponse,
    Error,
    DeleteReferralCodeVariables
  >({
    mutationFn: async ({ authTokens }) => {
      if (!account.address) {
        throw new Error('Wallet not connected')
      }

      switch (chain) {
        case 'avalanche': {
          const response =
            await dexbarnClient.delete<DeleteReferralCodeResponse>(
              '/v1/users/referral-code',
              { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
            )
          return response.data
        }
        case 'solana': {
          const response =
            await solbarnClient.delete<DeleteReferralCodeResponse>(
              '/users/referral-code',
              { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
            )
          return response.data
        }
      }
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.detail) {
        addErrorPopup({
          subtitle: error.response?.data.detail,
          summary: 'Unable to delete referral code'
        })
      } else {
        addErrorPopup({
          subtitle: error.message,
          summary: 'Unable to delete referral code'
        })
      }
      console.error(error)
    }
  })
}

export default useDeleteReferralCode
