import { Box, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { Link } from 'react-router-dom'
import { MarketData } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface Props {
  market: MarketData
  onClick: () => void
  id?: string
}

const MobileMarketSearchResultRow = ({ id, market, onClick }: Props) => {
  return (
    <VStack
      id={id}
      as={Link}
      aria-label={`Link to ${market.baseToken.symbol}`}
      to={`/${market.chain}/${market.marketAddress}`}
      px={3}
      py={2}
      spacing={2}
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      borderRadius={0}
      onClick={onClick}
      align="flex-start"
      borderBottom="1px solid"
      borderColor="border"
    >
      <HStack justify="space-between" w="full" fontSize="sm">
        <CurrencyLogo
          address={market.baseToken.address}
          symbol={market.baseToken.symbol}
          chain={market.chain}
          logoURI={market.iconUrl}
          boxSize={{ base: 6, md: 8 }}
        />
        <Text fontWeight="bold">{market.baseToken.symbol}</Text>
        <Text textColor="textSecondary">{market.baseToken.name}</Text>

        <Spacer />

        <Text fontSize="sm">
          {formattedNum(market.baseToken.priceUsd, {
            places: 5,
            usd: true
          })}
        </Text>
        <Text
          fontSize="sm"
          textColor={market.baseTokenPctChange >= 0 ? 'green.400' : 'red.500'}
        >
          {formattedNum(market.baseTokenPctChange, { places: 2 })}%
        </Text>
      </HStack>

      <HStack fontSize="xs">
        <Text fontSize="sm">
          <Box as="span" textColor="textSecondary">
            MCAP
          </Box>{' '}
          {formattedNum(market.baseMarketCapUsd, {
            places: 2,
            usd: true
          })}
        </Text>

        <Text textColor="textSecondary">•</Text>

        <Text fontSize="sm">
          <Box as="span" textColor="textSecondary">
            VOL
          </Box>{' '}
          {formattedNum(market.volumeUsd, {
            places: 2,
            usd: true
          })}
        </Text>

        <Text textColor="textSecondary">•</Text>

        <Text fontSize="sm">
          <Box as="span" textColor="textSecondary">
            LIQ
          </Box>{' '}
          {formattedNum(market.quoteLiquidityUsd, { places: 2, usd: true })}
        </Text>
      </HStack>
    </VStack>
  )
}

export default MobileMarketSearchResultRow
