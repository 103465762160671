import useAddErrorPopup from 'hooks/useAddErrorPopup'
import { useDexbarnClient, useSolbarnClient } from 'hooks/useDexbarn'
import { useCallback } from 'react'
import { AuthTokens, Chain } from 'types/dexbarn'

import useAccountOnChain from './useAccountOnChain'

export const useCreateTokenLogoUploadUrl = ({ chain }: { chain: Chain }) => {
  const dexbarnClient = useDexbarnClient()
  const solbarnClient = useSolbarnClient()

  const { address: account } = useAccountOnChain(chain)
  const addErrorPopup = useAddErrorPopup()

  return useCallback(
    async ({
      authTokens,
      contentType
    }: {
      authTokens: AuthTokens
      contentType: string
    }) => {
      if (!account) {
        return { destinationUrl: '', fields: {}, presignedUploadUrl: '' }
      }

      try {
        switch (chain) {
          case 'avalanche': {
            const response = await dexbarnClient.post<{
              destinationUrl: string
              fields: { [key: string]: string }
              presignedUploadUrl: string
            }>(
              '/v1/markets/drafts/upload/posturl',
              { contentType },
              { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
            )
            const { data } = response

            return {
              destinationUrl: `https://${data.destinationUrl}`,
              fields: data.fields,
              presignedUploadUrl: data.presignedUploadUrl
            }
          }
          case 'solana': {
            const response = await solbarnClient.post<{
              destination_url: string
              fields: { [key: string]: string }
              presigned_upload_url: string
            }>(
              '/markets/drafts/upload/posturl',
              { content_type: contentType },
              { headers: { Authorization: `Bearer ${authTokens.accessToken}` } }
            )
            const { data } = response

            return {
              destinationUrl: `https://${data.destination_url}`,
              fields: data.fields,
              presignedUploadUrl: data.presigned_upload_url
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          addErrorPopup({
            subtitle: e.message,
            summary: 'Unable to create upload URL'
          })
        }
        console.error(e)
        return Promise.reject(e)
      }
    },
    [account, dexbarnClient, solbarnClient, addErrorPopup, chain]
  )
}
