import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { TMMarketQueryParam } from 'types/dexbarn'
import { SolbarnMarket, SolbarnMarketQueryParam } from 'types/solbarn'
import { convertToSolbarnFilterBy } from 'utils/solbarn'

export interface UseGetSolanaMarketsParams {
  excludeLowVolumeMarkets?: boolean
  filterBy?: TMMarketQueryParam.FilterBy
  isDesc?: boolean
  orderBy?: TMMarketQueryParam.OrderBy
  pageSize?: number
}

const useGetMarketsFromSolbarn = ({
  excludeLowVolumeMarkets,
  filterBy,
  isDesc,
  orderBy = 'new',
  pageSize = 20
}: UseGetSolanaMarketsParams = {}) => {
  const fetchMarkets = useSolbarnGet<SolbarnMarket[]>('/markets')

  const solbarnOrderBy: SolbarnMarketQueryParam.OrderBy = orderBy

  return useInfiniteQuery<SolbarnMarket[]>({
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchMarkets({
        params: {
          exclude_low_volume_markets: excludeLowVolumeMarkets,
          filter_by: filterBy ? convertToSolbarnFilterBy(filterBy) : null,
          is_desc: isDesc,
          order_by: solbarnOrderBy,
          page_num: pageParam,
          page_size: pageSize
        }
      })
      return data
    },
    queryKey: [
      'GetSolanaMarkets',
      pageSize,
      orderBy,
      filterBy,
      excludeLowVolumeMarkets,
      isDesc
    ]
  })
}

export default useGetMarketsFromSolbarn
