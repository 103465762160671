import { ChevronRightIcon } from '@chakra-ui/icons'
import { Flex, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'

import WalletMenuItem from './WalletMenuItem'

interface Props {
  href: string
  title: string
}

const WalletMenuLink = ({ href, title }: Props) => {
  return (
    <Flex w="full" justifyContent="flex-start" flexDir="column" rowGap={2}>
      <Link as={NavLink} to={href} style={{ textDecoration: 'none' }}>
        <WalletMenuItem>
          <Text fontWeight="semibold">{title}</Text>
          <ChevronRightIcon
            textColor="textSecondary"
            ml="auto"
            boxSize="20px"
          />
        </WalletMenuItem>
      </Link>
    </Flex>
  )
}

export default WalletMenuLink
