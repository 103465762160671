import { t } from '@lingui/macro'
import { TokenMillStakingAbi } from 'constants/abi/tokenMillStaking'
import { TM_STAKING } from 'constants/addresses'
import { UseClaimStakingRewardsProps } from 'hooks/tokenmill/useClaimStakingRewards'
import useTransactionToast from 'hooks/useTransactionToast'
import { getChainId } from 'utils/chains'
import { getAddress, isAddress } from 'viem'
import {
  useAccount,
  usePublicClient,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

const useClaimStakingRewards = ({
  baseTokenAddress,
  chain,
  onSuccess
}: UseClaimStakingRewardsProps) => {
  const chainId = getChainId(chain)
  const publicClient = usePublicClient({ chainId })
  const { address: account, chain: walletChain } = useAccount()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: TokenMillStakingAbi,
    address: TM_STAKING,
    args:
      isAddress(baseTokenAddress) && account
        ? [getAddress(baseTokenAddress), account]
        : undefined,
    chainId,
    functionName: 'claimRewards',
    query: {
      enabled:
        walletChain?.id === chainId && isAddress(baseTokenAddress) && !!account,
      gcTime: 0
    }
  })

  const { isPending, writeContractAsync } = useWriteContract({
    mutation: {
      onSuccess: async (hash) => {
        if (!publicClient) return

        const transactionSummary = t`Claimed staking rewards`

        addTransactionToast({
          chain,
          description: transactionSummary,
          hash,
          walletAddress: account || ''
        })

        const receipt = await publicClient.waitForTransactionReceipt({ hash })
        if (receipt.status === 'success') {
          onSuccess?.()
        }
      }
    }
  })

  const claimStakingRewardsAsync = config?.request
    ? async () => await writeContractAsync(config.request)
    : undefined

  return {
    claimStakingRewardsAsync,
    isClaimingStakingRewards: isPending
  }
}

export default useClaimStakingRewards
