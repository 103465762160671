import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Center,
  HStack,
  LinkBox,
  LinkOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import React from 'react'
import useGetTopHolders from 'solana/hooks/useGetTopHolders'
import { Chain } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import { getBlockExplorer } from 'utils/chains'
import { formattedNum } from 'utils/format'

interface TopHoldersProps {
  baseTokenAddress: string
  chain: Chain
  circulatingSupply: number
  creatorAddress: string
  marketAddress: string
  totalSupply: number
}

const TopHolders = ({
  baseTokenAddress,
  chain,
  circulatingSupply,
  creatorAddress,
  marketAddress,
  totalSupply
}: TopHoldersProps) => {
  // TODO (EVM): get top holders
  const { data: topHolders, isLoading: isLoadingTopHolders } = useGetTopHolders(
    {
      baseTokenAddress,
      marketAddress
    }
  )

  if (isLoadingTopHolders) {
    return (
      <Center p={4}>
        <Spinner />
      </Center>
    )
  }

  if (!topHolders?.length) {
    return (
      <Center p={4}>
        <Text fontSize="sm" textColor="textSecondary">
          No holder data available
        </Text>
      </Center>
    )
  }

  return (
    <Box
      px={{ base: 0, md: 2 }}
      pt={2}
      pb={4}
      w={{ base: '100vw', md: 'full' }}
    >
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th py={4}>Rank</Th>
              <Th py={4}>User</Th>
              <Th py={4} isNumeric>
                Balance
              </Th>
              <Th py={4} isNumeric>
                Staked
              </Th>
              <Th py={4} isNumeric>
                Vesting
              </Th>
              <Th py={4} isNumeric>
                % Circulating
              </Th>
              <Th py={4} isNumeric>
                % Total
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {topHolders.map((holder, index) => {
              const isCreator =
                creatorAddress.toLowerCase() ===
                holder.userAddress.toLowerCase()

              const circulatingPercent =
                ((holder.staked + holder.balance) / circulatingSupply) * 100
              const totalPercent = (holder.total / totalSupply) * 100

              return (
                <LinkBox
                  as={Tr}
                  key={holder.userAddress}
                  cursor="pointer"
                  transform="scale(1)"
                  role="group"
                  _hover={{ bg: 'hover' }}
                >
                  <Td>
                    <LinkOverlay
                      isExternal
                      aria-label="link to transaction on explorer"
                      href={
                        getBlockExplorer(holder.userAddress, chain, 'address')
                          .url
                      }
                    />
                    <Text>#{index + 1}</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Text>{shortenAddress(holder.userAddress)}</Text>
                      {isCreator ? (
                        <Badge colorScheme="yellow">DEV</Badge>
                      ) : null}
                    </HStack>
                  </Td>
                  <Td isNumeric>{formattedNum(holder.balance)}</Td>
                  <Td isNumeric>{formattedNum(holder.staked)}</Td>
                  <Td isNumeric>{formattedNum(holder.vesting)}</Td>
                  <Td isNumeric>
                    {holder.isMarket
                      ? '--'
                      : `${formattedNum(circulatingPercent, { places: 2 })}%`}
                  </Td>
                  <Td isNumeric>
                    {totalPercent < 0.01
                      ? '<0.01'
                      : formattedNum(totalPercent, { places: 2 })}
                    %
                  </Td>
                  <Td>
                    <ExternalLinkIcon mx="2px" />
                  </Td>
                </LinkBox>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TopHolders
