import 'theme/index.css'
import '@rainbow-me/rainbowkit/styles.css'
import '@solana/wallet-adapter-react-ui/styles.css'
import 'process/browser'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { QueryClientProvider } from '@tanstack/react-query'
import { Buffer } from 'buffer'
import AxiosInterceptor from 'components/AxiosInterceptor'
import { LanguageProvider } from 'components/LanguageProvider'
import RainbowKitProvider from 'components/RainbowKitProvider'
import { queryClient } from 'constants/queryClient'
import { wagmiConfig } from 'constants/wagmi'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { config, wallets } from 'solana/config'
import store from 'state/store'
import { chakraTheme } from 'theme/chakra'
import { initializeAnalytics } from 'utils/measure'
import { WagmiProvider } from 'wagmi'

import { App } from './App'
import reportWebVitals from './reportWebVitals'

initializeAnalytics()

// https://github.com/WalletConnect/walletconnect-monorepo/issues/748#issuecomment-1178160422
// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || Buffer

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <AxiosInterceptor>
          <Provider store={store}>
            <ChakraProvider
              theme={chakraTheme}
              toastOptions={{ defaultOptions: { position: 'top-right' } }}
            >
              <LanguageProvider>
                <ConnectionProvider endpoint={config.endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletModalProvider>
                      <RainbowKitProvider>
                        <App />
                      </RainbowKitProvider>
                    </WalletModalProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </LanguageProvider>
            </ChakraProvider>
          </Provider>
        </AxiosInterceptor>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
)

reportWebVitals()
