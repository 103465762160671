import { HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { WarningIcon } from 'theme/icons'

interface WarningProps {
  text: string
}

const Warning = ({ text }: WarningProps) => {
  return (
    <HStack py={2} px={4} bg="rgba(255, 0, 0, 0.1)" w="full" spacing={4}>
      <WarningIcon fill="candleRed" />
      <Text color="candleRed" fontSize="sm">
        {text}
      </Text>
    </HStack>
  )
}

export default Warning
