import { useSwitchChain as useWagmiSwitchChain } from 'wagmi'

import useErrorToast from '../useErrorToast'

const useSwitchChain = () => {
  const addErrorToast = useErrorToast()

  return useWagmiSwitchChain({
    mutation: {
      onError: (error) => {
        // ignore user cancel errors
        if ((error as any).code === 4001) return
        addErrorToast(
          error.message,
          'Please switch to the correct network from your wallet.',
          'switch_chain_error'
        )
      }
    }
  })
}

export default useSwitchChain
