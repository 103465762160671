import { useColorMode, useMediaQuery } from '@chakra-ui/react'
import { useLingui } from '@lingui/react'
import {
  darkTheme,
  lightTheme,
  RainbowKitProvider as RainbowKitProviderDefault,
  Theme
} from '@rainbow-me/rainbowkit'
import { RAINBOWKIT_LOCALE_MAP } from 'components/LanguageProvider'
import React from 'react'
import { fontStyle } from 'theme/fonts'

const joeDarkTheme: Theme = {
  ...darkTheme({ accentColor: '#fd6c3c', borderRadius: 'none' }),
  fonts: { body: fontStyle }
}

const joeLightTheme: Theme = {
  ...lightTheme({ accentColor: '#fd6c3c', borderRadius: 'none' }),
  fonts: { body: fontStyle }
}

interface RainbowKitProviderProps {
  children: React.ReactNode
}

const RainbowKitProvider = ({ children }: RainbowKitProviderProps) => {
  const { i18n } = useLingui()

  const [isLargerThan720] = useMediaQuery('(min-width: 720px)')
  const { colorMode } = useColorMode()

  return (
    <RainbowKitProviderDefault
      appInfo={{
        appName: 'Token Mill',
        learnMoreUrl:
          'https://support.traderjoexyz.com/en/collections/3691849-getting-setup#:~:text=a%20week%20ago-,Cryptocurrency%20Wallets,-Wallet%20Setup%3A%20Metamask'
      }}
      theme={colorMode === 'dark' ? joeDarkTheme : joeLightTheme}
      showRecentTransactions={isLargerThan720}
      modalSize="compact"
      locale={RAINBOWKIT_LOCALE_MAP[i18n.locale]}
    >
      {children}
    </RainbowKitProviderDefault>
  )
}

export default RainbowKitProvider
