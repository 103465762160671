import { Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'

const Privacy = () => {
  return (
    <Flex
      flexDir="column"
      gap={2}
      pt={{ base: 4, md: 10 }}
      maxW="960px"
      margin="0 auto"
      minH="calc(100vh - 80px)"
      px={4}
      pb={{ base: 8, md: 16 }}
    >
      <Heading
        pt={4}
        textAlign="center"
        size="xl"
        color="textPrimary"
        mb={{ base: 4, md: 8 }}
      >
        PRIVACY POLICY
      </Heading>

      <Heading pt={4} textAlign="center" size="md">
        Last updated: 4 November 2024
      </Heading>

      <Text fontSize="md">
        We recognise our responsibilities in relation to the collection,
        holding, processing, use and/or transfer of personal data. Your privacy
        is of utmost importance to us.
      </Text>

      <Text fontSize="md">
        This policy (this <Text as="b">Policy</Text>) outlines how we collect,
        use, store and disclose your personal data. Please take a moment to read
        about how we collect, use and/or disclose your personal data so that you
        know and understand the purposes for which we may collect, use and/or
        disclose your personal data. By accessing the website at [*] and any
        sub-domains (the <Text as="b">Website</Text>), you agree and consent to
        Joemart Ltd. (the <Text as="b">Company</Text>), its related
        corporations, business units and affiliates, as well as their respective
        representatives and/or agents (collectively referred to herein as{' '}
        <Text as="b">we, us</Text> or <Text as="b">our</Text>), collecting,
        using, disclosing and sharing amongst themselves the personal data, and
        to disclosing such personal data to relevant third party providers.
      </Text>

      <Text fontSize="md">
        This Policy supplements but does not supersede nor replace any other
        consent which you may have previously provided to us nor does it affect
        any rights that we may have at law in connection with the collection,
        use and/or disclosure of your personal data. We may from time to time
        update this Policy to ensure that this Policy is consistent with our
        future developments, industry trends and/or any changes in legal or
        regulatory requirements. Subject to your rights at law, the prevailing
        terms of this Policy shall apply. For the avoidance of doubt, this
        Policy forms part of the terms and conditions governing your
        relationship with us and should be read in conjunction with such terms
        and conditions.
      </Text>

      <Text fontSize="md">
        The security of your personal data is important to us. At each stage of
        data collection, use and disclosure, we have in place physical,
        electronic, administrative and procedural safeguards to protect the
        personal data stored with us. However, do note that no transmission of
        personal data over the internet can be guaranteed to be 100% secure –
        accordingly and despite our efforts, we cannot guarantee or warrant the
        security of any information you transmit to us, or to or from our online
        services. We shall not have any responsibility or liability for the
        security of information transmitted via the internet.
      </Text>

      <Text fontSize="md">
        This Policy describes how we may collect, use, disclose, process and
        manage your personal data, and applies to any individual&quot;s personal
        data which is in our possession or under our control.
      </Text>

      <Heading pt={4} size="md">
        What personal data is collected by us
      </Heading>
      <Text fontSize="md">
        &quot;Personal data&quot; means data, whether true or not, about an
        individual who can be identified (i) from that data, or (ii) from that
        data and other information to which the organisation has or is likely to
        have access. Some examples of personal data that we may collect are:
      </Text>
      <Text fontSize="md" as="div">
        <ul>
          <li>
            personal particulars (e.g. legal name, alias(es), nationality and
            domicile, gender, telephone number, residential address, date of
            birth, or identity card / passport / driver&quot;s licence details)
          </li>
          <li>
            e-mail address, account username, account password, social media or
            messaging platform handles and other social media or messaging
            platform profile information
          </li>
          <li>information or details regarding digital assets held</li>
          <li>
            particulars of digital wallet addresses (including transactions
            performed by said digital wallet addresses), public cryptographic
            key relating to digital wallet addresses on distributed ledger
            networks and/or similar information
          </li>
          <li>
            information about your use of our services and Website, and specific
            user interactions with the Website such as pages (and sub-page)
            visited, time and date of your visit, time spent on each page, and
            other diagnostic data, features utilised, areas visited or clicked
            on, and time spent
          </li>
          <li>
            usernames and password, third party account credentials (such as
            your Facebook login credentials, Google login credentials), Internet
            Protocol (IP) address, and geographical location
          </li>
          <li>
            browser type and version, operating system used by the accessing
            system, internet service provider of the accessing system, the
            website from which an accessing system reaches our website (i.e.
            &quot;referrers&quot;), mobile device ID, location of access, or
            other similar data and information
          </li>
        </ul>
      </Text>

      <Text fontSize="md">
        Personal data will be automatically collected when you interact with our
        services, access the Website, post on the community forum or social
        media or interact with other users of our services, sign up for any
        beta/testing services, open an email from us or fill in any form or
        survey, register a user account or update a user account that you
        registered with us, engage with us (whether through live chat, message,
        phone call, email, social media accounts, messaging platforms,
        attendance at in-person events, opting-in to receive our marketing
        messages, or subscribing to our mailing lists), or make a purchase from
        us.
      </Text>

      <Text fontSize="md">
        To the extent permitted by law, we may also obtain other information
        about you such as contact information, change of address or demographic
        information from commercially available sources.
      </Text>

      <Heading pt={4} size="md">
        Personal data and the Blockchain
      </Heading>
      <Text fontSize="md">
        Blockchain technology, also known as distributed ledger technology
        (DLT), is at the core of our business. Blockchains are decentralised and
        made up of digitally recorded data in a chain of packages called
        &quot;blocks&quot;. The manner in which these blocks are linked is
        chronological, meaning that the data is very difficult to alter once
        recorded. Since the ledger may be distributed all over the world (across
        several &quot;nodes&quot; which usually replicate the ledger) this means
        there is no single person making decisions or otherwise administering
        the system (such as an operator of a cloud computing system), and that
        there is no centralised place where it is located either.
      </Text>

      <Text fontSize="md">
        Accordingly, by design, a blockchain&quot;s data cannot be changed or
        deleted and is said to be &quot;immutable&quot;. This may affect your
        ability to exercise your rights such as your right to erasure
        (&quot;right to be forgotten&quot;), or your rights to object or
        restrict processing of your personal data. Data on the blockchain cannot
        be erased and cannot be changed. Although smart contracts may be used to
        revoke certain access rights, and some content may be made invisible to
        others, it is not deleted.
      </Text>

      <Text fontSize="md">
        In certain circumstances, in order to comply with our contractual
        obligations to you (such as delivery of tokens or provision of other
        services) it will be necessary to collect certain personal data, such as
        your wallet address, onto the blockchain; this is done through a smart
        contract and requires you to execute such transactions using your
        wallet&quot;s private key.
      </Text>

      <Text fontSize="md" fontWeight="bold">
        IF YOU WANT TO ENSURE YOUR PRIVACY RIGHTS ARE NOT AFFECTED IN ANY WAY,
        YOU SHOULD NOT TRANSACT ON BLOCKCHAINS AS CERTAIN RIGHTS MAY NOT BE
        FULLY AVAILABLE OR EXERCISABLE BY YOU OR US DUE TO THE TECHNOLOGICAL
        INFRASTRUCTURE OF THE BLOCKCHAIN. IN PARTICULAR THE BLOCKCHAIN IS
        AVAILABLE TO THE PUBLIC AND ANY PERSONAL DATA SHARED ON THE BLOCKCHAIN
        WILL BECOME PUBLICLY AVAILABLE.
      </Text>

      <Text fontSize="md">
        Specifically, information regarding your digital wallet addresses
        (including transactions performed by said digital wallet addresses),
        public cryptographic key relating to digital wallet addresses on
        distributed ledger networks and/or similar information which you utilise
        to access the Website will be linked to your user account. By accessing
        our services, you provide your consent to our services reading and/or
        accessing information from such digital wallet addresses in order to
        verify ownership of any digital assets or tokens associated with such
        address (whether fungible or non-fungible). Users will have the ability
        to &quot;add&quot; their digital wallet address to access our services,
        as well as the ability to &quot;remove&quot; such stored wallets. All
        data obtained by us in connection with your digital wallet addresses
        shall be treated as personal data and dealt with in accordance with the
        provisions of this Policy.
      </Text>

      <Heading pt={4} size="md">
        Purposes for collection, use and disclosure of your personal data
      </Heading>
      <Text fontSize="md">
        We may collect, use and/or disclose your personal data for its
        legitimate interests or business purposes, including operations for
        these purposes. These may include, without limitation, the following:
      </Text>
      <Text fontSize="md" as="div">
        <ul>
          <li>
            developing, providing and improving our products and services
            (whether made available by us or through us) or your participation
            in interactive features of our services, including without
            limitation:
            <ul>
              <li>
                services for purchasing, trading and/or holding of digital
                assets
              </li>
              <li>
                community support and user support, as well as to facilitate
                interactions between users (whether on our platform or outside
                our platform)
              </li>
              <li>
                acting as intermediaries through any blockchain, network or
                platform
              </li>
              <li>
                recording and/or encryption on any blockchain, network or
                platform
              </li>
              <li>
                promoting advertisements or marketing material, whether from us
                or third parties
              </li>
              <li>
                any escrow, courier, anti-counterfeiting, cyber-security or
                dispute resolution services
              </li>
              <li>
                research, planning, analytics, trouble-shooting, technical
                maintenance and bug fixes
              </li>
            </ul>
          </li>
          <li>
            communicating with you, including providing you with updates on
            changes to services or products (whether made available by us or
            through us) including any additions, expansions, suspensions and
            replacements of or to such services or products and their terms and
            conditions
          </li>
          <li>
            addressing, investigating or responding to any feedback, queries,
            complaints, claims or disputes in connection with the services or
            the Website
          </li>
          <li>
            complying with all applicable laws, regulations, rules, directives,
            orders, instructions, requests, demands, investigations or queries
            from any local or foreign authorities (including regulatory,
            governmental, tax and law enforcement authorities or other
            authorities) as well as law firms or other professionals
          </li>
        </ul>
      </Text>

      <Text fontSize="md">
        We may also use personal data for purposes set out in the terms and
        conditions that govern our relationship with you or our customer.
      </Text>

      <Heading pt={4} size="md">
        e-KYC
      </Heading>
      <Text fontSize="md">
        For certain product offerings, we may engage and authorise certain third
        party service providers of electronic know-your-client (eKYC) services
        for identity verification, processing of identity documentation,
        collection of due diligence documentation, and/or transaction
        monitoring.
      </Text>

      <Text fontSize="md">
        Under these arrangements, personal data may be provided to such eKYC
        service providers through the submission of information, forms,
        documents or media files (in whatever format) through an upload to
        online platforms operated by such eKYC service providers. The eKYC
        process may be automated, semi-automated or performed by a human.
      </Text>

      <Text fontSize="md">
        The result of the eKYC process as well as all personal data provided to
        eKYC service providers will be made available solely to us and will not
        be shared with any other external parties. All eKYC service providers
        shall be required to ensure that the eKYC solution is secure and robust
        to protect personal data from unauthorised access, use and disclosure at
        all times.
      </Text>

      <Heading pt={4} size="md">
        Use of personal data for marketing purposes
      </Heading>
      <Text fontSize="md">
        We may use your personal data to offer you products or services,
        including special offers, promotions, contests or entitlements that may
        be of interest to you or for which you may be eligible. Such marketing
        messages may be sent to you in various modes including but not limited
        to electronic mail, direct mailers, short message service, telephone
        calls, facsimile and other mobile messaging services or messaging
        applications (such as Telegram), and may be sent directly by us or by
        various third parties which we work with. In doing so, the sender will
        comply with all applicable data protection and privacy laws.
      </Text>

      <Text fontSize="md">
        In respect of sending telemarketing messages to your telephone number
        via short message service, telephone calls, facsimile and other mobile
        messaging services, please be assured that we shall only do so if we
        have your clear and unambiguous consent in writing or other recorded
        form to do so or if you have not otherwise made the appropriate
        registration of that number with the Do Not Call Registry. If we have an
        ongoing relationship with you and you have not indicated to us that you
        do not wish to receive telemarketing messages sent to your telephone
        number, we may send you telemarketing messages to that number related to
        the subject of our ongoing relationship via short message service,
        facsimile and other mobile messaging services (other than a voice or
        video call).
      </Text>

      <Text fontSize="md">
        You may at any time request that we stop contacting you for marketing
        purposes via selected or all modes.
      </Text>

      <Text fontSize="md">
        To find out more about how you can change the way we use your personal
        data for marketing purposes, please contact us.
      </Text>

      <Text fontSize="md">
        Nothing in this Policy shall vary or supersede the terms and conditions
        that govern our relationship with you.
      </Text>

      <Heading pt={4} size="md">
        Disclosure and sharing of personal data
      </Heading>
      <Text fontSize="md">
        We may from time to time and in compliance with all applicable laws on
        data privacy, disclose your personal data to any of our personnel,
        staff, employees, officers, group entities, or to third parties
        (including without limitation banks, financial institutions, credit card
        companies, credit bureaus and their respective service providers,
        companies providing services relating to insurance and/or reinsurance to
        us, and associations of insurance companies, agents, contractors or
        third party service providers who provide services to us such as
        telecommunications, information technology, payment, data processing,
        storage and archival, and our professional advisers such as our auditors
        and lawyers, and regulators and authorities), located in any
        jurisdiction, in order to carry out the purposes set out above
        (including without limitation the provision of our services, or as
        required by any law). Please be assured that when we disclose your
        personal data to such parties, we will disclose only the personal
        information that is necessary to deliver the service required, and will
        also require them to ensure that any personal data disclosed to them are
        kept confidential and secure.
      </Text>

      <Text fontSize="md">
        For more information about the third parties with whom we share your
        personal data, you may, where appropriate, wish to refer to the
        agreement(s) and/or terms and conditions that govern our relationship
        with you or our customer. You may also contact us for more information
        (please see section 11 below).
      </Text>

      <Text fontSize="md">
        We wish to emphasise that we do not sell personal data to any third
        parties without your explicit consent, and we shall remain fully
        compliant with any duty or obligation of confidentiality imposed on us
        under the applicable agreement(s) and/or terms and conditions that
        govern our relationship with you or our customer or any applicable law.
      </Text>

      <Text fontSize="md">
        You are responsible for ensuring that the personal data you provide to
        us is accurate, complete, and not misleading and that such personal data
        is kept up to date. You acknowledge that failure on your part to do so
        may result in our inability to provide you with the products and
        services you have requested. To update your personal data, please
        contact us (please see section 11 below for contact details). Where you
        provide us with personal data concerning individuals other than
        yourself, you are responsible for obtaining all legally required
        consents from the concerned individuals and you shall retain proof of
        such consent(s), such proof to be provided to us upon our request.
      </Text>

      <Text fontSize="md">
        We may transfer, store, process and/or deal with your personal data in
        any jurisdiction, and accordingly such personal data may be transferred
        to computers, servers or hardware located outside of your state,
        province, country or other governmental jurisdiction where the data
        protection laws may differ from those in your jurisdiction. We will take
        all steps reasonably necessary to ensure that your data is treated
        securely and in accordance with this Policy and no transfer of your
        personal data will take place to an organisation or a country unless
        there are adequate controls in place including the security of your data
        and other personal information (including without limitation the
        Standard Contractual Clauses approved by the European Commission). Your
        consent to this Policy followed by your submission of such information
        represents your agreement to the transfer of personal data as described
        herein.
      </Text>

      <Heading pt={4} size="md">
        Cookies and related technologies
      </Heading>
      <Text fontSize="md">
        The Website uses cookies. A cookie is a small text file placed on your
        computer or mobile device when you visit a Website or use an app, which
        may include an anonymous unique identifier. Cookies collect information
        about users and their visit to the Website or use of the app, such as
        their Internet protocol (IP) address, how they arrived at the Website
        (for example, through a search engine or a link from another Website),
        how they navigate within the Website or app, browser information,
        computer or device type, operating system, internet service provider,
        website usage, referring/exit pages, platform type, date/time stamp,
        number of clicks, ads viewed, and how they use our services. We use
        cookies and other technologies to facilitate your internet sessions and
        use of our apps, offer you customised products and/or services according
        to your preferred settings, display features and services which might be
        of interest to you (including ads on our services), track usage of our
        websites and apps, to compile statistics about activities carried out on
        our websites, and to hold certain information. Examples of cookies which
        we use include, without limitation, Sign-in and Authentication Cookies
        for user authentication, Session Cookies to operate our service,
        Preference Cookies to remember your preferences and various settings,
        Third-Party Cookies from third party services to receive and incorporate
        external data, as well as Security Cookies for security purposes.
      </Text>

      <Text fontSize="md">
        You may set up your web browser to block cookies from monitoring your
        website visit. You may also remove cookies stored from your computer or
        mobile device. However, if you do block cookies you may not be able to
        use certain features and functions of our Website.
      </Text>

      <Text fontSize="md">
        We further utilise a variety of other similar tracking technologies
        provided by parties such as Google Tag Manager, Google Analytics, which
        include technologies such as cookies, identifiers for mobile devices,
        beacons, tags, and scripts to collect and track information and to
        improve and analyse our services (including training of any artificial
        intelligence or machine learning models).
      </Text>

      <Text fontSize="md">
        Our systems do not fully recognise &quot;do not track&quot; signals or
        other mechanisms that might enable users to opt out of tracking on the
        Website. We provide the user the option to NOT allow cookies, which will
        disable all cookies except for essential cookies (e.g. cookies utilised
        directly for the provision of services or basic statistics tracking); if
        a user chooses to not allow cookies, they may not be able to use certain
        features of the Website or the services therein.
      </Text>

      <Heading pt={4} size="md">
        Other web sites
      </Heading>
      <Text fontSize="md">
        Our websites may contain links to other websites which are not
        maintained by us. This Policy only applies to websites maintained by us.
        When visiting these third party websites, you should read their privacy
        policies which will apply to your use of such websites.
      </Text>

      <Heading pt={4} size="md">
        Retention of personal data
      </Heading>
      <Text fontSize="md">
        Your personal data is retained as long as the purpose for which it was
        collected remains and until it is no longer necessary for any legal or
        business purposes. This enables us to comply with legal and regulatory
        requirements or use it where we need to for our legitimate purposes,
        such as transfers of digital assets, and dealing with any disputes or
        concerns that may arise.
      </Text>

      <Text fontSize="md">
        We may need to retain information for a longer period where we need the
        information to comply with regulatory or legal requirements or where we
        may need it for our legitimate purposes (e.g. to help us respond to
        queries or complaints, fighting fraud and financial crime, responding to
        requests from regulators etc).
      </Text>

      <Text fontSize="md">
        When we no longer need to use personal data, we will remove it from our
        systems and records and/or take steps to anonymise it so that you can no
        longer be identified from it.
      </Text>

      <Heading pt={4} size="md">
        Queries, Access/Correction Requests and Withdrawal of Consent
      </Heading>
      <Text fontSize="md">If you:</Text>
      <Text fontSize="md" as="div">
        <ul>
          <li>
            have queries about our data protection processes and practices;
          </li>
          <li>
            wish to request access to and/or make corrections to your personal
            data in our possession or under our control; or
          </li>
          <li>
            wish to withdraw your consent to our collection, use or disclosure
            of your personal data,
          </li>
        </ul>
      </Text>
      <Text fontSize="md">
        please submit a written request (with supporting documents, (if any) to
        our Data Protection Officer at: [privacy@]. Our Data Protection Officer
        shall respond to you within 30 days of your submission. Please note that
        if you withdraw your consent to any or all use or disclosure of your
        personal data, depending on the nature of your request, we may not be in
        a position to continue to provide our services or products to you or
        administer any contractual relationship in place. Such withdrawal may
        also result in the termination of any agreement you may have with us.
        Our legal rights and remedies are expressly reserved in such event.
      </Text>

      <Text fontSize="md">
        We may charge you a fee for processing your request for access. Such a
        fee depends on the nature and complexity of your access request.
        Information on the processing fee will be made available to you.
      </Text>

      <Heading pt={4} size="md">
        Contact information
      </Heading>
      <Text fontSize="md">
        To contact us on any aspect of this Policy or your personal data or to
        provide any feedback that you may have, please contact our Data
        Protection Officer at [privacy@].
      </Text>

      <Heading pt={4} size="md">
        Governing Law and Jurisdiction
      </Heading>
      <Text fontSize="md">
        This Policy and your use of the Website shall be governed and construed
        in accordance with the laws of Panama, without regard to its conflict of
        laws principles. All disputes arising out of or in connection with this
        Policy (including without limitation the enforceability of this Section
        12 or any question regarding its existence, validity or termination,
        your access or use of the services shall be settled by arbitration,
        following an attempt at Conciliation, administered by Panama
        Conciliation and Arbitration Centre in accordance with its procedural
        rules for the time being in force. The tribunal shall consist of 1
        arbitrator, who shall have exclusive authority to decide all issues
        relating to the interpretation, applicability, enforceability and scope
        of this Agreement (including this arbitration agreement). The language
        used in the arbitral proceedings shall be English. Each party
        irrevocably submits to the jurisdiction and venue of such tribunal.
        Judgment upon the award may be entered by any court having jurisdiction
        thereof or having jurisdiction over the relevant party or its assets.
      </Text>

      <Heading pt={4} size="md">
        Amendments and updates to Token Mill Privacy Policy
      </Heading>
      <Text fontSize="md">
        We reserve the right to amend this Policy from time to time to ensure
        that this Policy is consistent with any developments to the way we use
        your personal data or any changes to the laws and regulations applicable
        to us. We will make available the updated Policy on the Website. You are
        encouraged to visit the Website from time to time to ensure that you are
        well informed about our latest policies in relation to personal data
        protection. All communications, transactions and dealings with us shall
        be subject to the latest version of this Policy in force at the time.
      </Text>

      <Heading pt={4} size="md">
        For European Union or European Economic Area Residents
      </Heading>
      <Text fontSize="md">
        This section 14 applies if you are an individual located in the European
        Union or European Economic Area. Subject to applicable law, you have the
        following additional rights in relation to your personal data:
      </Text>
      <Text fontSize="md" as="div">
        <ul>
          <li>
            the right to access your personal data (if you ask us, we will
            confirm whether we are processing your personal data in a
            structured, commonly used and machine-readable format and, if so,
            provide you with a copy of that personal data (along with certain
            other details). If you require additional copies, we may need to
            charge a reasonable fee;
          </li>
          <li>the right to ensure the accuracy of your personal data;</li>
          <li>
            the right to have us delete your personal data (we will do so in
            some circumstances, such as where we no longer need it, but do note
            that we may not delete your data when other interests outweigh your
            right to deletion);
          </li>
          <li>
            the right to restrict further processing of your personal data
            (unless we demonstrate compelling legitimate grounds for the
            processing);
          </li>
          <li>
            rights in relation to automated decision-making and profiling (you
            have the right to be free from decisions based solely on automated
            processing of your personal data, including profiling, that affect
            you, unless such processing is necessary for entering into, or the
            performance of, a contract between you and us or you provide your
            explicit consent to such processing);
          </li>
          <li>
            the right to withdraw consent (if we rely on your consent to process
            your personal data, you have the right to withdraw that consent at
            any time, but provided always that this shall not affect the
            lawfulness of processing based on your prior consent); and
          </li>
          <li>
            the right to complain to a supervisory authority in your country of
            residence in the event that data is misused.
          </li>
        </ul>
      </Text>

      <Text fontSize="md">
        If you believe that our processing of your personal information
        infringes data protection laws, you have a legal right to lodge a
        complaint with a supervisory authority responsible for data protection.
        You may do so in the EU member state of your residence, your place of
        work or the place of the alleged infringement. You may exercise any of
        your rights in relation to your personal data by contacting our Data
        Protection Officer at: [privacy@], and we shall respond to you within 30
        days of your submission.
      </Text>

      <Heading pt={4} size="md">
        Your acceptance of these terms
      </Heading>
      <Text fontSize="md">
        This Policy applies in conjunction with any other notices, contractual
        clauses and consent clauses that apply in relation to the collection,
        use and disclosure of your personal data by us. We may revise this
        Policy from time to time without any prior notice. You may determine if
        any such revision has taken place by referring to the date on which this
        Policy was last updated.
      </Text>

      <Text fontSize="md">
        By using the Website and/or any services provided by us, you signify
        your acceptance of this Policy and terms of service. If you do not agree
        to this Policy or terms of service, please do not use the Website or any
        services provided by us. Your continued use of the Website following the
        posting of changes to this Policy will be deemed your acceptance of
        those changes.
      </Text>
    </Flex>
  )
}

export default Privacy
