import { useWallet as useAccountSolana } from '@solana/wallet-adapter-react'
import { Chain } from 'types/dexbarn'
import { useAccount as useAccountEVM } from 'wagmi'

const useAccountOnChain = (chain: Chain) => {
  const accountEVM = useAccountEVM()
  const accountSolana = useAccountSolana()

  switch (chain) {
    case 'avalanche':
      return {
        address: accountEVM.address,
        isConnected: accountEVM.isConnected
      }

    case 'solana':
      return {
        address: accountSolana.publicKey?.toBase58(),
        isConnected: accountSolana.connected
      }
  }
}

export default useAccountOnChain
