import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import DatePicker2 from 'components/ReactDatePicker'
import { VestingArgs } from 'hooks/tokenmill/useCreateMarketAndToken'
import useTokenBalance from 'hooks/useTokenBalance'
import React, { useEffect, useMemo, useState } from 'react'
import { Chain } from 'types/dexbarn'
import { Token } from 'types/token'
import {
  calculateQuoteAmountToBuySupply,
  PricePoint
} from 'utils/bondingCurves'
import { formattedNum } from 'utils/format'
import { parseUnits, zeroAddress } from 'viem'

// Add styles to head
const style = document.createElement('style')
style.innerHTML = `
#cliff-start-picker {
  z-index: 100 !important;
}
#vesting-end-picker {
  z-index: 100 !important;
}
`
document.head.appendChild(style)

interface InitialPurchaseInputProps {
  askPricePoints: PricePoint[]
  baseToken: string
  chain: Chain
  onVestingArgsChange: (vestingArgs?: VestingArgs) => void
  quoteToken: Token
  setIsVestingArgsValid: (isValid: boolean) => void
}

const InitialPurchaseInput: React.FC<InitialPurchaseInputProps> = ({
  askPricePoints,
  baseToken,
  chain,
  onVestingArgsChange,
  quoteToken,
  setIsVestingArgsValid
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [percentage, setPercentage] = useState<string>('')
  const [isPercentageValid, setIsPercentageValid] = useState<boolean>(true)
  const [vestingStartDate, setVestingStartDate] = useState<Date | null>(null)
  const [vestingEndDate, setVestingEndDate] = useState<Date | null>(null)
  const [error, setError] = useState<string>('')

  const { data: userQuoteTokenBalance } = useTokenBalance({
    chain,
    token: quoteToken.address !== zeroAddress ? quoteToken.address : 'native'
  })

  const totalSupply = useMemo(() => {
    return askPricePoints.length === 0
      ? 0
      : askPricePoints[askPricePoints.length - 1].supply
  }, [askPricePoints])

  const estimatedAmountOut = useMemo(() => {
    const percentageNum = Number(percentage)
    if (isNaN(percentageNum)) {
      return 0
    }

    if (percentageNum >= 100) {
      return totalSupply
    }

    return (percentageNum / 100) * totalSupply
  }, [percentage, totalSupply])

  const totalCost = useMemo(() => {
    const result = calculateQuoteAmountToBuySupply(
      estimatedAmountOut,
      askPricePoints
    )
    return result
  }, [askPricePoints, estimatedAmountOut])

  const hasSufficientBalance = useMemo(() => {
    if (!userQuoteTokenBalance || estimatedAmountOut <= 0) {
      return true
    }
    return totalCost <= Number(userQuoteTokenBalance.formatted)
  }, [totalCost, userQuoteTokenBalance, estimatedAmountOut])

  const handlePercentageChange = (value: string) => {
    setPercentage(value)

    const percentageNum = parseFloat(value)
    if (isNaN(percentageNum)) {
      setIsPercentageValid(true)
    } else if (percentageNum <= 0 || percentageNum > 25) {
      setIsPercentageValid(false)
    } else {
      setIsPercentageValid(true)
    }
  }

  const handleVestingStartDateChange = (date: Date | null) => {
    setVestingStartDate(date)
  }

  const handleVestingEndDateChange = (date: Date | null) => {
    setVestingEndDate(date)
  }

  const handleReset = () => {
    setPercentage('')
    setVestingStartDate(null)
    setVestingEndDate(null)
    setError('')
    setIsPercentageValid(true)
  }

  // recalculate vesting args when price points change
  useEffect(() => {
    const updateVestingArgs = (
      percentage: string,
      hasSufficientBalance: boolean,
      vestingStartDate: Date | null,
      vestingEndDate: Date | null,
      totalCost: number
    ) => {
      const percentageNum = Number(percentage)
      if (
        isNaN(percentageNum) ||
        percentageNum <= 0 ||
        percentageNum > 25 ||
        !vestingStartDate ||
        !vestingEndDate ||
        !hasSufficientBalance
      ) {
        onVestingArgsChange(undefined)
        setIsVestingArgsValid(false)
        return
      }

      const amount = (percentageNum / 100) * totalSupply

      const now = new Date()
      if (vestingStartDate <= now) {
        setIsVestingArgsValid(false)
        setError('Vesting start date must be in the future')
        return
      }

      if (vestingStartDate >= vestingEndDate) {
        setIsVestingArgsValid(false)
        setError('Vesting end date must be after the start date')
        return
      }

      const vestingArgs: VestingArgs = {
        baseAmount: parseUnits(amount.toString(), 6),
        cliffDuration: Math.floor(
          (vestingStartDate.getTime() - new Date().getTime()) / 1000
        ),
        percentageAmountBps: 1000,
        quoteAmount: parseUnits(
          totalCost.toFixed(quoteToken.decimals),
          quoteToken.decimals
        ),
        start: Math.floor(Date.now() / 1000),
        vestingDuration: Math.floor(
          (vestingEndDate.getTime() - vestingStartDate.getTime()) / 1000
        )
      }

      onVestingArgsChange(vestingArgs)
      setIsVestingArgsValid(true)
      setError('')
    }

    if (percentage && vestingStartDate && vestingEndDate) {
      const quoteTokenAmountIn = calculateQuoteAmountToBuySupply(
        (Number(percentage) / 100) * totalSupply,
        askPricePoints
      )

      const hasSufficientBalance = userQuoteTokenBalance
        ? quoteTokenAmountIn < Number(userQuoteTokenBalance.formatted)
        : true

      updateVestingArgs(
        percentage,
        hasSufficientBalance,
        vestingStartDate,
        vestingEndDate,
        quoteTokenAmountIn
      )
    } else {
      onVestingArgsChange(undefined)
      setIsVestingArgsValid(!isOpen)
    }
  }, [
    percentage,
    vestingStartDate,
    vestingEndDate,
    askPricePoints,
    totalSupply,
    userQuoteTokenBalance,
    onVestingArgsChange,
    quoteToken.decimals,
    setIsVestingArgsValid,
    isOpen
  ])

  const handleCliffStartChange = (months: number) => {
    const now = new Date()
    now.setMonth(now.getMonth() + months)
    setVestingStartDate(now)
  }

  const handleVestingEndChange = (months: number) => {
    const now = new Date()
    now.setMonth(now.getMonth() + months)
    setVestingEndDate(now)
  }

  return (
    <VStack p={{ base: 4, md: 6 }} spacing={4} align="stretch">
      <HStack spacing={4}>
        <Heading size="md">Initial Purchase</Heading>
        <Switch
          isChecked={isOpen}
          onChange={(e) => {
            const isChecked = e.target.checked
            setIsOpen(isChecked)
            handleReset()
          }}
        />
      </HStack>

      <Collapse in={isOpen}>
        <VStack align="stretch" p="1px" spacing={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
            <VStack align="flex-start">
              <Text fontSize="sm">Supply %</Text>
              <InputGroup>
                <NumericalInput
                  inputMode="decimal"
                  placeholder="0.0"
                  value={percentage}
                  onValueChange={handlePercentageChange}
                  isInvalid={!isPercentageValid}
                />
                <InputRightElement w="fit-content" pr={2}>
                  <HStack>
                    <Button
                      variant="boxShadowFlat"
                      bg="bgPrimary"
                      size="xs"
                      onClick={() => handlePercentageChange('1')}
                    >
                      1%
                    </Button>
                    <Button
                      variant="boxShadowFlat"
                      bg="bgPrimary"
                      size="xs"
                      onClick={() => handlePercentageChange('10')}
                    >
                      10%
                    </Button>
                  </HStack>
                </InputRightElement>
              </InputGroup>
              {!isPercentageValid && (
                <Text fontSize="sm" color="red.500">
                  The percentage must be between 0 and 25
                </Text>
              )}
            </VStack>

            <VStack align="flex-start">
              <Text fontSize="sm">Cost</Text>
              <InputGroup>
                <NumericalInput
                  isReadOnly
                  inputMode="decimal"
                  placeholder="0.0"
                  value={formattedNum(totalCost, {
                    allowSmallDecimals: true
                  })}
                  isInvalid={!hasSufficientBalance}
                  pr="60px"
                />
                <InputRightElement mr={4}>
                  {quoteToken.symbol}
                </InputRightElement>
              </InputGroup>
              {!hasSufficientBalance && userQuoteTokenBalance && (
                <Text fontSize="sm" color="red.500">
                  You only have{' '}
                  {formattedNum(userQuoteTokenBalance.formatted, { places: 5 })}{' '}
                  {quoteToken.symbol}
                </Text>
              )}
            </VStack>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
            <FormControl>
              <FormLabel fontSize="sm">Cliff Start</FormLabel>
              <DatePicker2
                datePickerProps={{
                  dateFormat: 'dd/MM/yyyy h:mm aa',
                  minDate: new Date(),
                  onChange: handleVestingStartDateChange,
                  popperProps: { strategy: 'fixed' },
                  portalId: 'cliff-start-picker',
                  selected: vestingStartDate,
                  showTimeSelect: true
                }}
                inputProps={{ isInvalid: !!error }}
              />
              <HStack mt={2} spacing={2}>
                <Button
                  variant="boxShadowFlat"
                  size="xs"
                  onClick={() => handleCliffStartChange(1)}
                >
                  1M
                </Button>
                <Button
                  variant="boxShadowFlat"
                  size="xs"
                  onClick={() => handleCliffStartChange(3)}
                >
                  3M
                </Button>
                <Button
                  variant="boxShadowFlat"
                  size="xs"
                  onClick={() => handleCliffStartChange(6)}
                >
                  6M
                </Button>
              </HStack>
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm">Vesting End</FormLabel>
              <DatePicker2
                datePickerProps={{
                  dateFormat: 'dd/MM/yyyy h:mm aa',
                  disabled: !vestingStartDate,
                  minDate: vestingStartDate || new Date(),
                  onChange: handleVestingEndDateChange,
                  popperProps: { strategy: 'fixed' },
                  portalId: 'vesting-end-picker',
                  selected: vestingEndDate,
                  showTimeSelect: true
                }}
                inputProps={{ isInvalid: !!error }}
              />
              <HStack mt={2} spacing={2}>
                <Button
                  variant="boxShadowFlat"
                  size="xs"
                  onClick={() => handleVestingEndChange(6)}
                  isDisabled={!vestingStartDate}
                >
                  6M
                </Button>
                <Button
                  variant="boxShadowFlat"
                  size="xs"
                  onClick={() => handleVestingEndChange(12)}
                  isDisabled={!vestingStartDate}
                >
                  12M
                </Button>
                <Button
                  variant="boxShadowFlat"
                  size="xs"
                  onClick={() => handleVestingEndChange(24)}
                  isDisabled={!vestingStartDate}
                >
                  24M
                </Button>
              </HStack>
            </FormControl>
          </SimpleGrid>

          {error && (
            <Text fontSize="sm" textColor="red.500">
              {error}
            </Text>
          )}

          {percentage && (
            <Text fontSize="sm">
              You will receive{' '}
              {formattedNum(estimatedAmountOut, { allowSmallDecimals: true })}{' '}
              {baseToken || 'tokens'} vested over the selected period.
            </Text>
          )}

          <Text fontSize="sm">
            Automatically make the first buy order, guaranteed. The tokens will
            be vested over time, during which they will be staked and earning
            fees from trading activity.
          </Text>
        </VStack>
      </Collapse>
    </VStack>
  )
}

export default InitialPurchaseInput
