import { HStack, Link, Spacer, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'

const CreateTokenHeader: React.FC = () => {
  return (
    <VStack w="full" spacing={2} p={{ base: 4, md: 6 }} align="flex-start">
      <HStack spacing={3} fontSize="sm" w="full">
        <Link as={NavLink} to="/" textColor="textSecondary">
          {`<- Tokens`}
        </Link>

        <Text textColor="textSecondary">/</Text>

        <Link as={NavLink} to="/new">
          Create New Token
        </Link>

        <Spacer />
      </HStack>
    </VStack>
  )
}

export default CreateTokenHeader
