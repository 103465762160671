import { inputAnatomy } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  Input,
  NumberInput
} from '@chakra-ui/react'

const defaultProps = {
  _placeholder: { textColor: 'textSecondary' },
  bg: 'bgSecondary',
  border: 0,
  borderRadius: 0,
  errorBorderColor: 'candleRed',
  focusBorderColor: 'accent.500'
}

Input.defaultProps = {
  ...Input.defaultProps,
  ...defaultProps
}

NumberInput.defaultProps = {
  ...NumberInput.defaultProps,
  ...defaultProps
}

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
)

export const inputTheme = defineMultiStyleConfig({})
