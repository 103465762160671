import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  borderRadius: 0
})

const boxShadowFlat = defineStyle((props) => ({
  _disabled: {
    _hover: {
      bg: `${props.bg} !important`,
      opacity: 0.4
    }
  },
  _hover: { opacity: 0.8 },
  bg: props.colorMode === 'dark' ? 'charcoal' : 'blueGray',
  borderRadius: 'none',
  color: 'textPrimary',
  fontSize: '14px',
  px: 2,
  py: 1,
  transition: 'opacity 0.2s'
}))

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: { boxShadowFlat }
})
