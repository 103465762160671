import {
  Account,
  getAccount,
  getAssociatedTokenAddress,
  getMint,
  Mint,
  TOKEN_2022_PROGRAM_ID,
  TOKEN_PROGRAM_ID
} from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Connection, PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { UseTokenBalanceProps } from 'hooks/useTokenBalance'
import { TokenBalance } from 'types/token'
import { formatUnits } from 'viem'

async function getTokenBalance(
  connection: Connection,
  walletAddress?: string,
  tokenAddress?: string
): Promise<TokenBalance> {
  if (!tokenAddress || tokenAddress === 'native') {
    throw new Error('Token address is required')
  }

  if (!walletAddress) {
    throw new Error('Wallet address is required')
  }

  try {
    const walletPublicKey = new PublicKey(walletAddress)
    const tokenPublicKey = new PublicKey(tokenAddress)

    // Try TOKEN_2022_PROGRAM_ID first, then fall back to TOKEN_PROGRAM_ID
    const programIds = [TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID]

    for (const programId of programIds) {
      try {
        // Fetch the token's mint info to get the decimals
        const mintInfo: Mint = await getMint(
          connection,
          tokenPublicKey,
          'confirmed',
          programId
        )

        // Get the associated token address
        const associatedTokenAddress = await getAssociatedTokenAddress(
          tokenPublicKey,
          walletPublicKey,
          false,
          programId
        )

        // Fetch the token account
        let tokenAccount: Account
        try {
          tokenAccount = await getAccount(
            connection,
            associatedTokenAddress,
            'confirmed',
            programId
          )

          return {
            decimals: mintInfo.decimals,
            formatted: formatUnits(tokenAccount.amount, mintInfo.decimals),
            value: tokenAccount.amount
          }
        } catch (error: any) {
          // If the account doesn't exist, continue to the next program ID
          if (error.name === 'TokenAccountNotFoundError') {
            continue
          }
          throw error
        }
      } catch (error) {
        // If there's an error with this program ID, try the next one
        if (programId === TOKEN_2022_PROGRAM_ID) {
          continue
        }
        throw error
      }
    }

    // If we've tried all program IDs and haven't returned or thrown an error,
    // assume the balance is 0
    return { decimals: 0, formatted: '0', value: BigInt(0) }
  } catch (error) {
    console.error('Error fetching token balance:', error)
    throw error
  }
}

const useTokenBalance = ({ chain, enabled, token }: UseTokenBalanceProps) => {
  const { connection } = useConnection()
  const { publicKey } = useWallet()

  const walletAddress = publicKey?.toString()

  return useQuery<TokenBalance, Error>({
    enabled:
      enabled &&
      !!connection &&
      !!walletAddress &&
      !!token &&
      token !== 'native' &&
      chain === 'solana',
    queryFn: () => getTokenBalance(connection, walletAddress, token),
    queryKey: ['solanaTokenBalance', walletAddress, token, chain]
  })
}

export default useTokenBalance
