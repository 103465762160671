import { TokenMillLensAbi } from 'constants/abi/tokenMillLens'
import { TokenMillStakingAbi } from 'constants/abi/tokenMillStaking'
import { TM_LENS, TM_STAKING } from 'constants/addresses'
import { UseGetVestingDataProps } from 'hooks/tokenmill/useGetVestingData'
import { useMemo } from 'react'
import { VestingData } from 'types/market'
import { getChainId } from 'utils/chains'
import { formatUnits, getAddress, isAddress } from 'viem'
import { useReadContract, useReadContracts } from 'wagmi'

const useGetVestingData = ({
  baseTokenAddress,
  chain
}: UseGetVestingDataProps) => {
  const chainId = getChainId(chain)

  const {
    data: vestingData,
    isLoading: isLoadingVestingData,
    refetch: refetchVestingData
  } = useReadContract({
    abi: TokenMillLensAbi,
    address: TM_LENS,
    args:
      baseTokenAddress && isAddress(baseTokenAddress)
        ? [getAddress(baseTokenAddress)]
        : undefined,
    chainId,
    functionName: 'getSingleDetailedTokenStakingData',
    query: {
      enabled: !!baseTokenAddress
    }
  })

  const { data: releasableAmounts, isLoading: isLoadingReleasableAmounts } =
    useReadContracts({
      contracts:
        vestingData?.vestingSchedules.map(
          (_, index) =>
            ({
              abi: TokenMillStakingAbi,
              address: getAddress(TM_STAKING),
              args: baseTokenAddress
                ? [getAddress(baseTokenAddress), BigInt(index)]
                : undefined,
              chainId,
              functionName: 'getReleasableAmount'
            }) as const
        ) || []
    })

  const data: VestingData | undefined = useMemo(() => {
    if (!vestingData || !releasableAmounts) return undefined

    return {
      totalLocked: {
        formatted: formatUnits(vestingData.totalLocked, 18),
        value: vestingData.totalLocked
      },
      totalStaked: {
        formatted: formatUnits(vestingData.totalStaked, 18),
        value: vestingData.totalStaked
      },
      vestingSchedules: vestingData.vestingSchedules.map((schedule, i) => ({
        beneficiary: schedule.beneficiary,
        cliffDuration: Number(schedule.cliffDuration),
        index: i,
        releasable: {
          formatted: formatUnits(
            releasableAmounts?.[i].result || BigInt(0),
            18
          ),
          value: releasableAmounts[i].result || BigInt(0)
        },
        released: {
          formatted: formatUnits(schedule.released, 18),
          value: schedule.released
        },
        start: Number(schedule.start),
        total: {
          formatted: formatUnits(schedule.total, 18),
          value: schedule.total
        },
        vestingDuration: Number(schedule.vestingDuration),
        vestingPlanId: i.toString()
      }))
    } satisfies VestingData
  }, [vestingData, releasableAmounts])

  return {
    data,
    isLoading: isLoadingVestingData || isLoadingReleasableAmounts,
    refetch: refetchVestingData
  }
}

export default useGetVestingData
