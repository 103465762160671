import { getAddress, Hex } from 'viem'

const encodeIdTo4Bytes = (v: number, sv: number, t: number): string => {
  if (v > 255 || sv > 255 || t > 65535) {
    throw new Error('Invalid ID components')
  }
  const id = (v << 24) | (sv << 16) | t
  const idHex = id.toString(16).padStart(8, '0')
  return idHex
}

export const PackedRoute = {
  encode: (tokens: string[], ids: [number, number, number][]): Hex => {
    let encodedRoute = '0x'
    for (let i = 0; i < tokens.length; i++) {
      encodedRoute += getAddress(tokens[i]).slice(2)

      if (i < ids.length) {
        const idHex = encodeIdTo4Bytes(ids[0][0], ids[0][1], ids[0][2])
        encodedRoute += idHex
      }
    }
    return encodedRoute as Hex
  }
}
