import {
  Center,
  Image,
  InputGroup,
  InputRightElement,
  Skeleton,
  TabPanel,
  Text,
  VStack
} from '@chakra-ui/react'
import ApproveTokenButton from 'components/ApproveTokenButton'
import InputBalanceButton from 'components/InputBalanceButton'
import NumericalInput from 'components/NumericalInput'
import Web3Button from 'components/Web3Button'
import { TM_STAKING } from 'constants/addresses'
import useApproveSpenderIfNeeded from 'hooks/evm/useApproveSpenderIfNeeded'
import useStakeTokens from 'hooks/tokenmill/useStakeTokens'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useTokenBalance from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useMemo } from 'react'
import { Chain } from 'types/dexbarn'
import { Token } from 'types/token'
import { formattedNum } from 'utils/format'

interface StakePanelProps {
  baseToken: Token
  chain: Chain
  marketAddress: string
  onStakeSuccess: () => void
  baseTokenLogoUrl?: string
}

const StakePanel = ({
  baseToken,
  baseTokenLogoUrl,
  chain,
  marketAddress,
  onStakeSuccess
}: StakePanelProps) => {
  const currency = baseToken

  const {
    amount: amountIn,
    amountBN: amountInBN,
    setAmount: setAmountIn
  } = useCurrencyInputAmount({
    currency
  })

  const {
    data: baseTokenBalance,
    isLoading: isLoadingBaseTokenBalance,
    refetch: refetchBaseTokenBalance
  } = useTokenBalance({
    chain,
    token: baseToken.address
  })
  const debouncedRefetchBaseTokenBalance = debounce(
    refetchBaseTokenBalance,
    1000
  )

  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    isLoadingAllowance,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountInBN,
    chain,
    spender: TM_STAKING,
    token: baseToken.address,
    tokenSymbol: baseToken.symbol
  })

  const { isStaking, stakeAsync } = useStakeTokens({
    amount: amountInBN,
    baseTokenAddress: baseToken.address,
    baseTokenSymbol: baseToken.symbol,
    chain,
    enabled: isApproved || chain === 'solana',
    marketAddress,
    onStakeSuccess
  })

  const amountInErrorMsg = useMemo(() => {
    if (!amountInBN) return ''

    if (!baseTokenBalance) return ''
    if (amountInBN > baseTokenBalance.value) {
      return `Insufficient balance: ${formattedNum(
        baseTokenBalance.formatted
      )} ${baseToken.symbol}`
    }
    return ''
  }, [amountInBN, baseTokenBalance, baseToken.symbol])

  const handleStake = async () => {
    await stakeAsync?.()

    setAmountIn('')
    debouncedRefetchBaseTokenBalance()
    resetApproval()
  }

  const handleSetMax = () => {
    if (baseTokenBalance) {
      setAmountIn(baseTokenBalance.formatted)
    }
  }

  return (
    <TabPanel px={0} py={0}>
      <VStack spacing={2}>
        <Skeleton isLoaded={!isLoadingBaseTokenBalance} alignSelf="flex-end">
          <InputBalanceButton
            balance={baseTokenBalance?.formatted ?? '0'}
            onClick={handleSetMax}
          />
        </Skeleton>
        <InputGroup>
          <NumericalInput
            placeholder="0"
            inputType="decimal"
            w="full"
            size="lg"
            value={amountIn}
            onValueChange={setAmountIn}
            isInvalid={!!amountInErrorMsg}
          />
          <InputRightElement w="fit-content" h="full" pr={4}>
            <Image src={baseTokenLogoUrl} boxSize={6} objectFit="contain" />
          </InputRightElement>
        </InputGroup>
      </VStack>

      <VStack mt={8} spacing={4}>
        {!isApproved && approve && !amountInErrorMsg ? (
          <ApproveTokenButton
            amount={amountIn}
            approvalType={approvalType}
            onApprovalTypeSelect={setApprovalType}
            currencySymbol={baseToken.symbol}
            onClick={approve}
            isLoading={isLoadingAllowance || isApproving}
          />
        ) : null}

        <Web3Button
          chain={chain}
          size="lg"
          variant="boxShadowFlat"
          w="full"
          bg="accent.500"
          textColor="white"
          isDisabled={!amountIn || !!amountInErrorMsg || !stakeAsync}
          onClick={handleStake}
          isLoading={isStaking}
          loadingText="Staking"
        >
          {'[STAKE]'}
        </Web3Button>
      </VStack>

      {amountInErrorMsg ? (
        <Center mt={4}>
          <Text textColor="red.500" fontSize="sm">
            {amountInErrorMsg}
          </Text>
        </Center>
      ) : null}
    </TabPanel>
  )
}

export default StakePanel
