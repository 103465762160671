import { useQuery } from '@tanstack/react-query'
import { Chain } from 'types/dexbarn'
import { getChainId } from 'utils/chains'
import { TransactionReceipt } from 'viem'
import { waitForTransactionReceipt } from 'viem/actions'
import { usePublicClient } from 'wagmi'

const useWaitForTransactionReceipt = ({
  chain,
  hash,
  onTransactionSuccess
}: {
  chain: Chain
  hash?: `0x${string}`
  onTransactionSuccess?: (receipt: TransactionReceipt) => void
}) => {
  const chainId = getChainId(chain)
  const publicClient = usePublicClient({ chainId })

  return useQuery({
    enabled: !!hash && !!publicClient,
    queryFn: async () => {
      if (!hash || !publicClient) return
      const receipt = await waitForTransactionReceipt(publicClient, {
        hash
      })
      if (receipt.status === 'success') {
        onTransactionSuccess?.(receipt)
      }
      return receipt
    },
    queryKey: ['WaitForTransactionReceiptQuery', hash, chainId]
  })
}

export default useWaitForTransactionReceipt
