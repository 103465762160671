import { useInfiniteQuery } from '@tanstack/react-query'
import { useSolbarnGet } from 'hooks/useDexbarn'
import { VestingSchedule } from 'types/market'
import { SolbarnUserVestingPosition } from 'types/solbarn'
import { parseUnits } from 'viem'

export interface UseGetMarketVestingsFromSolbarnProps {
  enabled: boolean
  marketAddress?: string
  pageSize?: number
}

const useGetMarketVestingsFromSolbarn = ({
  enabled,
  marketAddress,
  pageSize = 20
}: UseGetMarketVestingsFromSolbarnProps) => {
  const fetchUserStakingPositions = useSolbarnGet<SolbarnUserVestingPosition[]>(
    `/vesting/market/${marketAddress}`
  )

  return useInfiniteQuery<VestingSchedule[]>({
    enabled: enabled && !!marketAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUserStakingPositions({
        params: {
          market_id: marketAddress,
          page_num: pageParam,
          page_size: pageSize
        }
      })

      return data.map((position, index): VestingSchedule => {
        const currentTime = Math.floor(Date.now() / 1000)
        const elapsedTime = Math.max(0, currentTime - position.vesting_start)

        let releasableAmount = 0
        if (elapsedTime > position.cliff_duration) {
          const vestedTime = Math.min(
            elapsedTime - position.cliff_duration,
            position.vesting_duration
          )
          const vestedPercentage = vestedTime / position.vesting_duration
          const totalVestedAmount = position.vesting_amount * vestedPercentage
          releasableAmount = Math.max(
            totalVestedAmount - position.amount_released,
            0
          )
        }

        return {
          beneficiary: position.user,
          cliffDuration: position.cliff_duration,
          index,
          releasable: {
            formatted: releasableAmount.toString(),
            value: parseUnits(releasableAmount.toString(), 6)
          },
          released: {
            formatted: position.amount_released.toString(),
            value: parseUnits(position.amount_released.toString(), 6)
          },
          start: position.vesting_start,
          total: {
            formatted: position.vesting_amount.toString(),
            value: parseUnits(position.vesting_amount.toString(), 6)
          },
          vestingDuration: position.vesting_duration,
          vestingPlanId: position.id
        }
      })
    },
    queryKey: ['GetMarketVestingsFromSolbarn', pageSize, marketAddress]
  })
}

export default useGetMarketVestingsFromSolbarn
