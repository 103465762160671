import { Box, Button, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import ConnectButton from 'components/ConnectButton'
import MobileNavigationMenu from 'components/MobileNavigationMenu'
import MobileSearchPanel from 'components/MobileSearchPanel'
import React from 'react'

const MobileBottomTabBar = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Box
        pos="fixed"
        zIndex={100}
        bottom={0}
        bg="bgPrimary"
        w="full"
        borderTop="1px"
        borderColor="border"
      >
        <SimpleGrid columns={3} p={2}>
          <MobileNavigationMenu />
          <Button
            _hover={{ bgColor: 'hover' }}
            variant="ghost"
            textColor="textPrimary"
            onClick={onOpen}
          >
            [Search]
          </Button>
          <ConnectButton />
        </SimpleGrid>
      </Box>
      <MobileSearchPanel isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default MobileBottomTabBar
