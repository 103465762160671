import { Token } from 'types/token'
import { zeroAddress } from 'viem'

import { TokenMillChainId } from './chains'

export const CNATIVE: { [chainId in TokenMillChainId]: Token } = {
  [TokenMillChainId.FUJI]: {
    address: zeroAddress,
    decimals: 18,
    name: 'AVAX',
    symbol: 'AVAX'
  },
  [TokenMillChainId.SOLANA_DEVNET]: {
    address: zeroAddress,
    decimals: 9,
    name: 'SOL',
    symbol: 'SOL'
  },
  [TokenMillChainId.SOLANA]: {
    address: zeroAddress,
    decimals: 9,
    name: 'SOL',
    symbol: 'SOL'
  }
}

export const WNATIVE: { [chainId in TokenMillChainId]: Token } = {
  [TokenMillChainId.FUJI]: {
    address: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    decimals: 18,
    name: 'Wrapped AVAX',
    symbol: 'WAVAX'
  },
  [TokenMillChainId.SOLANA_DEVNET]: {
    address: 'So11111111111111111111111111111111111111112',
    decimals: 9,
    name: 'Wrapped SOL',
    symbol: 'wSOL'
  },
  [TokenMillChainId.SOLANA]: {
    address: 'So11111111111111111111111111111111111111112',
    decimals: 9,
    name: 'Wrapped SOL',
    symbol: 'wSOL'
  }
}
