import { Box, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import { Chain } from 'types/dexbarn'
import { UserStakePosition } from 'types/market'
import { Token } from 'types/token'

import StakePanel from './StakePanel'
import UnstakePanel from './UnstakePanel'

interface StakeUnstakePanelProps {
  baseToken: Token
  chain: Chain
  isLoadingUserStakePosition: boolean
  marketAddress: string
  onStakeSuccess: () => void
  onUnstakeSuccess: () => void
  baseTokenLogoUrl?: string
  userStakePosition?: UserStakePosition
}

const StakeUnstakePanel = ({
  baseToken,
  baseTokenLogoUrl,
  chain,
  isLoadingUserStakePosition,
  marketAddress,
  onStakeSuccess,
  onUnstakeSuccess,
  userStakePosition
}: StakeUnstakePanelProps) => {
  return (
    <Box w="full" p={{ base: 4, md: 6 }} borderRadius="2xl" h="fit-content">
      <Tabs isLazy variant="bracketed">
        <TabList>
          <Tab>Stake</Tab>
          <Tab>Unstake</Tab>
        </TabList>
        <TabPanels px={0} py={0}>
          <StakePanel
            chain={chain}
            marketAddress={marketAddress}
            baseToken={baseToken}
            baseTokenLogoUrl={baseTokenLogoUrl}
            onStakeSuccess={onStakeSuccess}
          />
          <UnstakePanel
            baseToken={baseToken}
            baseTokenLogoUrl={baseTokenLogoUrl}
            chain={chain}
            marketAddress={marketAddress}
            isLoadingUserStakePosition={isLoadingUserStakePosition}
            userStakePosition={userStakePosition}
            onUnstakeSuccess={onUnstakeSuccess}
          />
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default StakeUnstakePanel
