import {
  Box,
  Button,
  Center,
  Flex,
  Hide,
  HStack,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Spacer,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import CoinsIcon from 'assets/coin.png'
import DollarSignIcon from 'assets/dollar.png'
import ChainIcon from 'components/ChainIcon'
import SortableTableHeader from 'components/SortableTableHeader'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useGetTMMarkets from 'hooks/tokenmill/useGetTMMarkets'
import React, { useCallback, useState } from 'react'
import { TMMarketQueryParam } from 'types/dexbarn'
import { formatShortDistanceToNow, formattedNum } from 'utils/format'

import HomeHeader from './HomeHeader'
import LiveIndicator from './LiveIndicator'

type PriceDisplay = 'usd' | 'native'

const PriceChangeColumn = ({ value }: { value: number }) => {
  if (value === 0) {
    return (
      <Text textColor="textPrimary">{formattedNum(value, { places: 2 })}%</Text>
    )
  }
  if (value > 0) {
    return (
      <Text textColor="green.400">+{formattedNum(value, { places: 2 })}%</Text>
    )
  }
  return <Text textColor="red.500">{formattedNum(value, { places: 2 })}%</Text>
}

const TokenMill = () => {
  const [sortMethod, setSortMethod] =
    useState<TMMarketQueryParam.OrderBy>('trending')
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)
  const [priceDisplay, setPriceDisplay] = useState<PriceDisplay>('usd')

  const filterOptions: TMMarketQueryParam.FilterBy[] = ['5m', '1h', '6h', '1d']
  const [filterBy, setFilterBy] = useState<TMMarketQueryParam.FilterBy>('6h')

  // TODO: this should be multichain
  const [isHoveringRow, setIsHoveringRow] = useState<boolean>(false)
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const isLive = !isPaused && !isHoveringRow && sortMethod === 'new'
  const {
    data: markets = [],
    fetchNextPage: fetchNextMarkets,
    hasNextPage: hasNextMarketsPage,
    isFetchingNextPage: isFetchingNextMarketsPage,
    isLoading: isLoadingMarkets,
    pausedMarketsCount
  } = useGetTMMarkets({
    chain: 'avalanche',
    excludeLowVolumeMarkets: false,
    filterBy,
    isDesc: isSortDescending,
    isPaused: !isLive,
    orderBy: sortMethod
  })

  const onHeaderClick = useCallback(
    (method: TMMarketQueryParam.OrderBy) => {
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod]
  )

  let sortMethodTabIndex = -1
  if (sortMethod === 'new') {
    sortMethodTabIndex = 0
  } else if (sortMethod === 'trending') {
    sortMethodTabIndex = 1
  }

  const togglePriceDisplay = () => {
    setPriceDisplay((prev) => (prev === 'usd' ? 'native' : 'usd'))
  }

  return (
    <Box maxW="1600px" margin="0 auto" pb={8}>
      <HomeHeader />

      <VStack
        align="flex-start"
        pt={{ base: 4, md: 6 }}
        px={{ base: 0, md: 2 }}
      >
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gap={{ base: 4, md: 8 }}
          w="full"
          px={4}
        >
          <Tabs
            variant="bracketed"
            index={sortMethodTabIndex}
            overflowX={{ base: 'auto', md: 'visible' }}
            w={{ base: 'full', md: 'auto' }}
          >
            <TabList>
              <Tab
                onClick={() => {
                  setSortMethod('new')
                  setFilterBy('5m')
                  setIsSortDescending(true)
                }}
                whiteSpace="nowrap"
              >
                New
              </Tab>
              <Tab
                onClick={() => {
                  setSortMethod('trending')
                  setFilterBy('6h')
                  setIsSortDescending(true)
                }}
                whiteSpace="nowrap"
              >
                Trending
              </Tab>
            </TabList>
          </Tabs>

          <Tabs
            variant="bracketed"
            index={filterOptions.indexOf(filterBy)}
            onChange={(index) => setFilterBy(filterOptions[index])}
            overflowX={{ base: 'auto', md: 'visible' }}
            w={{ base: 'full', md: 'auto' }}
          >
            <TabList>
              {filterOptions.map((option) => (
                <Tab key={option} whiteSpace="nowrap">
                  {option.toUpperCase()}
                </Tab>
              ))}
            </TabList>
          </Tabs>

          <Hide below="md">
            <Spacer />
          </Hide>

          {sortMethod === 'new' ? (
            <LiveIndicator
              isPaused={!isLive}
              onTogglePause={() => setIsPaused(!isPaused)}
              pausedDataCount={pausedMarketsCount}
            />
          ) : null}
        </Flex>

        {isLoadingMarkets ? (
          <Center w="full" minH="70svh">
            <Spinner />
          </Center>
        ) : (
          <TableContainer w="full" minH="70svh">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Token</Th>
                  <Th textAlign="right">
                    <HStack justifyContent="flex-end" spacing={0}>
                      <Text textColor="textSecondary">Price</Text>
                      <TouchFriendlyTooltip label="Toggle usd and native price">
                        <IconButton
                          borderRadius="full"
                          bg="transparent"
                          p={0}
                          aria-label="Toggle price currency"
                          icon={
                            priceDisplay === 'usd' ? (
                              <Image
                                opacity={0.5}
                                src={DollarSignIcon}
                                boxSize={4}
                                _dark={{ filter: 'invert(1)' }}
                              />
                            ) : (
                              <Image
                                src={CoinsIcon}
                                opacity={0.5}
                                boxSize={4}
                                _dark={{ filter: 'invert(1)' }}
                              />
                            )
                          }
                          size="xs"
                          onClick={togglePriceDisplay}
                        />
                      </TouchFriendlyTooltip>
                    </HStack>
                  </Th>
                  <SortableTableHeader
                    name={t`Age`}
                    isSortActive={sortMethod === 'new'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('new')}
                  />
                  <Th isNumeric>Txns</Th>
                  <SortableTableHeader
                    name={t`Volume`}
                    isSortActive={sortMethod === 'volume'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('volume')}
                  />
                  <Th isNumeric>5M</Th>
                  <Th isNumeric>1H</Th>
                  <Th isNumeric>6H</Th>
                  <Th isNumeric>24H</Th>
                  <Th isNumeric>Staking APR</Th>
                  <SortableTableHeader
                    name={t`Liquidity`}
                    isSortActive={sortMethod === 'liquidity'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('liquidity')}
                  />
                  <SortableTableHeader
                    name={t`Market Cap`}
                    isSortActive={sortMethod === 'market_cap'}
                    isSortDescending={isSortDescending}
                    isNumeric
                    onClick={() => onHeaderClick('market_cap')}
                    textAlign="right"
                  />
                </Tr>
              </Thead>

              <Tbody>
                {markets.map((market, i) => {
                  return (
                    <LinkBox
                      key={i}
                      as={Tr}
                      transform="scale(1)"
                      cursor="pointer"
                      role="group"
                      _hover={{ bg: 'bgSecondary' }}
                      onMouseEnter={() => setIsHoveringRow(true)}
                      onMouseLeave={() => setIsHoveringRow(false)}
                    >
                      <Td>
                        <LinkOverlay
                          aria-label={`Link to token page for ${market.baseToken.symbol}`}
                          href={`/${market.chain}/${market.marketAddress}`}
                        />
                        <HStack>
                          <ChainIcon chain={market.chain} size="20px" />
                          <Text textColor="textSecondary">
                            {market.quoteToken.symbol}
                          </Text>
                          <Text textColor="textSecondary">/</Text>
                          <Image
                            src={market.logoUrl}
                            boxSize={6}
                            objectFit="contain"
                            ml={2}
                          />
                          <Text>{market.baseToken.symbol}</Text>
                        </HStack>
                      </Td>
                      <Td>
                        <HStack justify="flex-end">
                          {priceDisplay === 'usd' ? (
                            <Text>
                              {formattedNum(market.baseToken.priceUsd, {
                                places: 5,
                                usd: true
                              })}
                            </Text>
                          ) : (
                            <Text>
                              {formattedNum(market.baseToken.priceNative, {
                                places: 5
                              })}{' '}
                              {market.quoteToken.symbol}
                            </Text>
                          )}
                        </HStack>
                      </Td>
                      <Td textAlign="right">
                        {formatShortDistanceToNow(market.createdAt)}
                      </Td>
                      <Td>
                        <HStack justifyContent="flex-end">
                          <Text textColor="green.400">{market.numberBuys}</Text>
                          <Text textColor="textSecondary">/</Text>
                          <Text textColor="red.500">{market.numberSells}</Text>
                        </HStack>
                      </Td>
                      <Td textAlign="right">
                        {formattedNum(market.volumeUsd, {
                          places: 0,
                          usd: true
                        })}
                      </Td>
                      <Td textAlign="right">
                        <PriceChangeColumn
                          value={market.baseTokenPctChange5m}
                        />
                      </Td>
                      <Td textAlign="right">
                        <PriceChangeColumn
                          value={market.baseTokenPctChange1h}
                        />
                      </Td>
                      <Td textAlign="right">
                        <PriceChangeColumn
                          value={market.baseTokenPctChange6h}
                        />
                      </Td>
                      <Td textAlign="right">
                        <PriceChangeColumn
                          value={market.baseTokenPctChange1d}
                        />
                      </Td>
                      <Td textAlign="right">
                        {formattedNum(market.stakingApr, {
                          places: 2
                        })}
                        %
                      </Td>
                      <Td textAlign="right">
                        {formattedNum(market.liquidityUsd, {
                          places: 0,
                          usd: true
                        })}
                      </Td>
                      <Td textAlign="right">
                        {formattedNum(market.circulatingSupplyUsd, {
                          places: 0,
                          usd: true
                        })}
                      </Td>
                    </LinkBox>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </VStack>

      {hasNextMarketsPage && !isLoadingMarkets && (
        <Center mt={4}>
          <Button
            variant="boxShadowFlat"
            bg="bgSecondary"
            color="textPrimary"
            isLoading={isFetchingNextMarketsPage}
            onClick={() => fetchNextMarkets()}
          >
            Load More
          </Button>
        </Center>
      )}
    </Box>
  )
}

export default TokenMill
