import useStakeTokensEVM from 'hooks/evm/useStakeTokens'
import useStakeTokensSolana from 'solana/hooks/useStakeTokens'
import { Chain } from 'types/dexbarn'
import { getChainType } from 'utils/chains'

export interface UseStakeTokensProps {
  amount: bigint | undefined
  baseTokenAddress: string
  baseTokenSymbol: string
  chain: Chain
  enabled: boolean
  marketAddress: string
  onStakeSuccess?: () => void
}

const useStakeTokens = (props: UseStakeTokensProps) => {
  const { chain } = props
  const chainType = getChainType(chain)

  const stakeEVM = useStakeTokensEVM(props)
  const stakeSolana = useStakeTokensSolana(props)

  switch (chainType) {
    case 'evm':
      return stakeEVM
    case 'solana':
      return stakeSolana
  }
}

export default useStakeTokens
