import useLiveTradesEVM from 'hooks/evm/useLiveTrades'
import useLiveTradesSolana from 'solana/hooks/useLiveTrades'
import { Chain } from 'types/dexbarn'

export interface UseLiveTradesProps {
  baseTokenSymbol: string
  chain: Chain
  isPaused: boolean
  marketAddress: string
}

const useLiveTrades = (props: UseLiveTradesProps) => {
  const liveTradesEVM = useLiveTradesEVM(props)
  const liveTradesSolana = useLiveTradesSolana(props)

  switch (props.chain) {
    case 'avalanche':
      return liveTradesEVM
    case 'solana':
      return liveTradesSolana
  }
}

export default useLiveTrades
