import { TokenMillChainId } from 'constants/chains'
import { WNATIVE } from 'constants/token'
import { Token } from 'types/token'
import { zeroAddress } from 'viem'

export function getAddress(
  chainId: TokenMillChainId,
  token: Token | undefined
): string | undefined {
  if (!token) {
    return undefined
  }

  return token.address === zeroAddress
    ? WNATIVE[chainId].address.toLowerCase()
    : token.address.toLowerCase()
}
