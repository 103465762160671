import { Idl, Program } from '@coral-xyz/anchor'
import { getAssociatedTokenAddress } from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useQuery } from '@tanstack/react-query'
import { UseGetPendingReferrerFeesProps } from 'hooks/referrals/useGetPendingReferrerFees'
import { TM_CONFIG } from 'solana/constants'
import TokenMillIdl from 'solana/idl/token_mill.json'
import { getChainId } from 'utils/chains'

const useGetPendingReferrerFeesSolana = ({
  chain,
  quoteTokenAddress
}: UseGetPendingReferrerFeesProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()

  const fetchPendingReferrerFees = async () => {
    if (!wallet.publicKey || !quoteTokenAddress) {
      return null
    }

    const program = new Program(TokenMillIdl as Idl, { connection })

    const quoteTokenMint = new PublicKey(quoteTokenAddress)

    const referrerReferralAccount = PublicKey.findProgramAddressSync(
      [
        Buffer.from('referral'),
        TM_CONFIG.toBuffer(),
        wallet.publicKey.toBuffer()
      ],
      program.programId
    )[0]

    const referralAccountATA = await getAssociatedTokenAddress(
      quoteTokenMint,
      referrerReferralAccount,
      true
    )

    const tokenAccountInfo =
      await connection.getTokenAccountBalance(referralAccountATA)

    if (tokenAccountInfo.value.uiAmount) {
      return {
        formatted: String(tokenAccountInfo.value.uiAmount),
        value: BigInt(tokenAccountInfo.value.amount)
      }
    }

    return undefined
  }

  const {
    data: pendingReferrerFees,
    isLoading: isLoadingPendingReferrerFees,
    refetch: refetchPendingReferrerFees
  } = useQuery({
    enabled: !!wallet.publicKey && !!quoteTokenAddress,
    queryFn: fetchPendingReferrerFees,
    queryKey: [
      'pendingReferrerFees',
      wallet.publicKey?.toString(),
      quoteTokenAddress,
      getChainId(chain)
    ]
  })

  return {
    isLoadingPendingReferrerFees,
    pendingReferrerFees,
    refetchPendingReferrerFees
  }
}

export default useGetPendingReferrerFeesSolana
