import { Button, ResponsiveValue, useClipboard } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Chain } from 'types/dexbarn'

interface Props {
  buttonSize: ResponsiveValue<string>
  chain: Chain
  code: string
  marketAddress?: string
}

const CopyReferredURLButton = ({
  buttonSize,
  chain,
  code,
  marketAddress
}: Props) => {
  const [buttonText, setButtonText] = useState('[Share]')
  const { onCopy } = useClipboard(
    marketAddress
      ? code
        ? `${location.origin}/${chain}/${marketAddress}?r=${code}`
        : `${location.origin}/${chain}/${marketAddress}`
      : code
        ? `${location.origin}?r=${code}`
        : `${location.origin}`
  )

  const handleCopy = () => {
    onCopy()
    setButtonText('Copied!')
    setTimeout(() => setButtonText('[Share]'), 1000)
  }

  return (
    <Button
      size={buttonSize}
      variant="boxShadowFlat"
      onClick={handleCopy}
      _dark={{ bg: 'bgSecondary' }}
    >
      {buttonText}
    </Button>
  )
}

export default CopyReferredURLButton
