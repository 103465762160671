import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const boxShadowFlat = defineStyle((props) => ({
  _hover: { opacity: 0.8, textDecor: 'none' },
  bg: props.colorMode === 'dark' ? 'charcoal' : 'blueGray',
  borderRadius: 0,
  color: 'textPrimary',
  p: 2
}))

export const linkTheme = defineStyleConfig({
  variants: { boxShadowFlat }
})
