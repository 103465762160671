import { Link, LinkProps } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'

interface HeaderLinkProps {
  title: string
  to: string
}

const HeaderLink = ({ title, ...props }: HeaderLinkProps & LinkProps) => {
  return (
    <Link as={NavLink} variant="boxShadowFlat" {...props}>
      {title}
    </Link>
  )
}

export default HeaderLink
