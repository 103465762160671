/* eslint-disable @typescript-eslint/naming-convention */
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga4'

export const initializeAnalytics = () => {
  const GOOGLE_ANALYTICS_ID: string | undefined =
    process.env.REACT_APP_GA_MEASUREMENT_ID
  if (typeof GOOGLE_ANALYTICS_ID === 'string' && GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    ReactGA.set({
      customBrowserType: !isMobile
        ? 'desktop'
        : 'web3' in window || 'ethereum' in window
          ? 'mobileWeb3'
          : 'mobileRegular'
    })
    ReactGA.send('pageview')
  } else {
    ReactGA.initialize('test', { testMode: true })
  }
}

export const trackConnectWallet = (walletName?: string): void => {
  ReactGA.event('connectWallet', {
    category: 'wallet',
    label: walletName
  })
}
